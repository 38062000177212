import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-dashboard-days',
  templateUrl: './select-dashboard-days.component.html',
  styleUrls: ['./select-dashboard-days.component.scss']
})
export class SelectDashboardDaysComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { value: 'current_month', name: 'Mes Actual' },
    { value: 'past_month', name: 'Mes Anterior' },
    { value: 30, name: 'Últ. 30 días' },
    { value: 60, name: 'Últ. 60 días' },
    { value: 90, name: 'Últ. 90 días' },
    { value: 120, name: 'Últ. 120 días' },
    { value: 180, name: 'Últ. 180 días' },
    { value: 360, name: 'Últ. 360 días' }
  ];

  public daysAgoActual: any = [];
  public ngxDisabled = false;
  daysAgoActual$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.daysAgoActual$ = this.sharedService.sharedDaysDashboard.subscribe(daysAgoActual => {
      this.daysAgoActual = daysAgoActual;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.daysAgoActual$.unsubscribe();
  }

  changeDaysAgo(event: any) {
    this.daysAgoActual = event;
    this.sharedService.nextDaysDashboard = this.daysAgoActual;
  }
}
