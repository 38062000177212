<!-- ============================================================== -->
<!-- Main wrapper - style you can find in login.component.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/images/login/img_login.svg">
    <div class="login-box card" style="border-radius: 0 !important;">
        <div class="password-header">
            <h2>Verificar cuenta</h2>
        </div>

        <div class="card-body verify-card" *ngIf="success">
            <a class="text-center db">
                <img src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/logo.svg" width="100%" alt="Home" />
            </a>

            <br>
            <h2 class="welcome">¡Tu cuenta ha sido verificada!</h2>
            <br>
            <h5 class="welcome-subtitle"> Realiza cargas en las gasolineras participantes, acumula GasPuntos y canjealos
                por GasPremios.</h5>

        </div>

        <div class="card-body verify-card" *ngIf="!success">
            <a class="text-center db">
                <img src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/logo.svg" width="100%" alt="Home" />
            </a>
        
            <br>
            <h2 class="welcome">¡Tu cuenta no ha sido verificada!</h2>
            <br>
            <h5 class="welcome-subtitle">Ha ocurrido un error durante el proceso por favor intentalo nuevamente.</h5>
        
        </div>

        <div class="password-footer">
            <h2>hola@gaspremia.com</h2>
        </div>
    </div>

</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->