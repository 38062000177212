import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-dashboard-zone',
  templateUrl: './select-dashboard-zone.component.html',
  styleUrls: ['./select-dashboard-zone.component.scss']
})
export class SelectDashboardZoneComponent implements OnInit, OnDestroy {

  public items: any[] = [{ value: '', name: 'Total' }];
  public idGroup: any;
  public zoneDashboard: any = [];
  public ngxDisabled = true;
  idGroup$: Subscription;
  sharedZone$: Subscription;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
        this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');


    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.getZones();
      }
    });

    this.sharedZone$ = sharedService.sharedZoneDashboard.subscribe((zoneDashboard: string) => {
      if (zoneDashboard !== this.zoneDashboard) {
        this.zoneDashboard = zoneDashboard;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getZones();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
  }

  getZones() {
    this.items = [{ value: '', name: 'Total' }];
    this.gasprecioService.getZonesDashboard(this.idGroup).subscribe((data: any) => {
      const zones: any = data;
      zones.forEach((element: any) => {
        this.items.push({ name: this.gasprecioService.capitalizeFirstLetter(element['name']), value: element['id'] });
      });
      this.ngxDisabled = false;
    });
  }

  changeMarket(event: any) {
    this.zoneDashboard = event;
    this.sharedService.nextZoneDashboard = this.zoneDashboard;
    this.items.map((zone: any) => {
      if (zone.value === this.zoneDashboard) {
        this.sharedService.nextZoneDashboardText = zone.name;
      }
    });
  }
}
