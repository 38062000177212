import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variables-intelligence-evolution',
  templateUrl: './select-variables-intelligence-evolution.component.html',
  styleUrls: ['./select-variables-intelligence-evolution.component.scss']
})
export class SelectVariablesIntelligenceEvolutionComponent implements  OnDestroy {

  public items: any[] = [
    { name: 'Precio de Venta', value: 'selling_price' },
    { name: 'Ranking', value: 'ranking' },
    { name: 'Dif Precio', value: 'diff_selling_price' }
  ];

  public variableSelected: any = [];
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariableEvolution.subscribe((variableSelected: string) => {
      this.variableSelected = variableSelected;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariableEvolution = this.variableSelected;
  }

}
