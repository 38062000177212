import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-multiselect-product',
  templateUrl: './multiselect-product.component.html',
  styleUrls: ['./multiselect-product.component.scss']
})
export class MultiselectProductComponent implements OnInit, OnDestroy {

  dropdownList = [
    { item_id: 1, item_text: 'Gasoleo A' },
    { item_id: 2, item_text: 'Gasoleo B' },
    { item_id: 3, item_text: 'Gasoleo Premium' },
    { item_id: 4, item_text: 'Gas 95 E5' },
    { item_id: 5, item_text: 'Gas 95 E5 Prem' },
    { item_id: 6, item_text: 'Gas 98 E5' }
  ];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas',
    unSelectAllText: 'Todas',
    itemsShowLimit: 1,
    allowSearchFilter: false,
    maxHeight: 140
  };
  url: string;

  constructor(
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.selectedItems = [
      { item_id: 1, item_text: 'Gasoleo A' },
      { item_id: 2, item_text: 'Gasoleo B' },
      { item_id: 3, item_text: 'Gasoleo Premium' },
      { item_id: 4, item_text: 'Gas. 95 E5' },
      { item_id: 5, item_text: 'Gas. 95 E5 Prem' },
      { item_id: 6, item_text: 'Gas. 98 E5' }
    ];
  }

  ngOnDestroy() {}


  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'product_ids[]=' + item.item_id + '&';
    });

    if (this.selectedItems.length === 0) {
      this.url = 'product_ids[]=1&product_ids[]=2&product_ids[]=3&product_ids[]=4&product_ids[]=5&product_ids[]=6&';
      this.selectedItems = [];
    }

    this.sharedService.nextComplementProducts = this.url;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      this.url = this.url + 'product_ids[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementProducts = this.url;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = 'product_ids[]=1&product_ids[]=2&product_ids[]=3&product_ids[]=4&product_ids[]=5&product_ids[]=6&';
    this.sharedService.nextComplementProducts = this.url;
  }

}
