import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MustMatch } from 'src/app/helpers/validators/must-match.validator';

import * as moment from 'moment';
moment.locale('es');

@Component({
  selector: 'app-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./../login/login.component.scss']
})
export class SetPasswordComponent implements OnInit {

  recoverForm: UntypedFormGroup;
  public token: any;
  public date: any;
  public dateToken: any;
  public submitted = false;
  public success = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthService
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.dateToken = params['date'];
      if (this.token && this.dateToken) {
        const startdate = moment();
        this.date = moment(startdate);
        this.dateToken = moment(this.dateToken);
        const time  = this.date.diff(this.dateToken, 'hours');
        if (time < 24) {
          if (this.token.length > 0) { } else {
            window.location.replace('https://www.crmgaspre.com');
          }
        } else {
          window.location.replace('https://www.crmgaspre.com');
        }
      } else {
        window.location.replace('https://www.crmgaspre.com');
      }
    });
  }

  ngOnInit() {
    this.recoverForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('.{8,}')]],
      confirm_password: ['', [Validators.required, Validators.minLength(8)]]
    }, {
        validator: MustMatch('password', 'confirm_password')
    });
  }

  get f() { return this.recoverForm.controls; }

  async onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.recoverForm.invalid) {
        console.log('El formulario no es valido', this.recoverForm.invalid);
        return;
    }

    const valido = await this.authenticationService.setPassword(this.recoverForm.value, this.token);

    if (valido) {
      // mostrar password exitoso
      this.router.navigate(['/login']);
      console.log(valido);
    } else {
      // mostrar error token expirado
      console.log('error');
    }
  }
}
