import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variables-second-intelligence-evolution',
  templateUrl: './select-variables-second-intelligence-evolution.component.html',
  styleUrls: ['./select-variables-second-intelligence-evolution.component.scss']
})
export class SelectVariablesSecondIntelligenceEvolutionComponent {

  public items: any[] = [
    { name: 'Sin Variable', value: '' },
    { name: 'Vol de Venta', value: 'volume' },
    { name: 'Precio Compra', value: 'purchase_price' }
  ];

  public variableSelected: any = [];
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariableSecondEvolution.subscribe((variableSelected: string) => {
      this.variableSelected = variableSelected;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariableSecondEvolution = this.variableSelected;
  }

}
