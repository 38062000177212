<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar" [ngClass]="{'expand-sidebar': showText === true}" (mouseover)="openSidebar()"
    (mouseleave)="closeSidebar()">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/metricas'" (click)="clickPage('Metricas')"
                        aria-expanded="false" title="Métricas">
                        <i class="fa fa-line-chart"></i>
                        <span *ngIf="showText">
                            Métricas
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/clientes'" (click)="clickPage('Clientes')"
                        aria-expanded="false">
                        <i class="fa fa-users"></i>
                        <span *ngIf="showText">
                            Clientes
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/solicitudes'" (click)="clickPage('Solicitudes')"
                        aria-expanded="false">
                        <i class="fa fa-list-alt"></i>
                        <span *ngIf="showText">
                            Solicitudes
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/premios'" (click)="clickPage('Inventario')"
                        aria-expanded="false">
                        <i class="fa fa-cubes"></i>
                        <span *ngIf="showText">
                            Inventario
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/ordenes'" (click)="clickPage('Ordenes')"
                        aria-expanded="false">
                        <i class="fa fa-truck"></i>
                        <span *ngIf="showText">
                            Ordenes
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->