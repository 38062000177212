import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';


@Component({
  selector: 'app-chart-sale-ppc',
  templateUrl: './chart-sale-ppc.component.html',
  styleUrls: ['./chart-sale-ppc.component.scss']
})
export class ChartSalePpcComponent implements OnInit, OnDestroy {

  @ViewChild('chartModalEvol') chartModalEvol: TemplateRef<any>;
  @Input() section: any = false;
  @Input() station: any = false;
  changeModal$!: Subscription;
  changeModal;
  currentStation: any;
  dataEvol;
  date: any;
  days = 30;
  days$!: Subscription;
  event$!: Subscription;
  idGroup = '627034d4c1814c0379cc455a';
  idGroup$!: Subscription;
  months = 12;
  months$!: Subscription;
  nameStation;
  period = 'day';
  period$!: Subscription;
  ppc: any;
  productType = 1;
  productType$!: Subscription;
  reqst = 0;
  reqstEvol = 0;
  rol;
  route;
  sellingPrice;
  stationId$!: Subscription;
  stationId: any;
  tendency: any = 'avg_selling_price';
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;
  chartEvolOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Histórico - Evolución de Precios`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: {
      title: {
        text: 'Precio de Venta',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        format: '{value:.3f} €',
        style: {
          fontSize: '12px'
        }
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      formatter: function () {
        var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
        var currentPoint = this;
        var addedStations = [];
        this.series.chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
              tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(3).replace('.', ',') + ' € </b><br/>';
            }
          });
        });
        return tooltip;
      }
      // pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 2
      }
    },
    exporting: {
      filename: `Histórico - Evolución de Precios`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -6
      }
    },
    series: []
  };

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Precio de Venta - Precio de Competencia`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Precio de Venta',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f} €',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Precio de Competencia',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f} €',
          style: {
            fontSize: '12px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
      pointFormat: '{series.name}: <b>{point.y:.3f} €</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 2
      }
    },
    exporting: {
      filename: `Precio de Venta - Precio de Competencia`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getDashboardSellingPricePpc();
      }
    });

    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        if (this.reqst > 1) {
          this.zoneSelected = '';
          this.getDashboardSellingPricePpc();
        }
      }
    });

    this.zoneSelected$ = sharedService.sharedZoneDashboard.subscribe((zone: string) => {
      if (zone === 'Total') {
        this.zoneSelected = null;
        if (zone !== this.zoneSelected) {
          this.getDashboardSellingPricePpc();
        }
      } else {
        if (zone !== this.zoneSelected) {
          this.zoneSelected = zone;
          this.getDashboardSellingPricePpc();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePpc();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePpc();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePpc();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePpc();
        }
      }
    });

  }

  ngOnInit() {
    this.getDashboardSellingPricePpc();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.zoneSelected$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
    this.event$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
  }

  getDashboardSellingPricePpc() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    const url = this.station ?
      this.gasprecioService.getDashboardSellingPricePpcStation(this.period, typeValue, n) :
      this.gasprecioService.getDashboardSellingPricePpc(this.idGroup, this.zoneSelected, this.period, typeValue, n);

      url.subscribe({
      next: (data: any) => {
        const info = data;
        this.ppc = info['ppc'];
        this.sellingPrice = info['selling_price'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }


  setData() {
    this.reqst = 1;
    const color = this.productType === null ? this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[6]) :  this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.productType - 1]);
    const colorOrange = this.gasprecioService.getColorRGBAString(this.gasprecioService.colorsProductsLight[7]);

    const sellingPriceChartData: any = {
      name: 'Precio de Venta',
      data: this.productType === null ? this.sellingPrice.map((comp: any) => {
        if (comp['product'] === null) {
          return null;
        } else {
          return +comp['product'];
        }
      }) : this.sellingPrice.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: color,
      lineWidth: 2,
      marker: { radius: 2 }
    };

    const competitorsPriceChartData: any = {
      name: 'Precio de Competencia',
      data: this.productType === null ? this.ppc.map((comp: any) => {
        if (comp['product'] === null) {
          return null;
        } else {
          return +comp['product'];
        }
      }) : this.ppc.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }), 
      color: colorOrange,
      lineWidth: 2
    };

    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.ppc.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions.series =  [sellingPriceChartData, competitorsPriceChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }


  openModalChartEvol() {
    this.modalService.open(this.chartModalEvol, { windowClass: 'chartModal', centered: true });
  }

  closeModalChartEvol() {
    this.modalService.dismissAll(this.chartModalEvol);
  }

  getEvolution() {
    this.reqstEvol = 1;
    this.stationId = this.section ? localStorage.getItem('idStation') : this.stationId;
    if (!this.stationId) return;
    this.gasprecioService.getEvolPriceHistory(this.stationId).subscribe({
      next: (res) => {
        this.dataEvol = res;
        this.nameStation = this.dataEvol['client']['name'];
        this.setDataEvol();
      }, complete: () => {
        this.reqstEvol = 2;
      }, error: () => {
        this.reqstEvol = 3;
      }
    });
  }

  setDataEvol() {
    this.reqst = 1;
    const lineChartData: any = [
      {
        name: this.dataEvol['client']['name'],
        data: this.dataEvol['client']['values'].map((comp: any) => {
          if (comp['product_' + this.productType] === null) {
            return null;
          } else {
            return +comp['product_' + this.productType];
          }
        }),
        color: 'rgb(212, 175, 55)',
        lineWidth: 1.5,
        marker: {
          radius: 4
        }
      }
    ]

    const lineXaxisLegend: any = [];
    this.dataEvol['client']['values'].map((v: any) =>
      lineXaxisLegend.push(this.gasprecioService.parseDateDM(v['date']))
    );

    this.dataEvol['competitors'].forEach((c, index) => {
      let color = `rgb(${this.gasprecioService.colors[index + 1].r}, ${this.gasprecioService.colors[index + 1].g}, ${this.gasprecioService.colors[index + 1].b})`;
      lineChartData.push({
        name:  c['name'],
        data: c['values'].filter((type: any) => type !== null).map((comp: any) => {
          if (comp['product_' + this.productType] === null) {
            return null;
          } else {
            return +comp['product_' + this.productType];
          }
        }),
        color: color
      });
    })

    this.chartEvolOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartEvolOptions['series'] = lineChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

}
