import * as moment from 'moment-timezone';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-comparative-dashboard',
  templateUrl: './table-comparative-dashboard.component.html',
  styleUrls: ['./table-comparative-dashboard.component.scss']
})
export class TableComparativeDashboardComponent implements OnInit, OnDestroy {

  days = 30;
  days$!: Subscription;
  columns = [];
  headerTable = [];
  id;
  idGroup = '627034d4c1814c0379cc455a';
  idGroupSub$!: Subscription;
  idStation;
  months = 12;
  months$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 1;
  productType$!: Subscription;
  rol;
  rows: any;
  reqst = 0;
  SelectionType = SelectionType;
  selected: any[] = [];
  temp: any = [];
  variableValue: any = 'selling_price';
  variableValue$: Subscription;
  val: any;
  heightTable = 0;
  weeks = 12;
  weeks$!: Subscription;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');

        this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');


    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.variableValue$ = sharedService.sharedVariableDashboard.subscribe((variableValue: string) => {
      if (this.variableValue !== variableValue) {
        this.variableValue = variableValue;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.idGroupSub$ = this.sharedService.sharedGroup.subscribe(idGroup => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

  }

  ngOnInit(): void {
    this.getDashboardComparative();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroupSub$.unsubscribe();
    this.variableValue$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
  }

  getRowHeight(row: any) {
    return row.height;
  }

  filterTable($event?: any) {
    if ($event) {
      this.val = $event.target.value;
    }
    this.temp = this.rows.filter((d: any) => {
      for (const key in d) {
        if (d[key] !== null) {
          if (key === 'nameStation' || key === 'product' || key === 'station' || key === 'municipio' || key === 'entidad') {
            if (d[key].toLowerCase().indexOf(this.val.toLowerCase()) !== -1) {
              return true;
            }
          }
        }
      }
      return false;
    });
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  getDashboardComparative() {
    this.reqst = 0;
    this.heightTable = 60;
    this.headerTable = [];
    this.columns = [];
    let url;
    let n;
    let typeValue = 'value';
    if (this.period === 'day') {
      n = this.days;
    } else if (this.period === 'week') {
      n = this.weeks;
    } else if (this.period === 'month') {
      n = this.months;
    }

    if (typeof n === 'number') {
      typeValue = 'value';
    } else {
      typeValue = 'date';
    }

    url = this.gasprecioService.getDashboardComparative(this.idGroup, this.variableValue, this.productType, this.period, typeValue, n);
    url.subscribe((data: any) => {
      const inf = [];
      const excel = [];
      const headerExcel = [''];
      data.forEach((element: any, index: number) => {
        const months = Object.keys(element['values']).length - 1;
        this.heightTable = this.heightTable + 24;
        const r = {};
        const rowExcel = [];
        element['values'].map((row: any, i) => {
          if (i === 0) {
            let value;
            if (this.variableValue === 'volume') {
              value = row['value'] !== null ? this.numberWithCommas(row['value']) : '-';
            } else if (this.variableValue === 'quotient') {
              value = row['value'] !== null ? row['value'].toFixed(3).replace('.', ',') : '-';
            } else {
              value = row['value'] !== null ? row['value'].toFixed(3).replace('.', ',') : '-';
            }
            rowExcel.push(row.zone_name, value);
          } else {
            let value;
            if (this.variableValue === 'volume') {
              value = row['value'] !== null ? this.numberWithCommas(row['value']) : '-';
            } else if (this.variableValue === 'quotient') {
              value = row['value'] !== null ? row['value'].toFixed(3).replace('.', ',') : '-';
            } else {
              value = row['value'] !== null ? row['value'].toFixed(3).replace('.', ',') : '-';
            }
            rowExcel.push(value);
          }

          if (index === 0) {
            if (this.period === 'day') {
              headerExcel.push(moment(row['date']).tz('Europe/Madrid').format('DD MMM'));
            } else if (this.period === 'week') {
              headerExcel.push(row['date_alias']);
            } else if (this.period === 'month') {
              headerExcel.push(moment(row['date']).tz('Europe/Madrid').format('MMM YY'));
            }
          }
          if (this.variableValue === 'volume') {
            r['day_' + i] = row['value'] !== null ? this.numberWithCommas(row['value']) + ' lts' : '-';
          } else if (this.variableValue === 'quotient') {
            r['day_' + i] = row['value'] !== null ? row['value'].toFixed(3).replace('.', ',') : '-';
          } else {
            r['day_' + i] = row['value'] !== null ? row['value'].toFixed(3).replace('.', ',') + ' €': '-';
          }
          r['height'] = 24;
          r['date'] = moment(row['date']).tz('Europe/Madrid').format('DD/MM/YY');
          r['zone'] = row['zone_name'];
          if (this.period === 'day') {
            this.headerTable.push(moment(row['date']).tz('Europe/Madrid').format('DD MMM'));
            if (index === 0) {
              this.columns.push({
                name: moment(element['date']).tz('Europe/Madrid').subtract(this.days - i, 'days').format('DD MMM'),
                prop: i
              });
            }
          } else if (this.period === 'week') {
            this.headerTable.push(row['date_alias']);
            if (index === 0) {
              this.columns.push({
                name: row['date_alias'],
                prop: i
              });
            }
          } else if (this.period === 'month') {
            this.headerTable.push(moment(row['date']).tz('Europe/Madrid').format('MMM YY'));
            if (index === 0) {
              this.columns.push({
                name: moment(element['date']).tz('Europe/Madrid').subtract(months - i, 'month').format('MMM YY'),
                prop: i
              });
            }
          }
        });
        inf.push(r);
        excel.push(rowExcel);
      });
      this.sharedService.nextHeaderTableComparative = headerExcel;
      this.sharedService.nextTableComparative = excel;
      if (this.period === 'month' && this.idGroup === '615efe30b318ce63b8971986') {
        this.rows = inf.slice(0, 8);
      } else{
        this.rows = inf;
      }
      this.temp = this.rows.map((prop: any, key: any) => {
        return {
          ...prop,
          id: key
        };
      });
      this.reqst = 1;
    }, err => {
      console.log(err);
    });
  }

}
