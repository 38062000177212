import { Component, OnInit } from '@angular/core';
import { ClickService } from 'src/app/services/click.service';
import { StationService } from 'src/app/modules/station/services/station.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  id: any;
  idStation: any;
  showText = false;

  constructor(
    public clickService: ClickService,
    public stationService: StationService
  ) { }

  ngOnInit() {
    this.id = localStorage.getItem('id');
    this.idStation = localStorage.getItem('idStation');
    if (this.id === null) {
      this.getProfile();
    }
  }

  getProfile() {
    this.stationService.getProfile().subscribe(
      data => {
        this.id = data._id;
      }
    );
  }

  openSidebar() {
    this.showText = true;
  }

  closeSidebar() {
    this.showText = false;
  }

  clickPage(page: any) {
    this.clickService.clickPage(this.id, this.idStation, page, 'GasPremia')
      .pipe()
      .subscribe(
        data => {
          // console.log(data);
        },
        error => {
          console.log(error);
        });
  }

}
