import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variables-intelligence',
  templateUrl: './select-variables-intelligence.component.html',
  styleUrls: ['./select-variables-intelligence.component.scss']
})
export class SelectVariablesIntelligenceComponent implements  OnDestroy {

  public items: any[] = [
    { value: 'num_stations', variable: 'numStations', name: '# Estaciones' },
    { value: 'market_share', variable: 'marketShare', name: '% Market Share' },
    { value: 'avg_selling_price', variable: 'avgSellingPrice', name: 'Precio Prom' },
    { value: 'quotient', variable: 'quotient', name: 'Indice de Precios' }
  ];

  public variableSelected: any = [];
  public ngxDisabled = true;
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariableBrand.subscribe((variableSelected: string) => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariableBrand = this.variableSelected;
  }

}
