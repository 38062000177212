import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-table-dif-min-price',
  templateUrl: './table-dif-min-price.component.html',
  styleUrls: ['./table-dif-min-price.component.scss']
})
export class TableDifMinPriceComponent implements OnInit, OnDestroy {

  market = '';
  productType = 1;
  productType$!: Subscription;
  daysOptimumPx = 180;
  daysOptimumPx$!: Subscription;
  private subscriptions: Subscription = new Subscription();
  rowsCurrent: any;
  rowsRecord: any;
  rowsLength: number;
  reqst = 0;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    // this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
    //   if (this.productType !== productType) {
    //     this.productType = productType;
    //     if (this.reqst > 0) {
    //       this.getDiffPxMin();
    //     }
    //   }
    // });
    this.subscriptions.add(
      combineLatest([
        this.sharedService.sharedProduct,
        this.sharedService.sharedDaysOptimumPx
      ]).subscribe(([productType, daysOptimumPx]) => {
        let shouldUpdate = false;

        if (this.productType !== productType) {
          this.productType = productType;
          shouldUpdate = true;
        }

        if (this.daysOptimumPx !== daysOptimumPx) {
          this.daysOptimumPx = daysOptimumPx;
          shouldUpdate = true;
        }

        if (shouldUpdate && this.reqst > 0) {
          this.getDiffPxMin();
        }
      })
    );
  }

  ngOnInit(): void {
    this.getDiffPxMin();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getRowHeight(row: any) {
    return row.height;
  }

  getDiffPxMin() {
    this.reqst = 0;
    this.gasprecioService.getDiffPxMinDays(this.productType, this.daysOptimumPx).subscribe((data: any) => {
      if (data['record_data_180']) {
        this.rowsLength = (73 / data['record_data_180'].length);
        data['record_data_180'].map((element: any, index: number) => {
          element['n_obs'] = element['n_obs'] !== null ? element['n_obs'] : '-';
          element['dif_p_min'] = element['dif_p_min'] !== null ? element['dif_p_min'] : '-';
          element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
          element['sales'] = element['sales'] !== null ? element['sales'] : '-';
          element['earning'] = element['earning'] !== null ? element['earning'] : '-';
          element['sales_change'] = element['sales_change'] !== null ? element['sales_change'] : '-';
          element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
          element['margin'] = element['margin'] !== null ? element['margin'] : '-';
          element['sales_pct'] = element['sales_pct'] !== null ? element['sales_pct'] * 100 : '-';
          if (element['flag_actual'] !== undefined) {
            this.market = element['flag_actual'] === 0 ? 'low' : element['flag_actual'] === 1 ? 'medium_low' : element['flag_actual'] === 2 ? 'medium_high' : 'high';
          }
        });

        data['current_data'].map((element: any, index: number) => {
          element['dif_p_min'] = element['dif_p_min'] !== null ? element['dif_p_min'] : '-';
          element['price_change'] = element['price_change'] !== null ? element['price_change'] : '-';
          element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
          element['sales'] = element['sales'] !== null ? element['sales'] : '-';
          element['earning'] = element['earning'] !== null ? element['earning'] : '-';
          element['supply_price'] = element['supply_price'] !== null ? element['supply_price'] : '-';
          element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
          element['margin'] = element['margin'] !== null ? element['margin'] : '-';
          if (element['flag_actual'] !== undefined) {
            this.market = element['flag_actual'] === 0 ? 'low' : element['flag_actual'] === 1 ? 'medium_low' : element['flag_actual'] === 2 ? 'medium_high' : 'high';
          }
        });

        this.rowsCurrent = data['current_data'];
        this.rowsRecord = data['record_data_180'];
      }
      this.reqst = 1;
    });
  }

}
