import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private brand: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC1: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC2: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC3: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC4: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC5: BehaviorSubject<string> = new BehaviorSubject('');
    private changesData: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private changeModal: BehaviorSubject<string> = new BehaviorSubject('');
    private creId: BehaviorSubject<string> = new BehaviorSubject('');
    private complement: BehaviorSubject<string> = new BehaviorSubject('product_ids[]=1&');
    private complementFlags: BehaviorSubject<string> = new BehaviorSubject('');
    private complementProducts: BehaviorSubject<string> = new BehaviorSubject('product_ids[]=1&product_ids[]=2&product_ids[]=3&product_ids[]=4&product_ids[]=5&product_ids[]=6&');
    private complementReasons: BehaviorSubject<string> = new BehaviorSubject('');
    private complementZones: BehaviorSubject<string> = new BehaviorSubject('');
    private date: BehaviorSubject<any> = new BehaviorSubject(moment().format('YYYY-MM-DD'));
    private daysAgo: BehaviorSubject<number> = new BehaviorSubject(30);
    private daysAgoActual: BehaviorSubject<number> = new BehaviorSubject(1);
    private daysAgoChange: BehaviorSubject<number> = new BehaviorSubject(1);
    private daysOptimumPx: BehaviorSubject<number> = new BehaviorSubject(180);
    private daysOptimumPrice: BehaviorSubject<number> = new BehaviorSubject(180);
    private daysAgoYesterday: BehaviorSubject<number> = new BehaviorSubject(14);
    private daysDashboard: BehaviorSubject<number> = new BehaviorSubject(30);
    private daysEvolution: BehaviorSubject<number> = new BehaviorSubject(30);
    private entity: BehaviorSubject<string> = new BehaviorSubject('');
    private entityText: BehaviorSubject<string> = new BehaviorSubject('');
    private group: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('group'));
    private headerTableComparative: BehaviorSubject<any> = new BehaviorSubject([]);
    private idStation: BehaviorSubject<string> = new BehaviorSubject('');
    private markeType: BehaviorSubject<string> = new BehaviorSubject('');
    private monthsAgo: BehaviorSubject<any> = new BehaviorSubject(12);
    private nameStation: BehaviorSubject<string> = new BehaviorSubject('');
    private national: BehaviorSubject<string> = new BehaviorSubject('');
    private page: BehaviorSubject<string> = new BehaviorSubject('Monitor');
    private pageMetric: BehaviorSubject<string> = new BehaviorSubject('');
    private period: BehaviorSubject<string> = new BehaviorSubject('week');
    private periodComparative: BehaviorSubject<number> = new BehaviorSubject(1);
    private periodDashboard: BehaviorSubject<string> = new BehaviorSubject('day');
    private periodEarning: BehaviorSubject<number> = new BehaviorSubject(1);
    private periodIndex: BehaviorSubject<string> = new BehaviorSubject(null);
    private product: BehaviorSubject<number> = new BehaviorSubject(1);
    private products: BehaviorSubject<any> = new BehaviorSubject('');
    private productType: BehaviorSubject<number> = new BehaviorSubject(1);
    private productTypeAll: BehaviorSubject<string> = new BehaviorSubject('Gasoleo A');
    private productTypeTotal: BehaviorSubject<any> = new BehaviorSubject(1);
    private reason: BehaviorSubject<string> = new BehaviorSubject('');
    private region: BehaviorSubject<string> = new BehaviorSubject('');
    private rol: BehaviorSubject<string> = new BehaviorSubject('');
    private station: BehaviorSubject<any> = new BehaviorSubject('');
    private stationId: BehaviorSubject<any> = new BehaviorSubject('');
    private statisticTradeTracking: BehaviorSubject<string> = new BehaviorSubject('avg');
    private showSubdivison: BehaviorSubject<number> = new BehaviorSubject(0);
    private showSubsubdivison: BehaviorSubject<number> = new BehaviorSubject(0);
    private statisticEvolution: BehaviorSubject<string> = new BehaviorSubject('avg');
    private tableComparative: BehaviorSubject<any> = new BehaviorSubject([]);
    private tendency: BehaviorSubject<string> = new BehaviorSubject('avg_selling_price');
    private town: BehaviorSubject<string> = new BehaviorSubject('');
    private townText: BehaviorSubject<string> = new BehaviorSubject('');
    private typePx: BehaviorSubject<string> = new BehaviorSubject('min');
    private variable: BehaviorSubject<string> = new BehaviorSubject('selling_price');
    private variableBrand: BehaviorSubject<string> = new BehaviorSubject('num_stations');
    private variableDashboard: BehaviorSubject<string> = new BehaviorSubject('selling_price');
    private variableDashboardText: BehaviorSubject<string> = new BehaviorSubject('Precio de Venta');
    private variableEvolution: BehaviorSubject<string> = new BehaviorSubject('selling_price');
    private variableSecondEvolution: BehaviorSubject<string> = new BehaviorSubject('purchase_price');
    private variablePriceIndex: BehaviorSubject<string> = new BehaviorSubject('quotient');
    private variablePxOpt: BehaviorSubject<string> = new BehaviorSubject('sales');
    private weeksAgo: BehaviorSubject<any> = new BehaviorSubject(12);
    private zone: BehaviorSubject<string> = new BehaviorSubject(null);
    private zoneDashboard: BehaviorSubject<string> = new BehaviorSubject(null);
    private zoneDashboardText: BehaviorSubject<string> = new BehaviorSubject('');
    private zoneMarket: BehaviorSubject<string> = new BehaviorSubject('');
    private zoneMarketText: BehaviorSubject<string> = new BehaviorSubject('');

    constructor() { }

    get sharedPage() {
        return this.page.asObservable();
    }

    set nextPage(page: string) {
        this.page.next(page);
    }

    get sharedPageMetric() {
        return this.pageMetric.asObservable();
    }

    set nextPageMetric(pageMetric: any) {
        this.pageMetric.next(pageMetric);
    }

    get sharedProducts() {
        return this.products.asObservable();
    }

    set nextProducts(products: string) {
        this.products.next(products);
    }

    get sharedProductType() {
        return this.productType.asObservable();
    }

    set nextProductType(productType: number) {
        this.productType.next(productType);
    }

    get sharedProductTypeAll() {
        return this.productTypeAll.asObservable();
    }

    set nextProductTypeAll(productTypeAll: string) {
        this.productTypeAll.next(productTypeAll);
    }

    get sharedProductTypeTotal() {
        return this.productTypeTotal.asObservable();
    }

    set nextProductTypeTotal(productTypeTotal: string) {
        this.productTypeTotal.next(productTypeTotal);
    }

    get sharedIdStation() {
        return this.idStation.asObservable();
    }

    set nextIdStation(idStation: string) {
        this.idStation.next(idStation);
    }

    get sharedCreId() {
        return this.creId.asObservable();
    }

    set nextCreId(creId: string) {
        this.creId.next(creId);
    }

    get sharedPeriod() {
        return this.period.asObservable();
    }

    set nextPeriod(period: string) {
        this.period.next(period);
    }

    get sharedPeriodComparative() {
        return this.periodComparative.asObservable();
    }

    set nextPeriodComparative(periodComparative: number) {
        this.periodComparative.next(periodComparative);
    }

    get sharedPeriodEarning() {
        return this.periodEarning.asObservable();
    }

    set nextPeriodEarning(periodEarning: number) {
        this.periodEarning.next(periodEarning);
    }

    get sharedPeriodIndex() {
        return this.periodIndex.asObservable();
    }

    set nextPeriodIndex(periodIndex: string) {
        this.periodIndex.next(periodIndex);
    }

    get sharedPeriodDashboard() {
        return this.periodDashboard.asObservable();
    }

    set nextPeriodDashboard(periodDashboard: string) {
        this.periodDashboard.next(periodDashboard);
    }

    get sharedProduct() {
        return this.product.asObservable();
    }

    set nextProduct(product: number) {
        this.product.next(product);
    }

    get sharedTendency() {
        return this.tendency.asObservable();
    }

    set nextTendency(tendency: string) {
        this.tendency.next(tendency);
    }

    get sharedReason() {
        return this.reason.asObservable();
    }

    set nextReason(reason: string) {
        this.reason.next(reason);
    }

    get sharedStation() {
        return this.station.asObservable();
    }

    set nextStation(station: any) {
        this.station.next(station);
    }

    get sharedComplement() {
        return this.complement.asObservable();
    }

    set nextComplement(complement: string) {
        this.complement.next(complement);
    }

    get sharedComplementProducts() {
        return this.complementProducts.asObservable();
    }

    set nextComplementProducts(complementProducts: string) {
        this.complementProducts.next(complementProducts);
    }

    get sharedComplementFlags() {
        return this.complementFlags.asObservable();
    }

    set nextComplementFlags(complementFlags: string) {
        this.complementFlags.next(complementFlags);
    }

    get sharedComplementReasons() {
        return this.complementReasons.asObservable();
    }

    set nextComplementReasons(complementReasons: string) {
        this.complementReasons.next(complementReasons);
    }

    get sharedComplementZones() {
        return this.complementZones.asObservable();
    }

    set nextComplementZones(complementZones: string) {
        this.complementZones.next(complementZones);
    }

    get sharedVariablePriceIndex() {
        return this.variablePriceIndex.asObservable();
    }

    set nextVariablePriceIndex(variablePriceIndex: string) {
        this.variablePriceIndex.next(variablePriceIndex);
    }

    get sharedStatisticEvolution() {
        return this.statisticEvolution.asObservable();
    }

    set nextStatisticEvolution(statisticEvolution: string) {
        this.statisticEvolution.next(statisticEvolution);
    }

    get sharedStatisticTradeTracking() {
        return this.statisticTradeTracking.asObservable();
    }

    set nextStatisticTradeTracking(statisticTradeTracking: string) {
        this.statisticTradeTracking.next(statisticTradeTracking);
    }

    get sharedVariableDashboard() {
        return this.variableDashboard.asObservable();
    }

    set nextVariableDashboard(variableDashboard: string) {
        this.variableDashboard.next(variableDashboard);
    }

    get sharedVariableDashboardText() {
        return this.variableDashboardText.asObservable();
    }

    set nextVariableDashboardText(variableDashboardText: string) {
        this.variableDashboardText.next(variableDashboardText);
    }

    get sharedNameStation() {
        return this.nameStation.asObservable();
    }

    set nextNameStation(nameStation: string) {
        this.nameStation.next(nameStation);
    }

    get sharedNational() {
        return this.national.asObservable();
    }

    set nextNational(national: string) {
        this.national.next(national);
    }

    get sharedZone() {
        return this.zone.asObservable();
    }

    set nextZone(zone: string) {
        this.zone.next(zone);
    }


    get sharedMarkeType() {
        return this.markeType.asObservable();
    }

    set nextMarketType(markeType: string) {
        this.markeType.next(markeType);
    }

    get sharedEntity() {
        return this.entity.asObservable();
    }

    set nextEntity(entity: string) {
        this.entity.next(entity);
    }

    get sharedEntityText() {
        return this.entityText.asObservable();
    }

    set nextEntityText(entityText: string) {
        this.entityText.next(entityText);
    }


    get sharedZoneDashboard() {
        return this.zoneDashboard.asObservable();
    }

    set nextZoneDashboard(zoneDashboard: any) {
        this.zoneDashboard.next(zoneDashboard);
    }

    get sharedZoneDashboardText() {
        return this.zoneDashboardText.asObservable();
    }

    set nextZoneDashboardText(zoneDashboardText: any) {
        this.zoneDashboardText.next(zoneDashboardText);
    }

    get sharedChangeModal() {
        return this.changeModal.asObservable();
    }

    set nextChangeModal(changeModal: string) {
        this.changeModal.next(changeModal);
    }

    get sharedDate() {
        return this.date.asObservable();
    }

    set nextDate(date: number) {
        this.date.next(date);
    }

    get sharedWeeksAgo() {
        return this.weeksAgo.asObservable();
    }

    set nextWeeksAgo(weeksAgo: number) {
        this.weeksAgo.next(weeksAgo);
    }

    get sharedMonthsAgo() {
        return this.monthsAgo.asObservable();
    }

    set nextMonthsAgo(monthsAgo: number) {
        this.monthsAgo.next(monthsAgo);
    }

    get sharedDaysAgo() {
        return this.daysAgo.asObservable();
    }

    set nextDaysAgo(daysAgo: number) {
        this.daysAgo.next(daysAgo);
    }

    get sharedDaysEvolution() {
        return this.daysEvolution.asObservable();
    }

    set nextDaysEvolution(daysEvolution: number) {
        this.daysEvolution.next(daysEvolution);
    }

    get sharedDaysDashboard() {
        return this.daysDashboard.asObservable();
    }

    get sharedDaysOptimumPrice() {
        return this.daysOptimumPrice.asObservable();
    }

    set nextDaysOptimumPrice(daysOptimumPrice: number) {
        this.daysOptimumPrice.next(daysOptimumPrice);
    }

    set nextDaysDashboard(daysDashboard: number) {
        this.daysDashboard.next(daysDashboard);
    }

    get sharedDaysAgoActual() {
        return this.daysAgoActual.asObservable();
    }

    set nextDaysAgoActual(daysAgoActual: number) {
        this.daysAgoActual.next(daysAgoActual);
    }

    get sharedDaysAgoChange() {
        return this.daysAgoChange.asObservable();
    }

    set nextDaysAgoChange(daysAgoChange: number) {
        this.daysAgoChange.next(daysAgoChange);
    }

    get sharedDaysOptimumPx() {
        return this.daysOptimumPx.asObservable();
    }

    set nextDaysOptimumPx(daysOptimumPx: number) {
        this.daysOptimumPx.next(daysOptimumPx);
    }

    get sharedDaysAgoYesterday() {
        return this.daysAgoYesterday.asObservable();
    }

    set nextDaysAgoYesterday(daysAgoYesterday: number) {
        this.daysAgoYesterday.next(daysAgoYesterday);
    }

    get sharedZoneMarket() {
        return this.zoneMarket.asObservable();
    }

    set nextZoneMarket(zoneMarket: string) {
        this.zoneMarket.next(zoneMarket);
    }

    get sharedZoneMarketText() {
        return this.zoneMarketText.asObservable();
    }

    set nextZoneMarketText(zoneMarketText: string) {
        this.zoneMarketText.next(zoneMarketText);
    }

    get sharedTown() {
        return this.town.asObservable();
    }

    set nextTown(town: string) {
        this.town.next(town);
    }

    get sharedTownText() {
        return this.townText.asObservable();
    }

    set nextTownText(townText: string) {
        this.townText.next(townText);
    }

    get sharedRegion() {
        return this.region.asObservable();
    }

    set nextRegion(region: string) {
        this.region.next(region);
    }

    get sharedBrand() {
        return this.brand.asObservable();
    }

    set nextBrand(brand: string) {
        this.brand.next(brand);
    }

    get sharedBrandC1() {
        return this.brandC1.asObservable();
    }

    set nextBrandC1(brandC1: string) {
        this.brandC1.next(brandC1);
    }

    get sharedBrandC2() {
        return this.brandC2.asObservable();
    }

    set nextBrandC2(brandC2: string) {
        this.brandC2.next(brandC2);
    }

    get sharedBrandC3() {
        return this.brandC3.asObservable();
    }

    set nextBrandC3(brandC3: string) {
        this.brandC3.next(brandC3);
    }

    get sharedBrandC4() {
        return this.brandC4.asObservable();
    }

    set nextBrandC4(brandC4: string) {
        this.brandC4.next(brandC4);
    }
    get sharedBrandC5() {
        return this.brandC5.asObservable();
    }

    set nextBrandC5(brandC5: string) {
        this.brandC5.next(brandC5);
    }

    get sharedChangesData() {
        return this.changesData.asObservable();
    }

    set nextChangesData(changesData: boolean) {
        this.changesData.next(changesData);
    }

    get sharedVariable() {
        return this.variable.asObservable();
    }

    set nextVariable(variable: string) {
        this.variable.next(variable);
    }

    get sharedVariableEvolution() {
        return this.variableEvolution.asObservable();
    }

    set nextVariableEvolution(variableEvolution: string) {
        this.variableEvolution.next(variableEvolution);
    }

    get sharedVariableSecondEvolution() {
        return this.variableSecondEvolution.asObservable();
    }

    set nextVariableSecondEvolution(variableSecondEvolution: string) {
        this.variableSecondEvolution.next(variableSecondEvolution);
    }

    get sharedVariableBrand() {
        return this.variableBrand.asObservable();
    }

    set nextVariableBrand(variableBrand: string) {
        this.variableBrand.next(variableBrand);
    }

    get sharedVariablePxOpt() {
        return this.variablePxOpt.asObservable();
    }

    set nextVariablePxOpt(variablePxOpt: string) {
        this.variablePxOpt.next(variablePxOpt);
    }
    get sharedStationId() {
        return this.stationId.asObservable();
    }

    set nextStationId(stationId: any) {
        this.stationId.next(stationId);
    }

    get sharedGroup() {
        return this.group.asObservable();
    }

    set nextGroup(group: any) {
        this.group.next(group);
    }

    get sharedHeaderTableComparative() {
        return this.headerTableComparative.asObservable();
    }

    set nextHeaderTableComparative(headerTableComparative: any) {
        this.headerTableComparative.next(headerTableComparative);
    }

    get sharedTableComparative() {
        return this.tableComparative.asObservable();
    }

    set nextTableComparative(tableComparative: any) {
        this.tableComparative.next(tableComparative);
    }

    get sharedRol() {
        return this.rol.asObservable();
    }

    set nextRol(rol: string) {
        this.rol.next(rol);
    }

    get sharedTypePx() {
        return this.typePx.asObservable();
    }

    set nextTypePx(typePx: any) {
        this.typePx.next(typePx);
    }

    get sharedShowSubdivison() {
        return this.showSubdivison.asObservable();
    }

    set nextShowSubdivison(showSubdivison: number) {
        this.showSubdivison.next(showSubdivison);
    }

    get sharedShowSubsubdivison() {
        return this.showSubsubdivison.asObservable();
    }

    set nextShowSubsubdivison(showSubsubdivison: number) {
        this.showSubsubdivison.next(showSubsubdivison);
    }
}
