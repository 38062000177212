import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-products-options',
  templateUrl: './select-products-options.component.html',
  styleUrls: ['./select-products-options.component.scss']
})
export class SelectProductsOptionsComponent implements OnInit, OnDestroy {

  public items: any[] = [];
  public productType: any = [];
  productType$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
      this.productType = productType;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getProducts();
  }

  ngOnDestroy() {
    this.productType$.unsubscribe();
  }

  getProducts() {
    this.gasprecioService.getProductsOptions().subscribe((resp: any) => {
      const products: any = resp;
      products.forEach((element) => {
        if (element._id !== '6178671b46818a57510e921b' && element._id !== '6178671b46818a57510e921a') {
          if (element['name'] === 'Gasolina 95 E5 Premium'){
            element['name'] = 'Gas. 95 E5 Prem';
          }
          this.items.push({ name: this.gasprecioService.capitalizeFirstLetter(element['name']), value: element['id'] });
        }
      });
    });
  }

  changeProductType(event: any) {
    this.productType = event;
    this.sharedService.nextProduct = this.productType;
  }
}
