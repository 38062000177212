import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { environment } from '../../enviroments/enviroments';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public token: any;
    public idStation: any;
    public creId: any;

    constructor(
        private http: HttpClient,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();
        this.idStation = localStorage.getItem('idStation');
        this.token = localStorage.getItem('token');
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {

        const data = { 'email': email, 'password': password };

        return this.http.post<any>(`${environment.apiUrl}/v1/crm/users/login`, data);
    }

    signup(name: string, email: string, cre: string) {

        const data = { 'name': name, 'email': email, 'cre': cre };

        return this.http.post<any>(`${environment.apiUrl}/v1/crm/users/signup`, data)
            .pipe(map(user => {
                console.log('entro ', user);
            })
        );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        this.currentUserSubject.next(null);
    }

    sendRecoverPassword(email: any) {

        const data = { 'email': email };

        return new Promise(resolve => {
            this.http.post(`${environment.apiUrl}/v1/crm/users/auth/forgot_password`, data)
                .subscribe(async resp => {
                    resolve(true);
                });
        });
    }

    setPassword(password: any, token: any) {

        password.token = token;

        return new Promise(resolve => {
            this.http.put<any>(`${environment.apiUrl}/v1/crm/users/password`, password)
                .subscribe(user => {
                    resolve(true);
            });
        });
    }

    verifyAccount(token: any) {

        const data = '';

        return new Promise(resolve => {
            this.http.put<any>(`${environment.apiUrl}/v1/app/users/verify_account`, data, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).subscribe(async resp => {
                console.log(resp);
                return resp;
            });
        });
    }
}
