import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-table-sales-quartil',
  templateUrl: './table-sales-quartil.component.html',
  styleUrls: ['./table-sales-quartil.component.scss']
})
export class TableSalesQuartilComponent implements OnInit, OnDestroy {

  market = '';
  productType = 1;
  productType$!: Subscription;
  variable = 'sales'
  variable$!: Subscription;
  daysOptimumPx = 180;
  daysOptimumPx$!: Subscription;
  private subscriptions: Subscription = new Subscription();
  private chart: any;
  reqst = 0;
  rol;
  rowsCurrent: any;
  rowsLength: number;
  rowsRecord: any;
  val: any;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.subscriptions.add(
      combineLatest([
        this.sharedService.sharedVariablePxOpt,
        this.sharedService.sharedProduct,
        this.sharedService.sharedDaysOptimumPx
      ]).subscribe(([variable, productType, daysOptimumPx]) => {
        let shouldUpdate = false;

        if (this.variable !== variable) {
          this.variable = variable;
          shouldUpdate = true;
        }

        if (this.productType !== productType) {
          this.productType = productType;
          shouldUpdate = true;
        }

        if (this.daysOptimumPx !== daysOptimumPx) {
          this.daysOptimumPx = daysOptimumPx;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          this.getTableSalesQuartil();
        }
      })
    );
  }

  ngOnInit(): void {
    this.getTableSalesQuartil();
  }

  ngOnDestroy(): void {
    if(this.productType$){
      this.productType$.unsubscribe();
    }
    if(this.variable$){
      this.variable$.unsubscribe();
    }
    if(this.daysOptimumPx$){
      this.daysOptimumPx$.unsubscribe();
    }
  }

  getRowHeight(row: any) {
    return row.height;
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getTableSalesQuartil() {
    this.reqst = 0;
    this.gasprecioService.getTableSalesQuartilDays(this.productType, this.variable, this.daysOptimumPx).subscribe((data: any) => {
      if (data['record']) {
        this.rowsLength = (72 / data['record'].length);
        data['record'].map((element: any, index: number) => {
          if (element['flag_actual']) {
            this.market = element['market'];
          }

          element['n_obs'] = element['n_obs'] !== null ? element['n_obs'] : '-';
          element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
          element['diff_price_competitors'] = element['diff_price_competitors'] !== null ? element['diff_price_competitors'] : '-';
          element['sales'] = element['sales'] !== null ? element['sales'] : '-';
          element['earning'] = element['earning'] !== null ? element['earning'] : '-';
          element['sales_change'] = element['sales_change'] !== null ? element['sales_change'] : '-';
          element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
          element['margin'] = element['margin'] !== null ? element['margin'] : '-';
          element['sales_pct'] = element['sales_pct'] !== null ? element['sales_pct'] * 100 : '-';
        });

        data['current'].map((element: any, index: number) => {
          if (element['flag_actual']) {
            this.market = element['market'];
          }
          element['price_change'] = element['price_change'] !== null ? element['price_change'] : '-';
          element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
          element['diff_price_competitors'] = element['diff_price_competitors'] !== null ? element['diff_price_competitors'] : '-';
          element['sales7'] = element['sales7'] !== null ? element['sales7'] : '-';
          element['earning'] = element['earning'] !== null ? element['earning'] : '-';
          element['supply_price'] = element['supply_price'] !== null ? element['supply_price'] : '-';
          element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
          element['margin'] = element['margin'] !== null ? element['margin'] : '-';
        });

        this.rowsCurrent = data['current'];
        this.rowsRecord = data['record'];
      }
      this.reqst = 1;
    });
  }

}
