import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-chart-comparative-dashboard',
  templateUrl: './chart-comparative-dashboard.component.html',
  styleUrls: ['./chart-comparative-dashboard.component.scss']
})
export class ChartComparativeDashboardComponent implements OnInit, OnDestroy {

  currentStation: any;
  data: any;
  days = 30;
  days$!: Subscription;
  id;
  idGroup = '627034d4c1814c0379cc455a';
  idGroupSub$!: Subscription;
  months = 12;
  months$!: Subscription;
  idStation;
  period = 'day';
  period$!: Subscription;
  productType = 1;
  productType$!: Subscription;
  reqst = 0;
  rol;
  tendency: any = 'avg_selling_price';
  ppc: any;
  variableValue: any = 'selling_price';
  variableValue$: Subscription;
  weeks = 12;
  weeks$!: Subscription;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Comparativo Zona`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
    },
    yAxis: {
      title: {
        text: 'Precio de Venta',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        format: '{value:.3f} €'
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      formatter: function () {
        var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
        var currentPoint = this;
        var addedStations = [];
        this.series.chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
              tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b> ' + point.y.toFixed(3) + '€ </b><br/>';
            }
          });
        });
        return tooltip;
      }
      // pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: `Comparativo Zona`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -6
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');

    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.variableValue$ = sharedService.sharedVariableDashboard.subscribe((variableValue: string) => {
      if (this.variableValue !== variableValue) {
        this.variableValue = variableValue;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.idGroupSub$ = this.sharedService.sharedGroup.subscribe(idGroup => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }
    });
  }

  ngOnInit() {
    this.getDashboardComparative();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroupSub$.unsubscribe();
    this.variableValue$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
  }



  getDashboardComparative() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';

    this.gasprecioService.getDashboardComparative(this.idGroup, this.variableValue, this.productType, this.period, typeValue, n).subscribe({
      next: (data: any) => {
        if (this.period === 'month' && this.idGroup === '615efe30b318ce63b8971986') {
          this.data = data.slice(0, 8);
        } else {
          this.data = data;
        }
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const seriesData = [];
    this.data.forEach((element, index) => {
      let color = `rgb(${this.gasprecioService.colors[index].r}, ${this.gasprecioService.colors[index].g}, ${this.gasprecioService.colors[index].b})`;
      let pointFormat = {};
      if (this.variableValue === 'volume') {
        this.chartOptions['yAxis']['labels']['format'] = '{value:,.0f} lts.'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts. </b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      } else if (this.variableValue === 'quotient') {
        this.chartOptions['yAxis']['labels']['format'] = '{value:.3f}'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(3).replace('.',',') + '</b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      } else {
        this.chartOptions['yAxis']['labels']['format'] = '{value:.3f} €'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(3).replace('.', ',') + '€</b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      }
      const lineChartData = {
        name: element['zone']['zone_name'],
        data: element['values'].map((v: any) => v['value']),
        color: color,
        lineWidth: 1.5,
        marker: {
          radius: 4
        }
      };

      const lineXaxisLegendGenerator = {
        day: (v: any) => this.gasprecioService.parseDateDM(v.date),
        week: (v: any) => v.date_alias,
        month: (v: any) => this.gasprecioService.parseDateM(v.date)
      };

      const lineXaxisLegend = element['values'].map(lineXaxisLegendGenerator[this.period]);
      this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
      seriesData.push(lineChartData);
    });
    this.chartOptions.series = seriesData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

}
