import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product-total',
  templateUrl: './select-product-total.component.html',
  styleUrls: ['./select-product-total.component.scss']
})
export class SelectProductTotalComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { value: null, name: 'Total' },
    { value: 1, name: 'Gasoleo A' },
    { value: 2, name: 'Gasoleo B' },
    { value: 3, name: 'Gasoleo Premium' },
    { value: 4, name: 'Gas. 95 E5' },
    { value: 5, name: 'Gas. 95 E5 Prem' },
    { value: 6, name: 'Gas. 98 E5' }
  ];

  public productTypeTotal: any = [];
  public ngxDisabled = false;
  productTypeTotal$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productTypeTotal$ = this.sharedService.sharedProductTypeTotal.subscribe(productTypeTotal => {
      this.productTypeTotal = productTypeTotal;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.productTypeTotal$.unsubscribe();
  }

  changeProductType(event: any) {
    this.productTypeTotal = event;
    this.sharedService.nextProductTypeTotal = this.productTypeTotal;
  }
}
