import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { AuthGuard } from './helpers/guards/auth.guard';

const routes: Routes = [
  {
    path: 'gasprecio',
    loadChildren: () => import('src/app/modules/gasprecio/gasprecio.module').then(m => m.GasPrecioModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'estacion',
    loadChildren: () => import('src/app/modules/station/station.module').then(m => m.StationModule),
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'verify_account', component: VerifyAccountComponent },
  { path: 'login', component: LoginComponent },
  { path: 'set_password', component: SetPasswordComponent },
  { path: 'recover_password', component: RecoverPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
