
<div class="header-c" style="margin-bottom: 10px;margin-top: 10px;">
    <div class="select-type">
        <app-select-variable-px-opt></app-select-variable-px-opt>
    </div>
    Venta Promedio Diario
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Promedio de litros diarios vendidos por estación.
    </epy-tooltip>
    - Zona de Mercado
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Según el Indice de Precios, en que Zona o parte del Mercado te ubicas.<br>
        Baja: Indice <= 0,980 <br>
            Media Baja: 0,980 < Indice < 1,000 <br>
                Media Alta: 1,000 <= Indice < 1,020<br>
                    Alta: Indice => 1,020 <br>
    </epy-tooltip>
</div>
<div id="container" style="width: 100%;">
    <canvas id="canvas-1" [ngStyle]="{ 'display':productType === 1 ? 'inline-block': 'none'}"></canvas>
    <canvas id="canvas-2" [ngStyle]="{ 'display':productType === 2 ? 'inline-block': 'none'}"></canvas>
    <canvas id="canvas-3" [ngStyle]="{ 'display':productType === 3 ? 'inline-block': 'none'}"></canvas>
    <canvas id="canvas-4" [ngStyle]="{ 'display':productType === 4 ? 'inline-block': 'none'}"></canvas>
    <canvas id="canvas-5" [ngStyle]="{ 'display':productType === 5 ? 'inline-block': 'none'}"></canvas>
    <canvas id="canvas-6" [ngStyle]="{ 'display':productType === 6 ? 'inline-block': 'none'}"></canvas>
</div>