import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './enviroments/enviroments';
import { applyPolyfills, defineCustomElements } from "@entropyx/epy-components/loader";
import { BootstrapModuleFn as Bootstrap, hmr, WebpackModule } from '@ngxs/hmr-plugin'

declare const module: WebpackModule;

if (environment.production) {
  enableProdMode();
}

const bootstrap: Bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

// For Hot Module Replacement as seen in:
// https://levelup.gitconnected.com/hmr-in-angular-cli-project-eb8512ef514b
bootstrap().catch(err => console.error(err))
