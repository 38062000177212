import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';


@Component({
  selector: 'app-chart-sale-buy',
  templateUrl: './chart-sale-buy.component.html',
  styleUrls: ['./chart-sale-buy.component.scss']
})
export class ChartSaleBuyComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  purchasePrice: any;
  date: any;
  days = 30;
  days$!: Subscription;
  event$!: Subscription;
  idGroup = '627034d4c1814c0379cc455a';
  idGroup$!: Subscription;
  months = 12;
  months$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 1;
  productType$!: Subscription;
  reqst = 0;
  route;
  rol;
  showYAxis = false;
  tendency: any = 'avg_selling_price';
  sellingPrice;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Precio de Venta - Precio Compra`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Precio TAR',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f} €',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Precio Compra',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f} €',
          style: {
            fontSize: '11px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
      pointFormat: '{series.name}: <b>{point.y:.3f} €</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 2
      }
    },
    exporting: {
      filename: `Precio de Venta - Precio Compra`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    this.rol = localStorage.getItem('rol');

    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        if (this.reqst > 1) {
          this.zoneSelected = '';
          this.getDashboardSellingPricePurchase();
        }
      }
    });

    this.zoneSelected$ = sharedService.sharedZoneDashboard.subscribe((zone: string) => {
      if (zone === 'Total') {
        this.zoneSelected = null;
        if (zone !== this.zoneSelected) {
          this.getDashboardSellingPricePurchase();
        }
      } else {
        if (zone !== this.zoneSelected) {
          this.zoneSelected = zone;
          this.getDashboardSellingPricePurchase();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePurchase();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePurchase();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePurchase();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 1) {
          this.getDashboardSellingPricePurchase();
        }
      }
    });
  }

  ngOnInit() {
    this.getDashboardSellingPricePurchase();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.zoneSelected$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
    this.event$.unsubscribe();
  }

  getDashboardSellingPricePurchase() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    const url = this.station ? this.gasprecioService.getDashboardSellingPricePurchaseStation(this.period, typeValue, n) : this.gasprecioService.getDashboardSellingPricePurchase(this.idGroup, this.zoneSelected, this.period, typeValue, n);
    url.subscribe({
      next: (data: any) => {
        const info = data;
        this.purchasePrice = info['purchase_price'];
        this.sellingPrice = info['selling_price'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.productType === null ? this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[6]) : this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.productType - 1]);
    const colorLight = this.productType === null ? this.gasprecioService.getColorRGBAString(this.gasprecioService.colorsProductsLight[6]) : this.gasprecioService.getColorRGBAString(this.gasprecioService.colorsProductsLight[this.productType - 1]);

    const sellingPriceChartData = {
      name: 'Precio de Venta',
      data: this.productType === null ? this.sellingPrice.map((comp: any) => {
        if (comp['product'] === null) {
          return null;
        } else {
          return +comp['product'];
        }
      }) : this.sellingPrice.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: color,
      lineWidth: 2,
      marker: { radius: 2 },
      yAxis: 0
    };

    const purchaseChartData: any = {
      name: 'Precio de Compra',
      data: this.productType === null ? this.purchasePrice.map((comp: any) => {
        if (comp['product'] === null) {
          return null;
        } else {
          return +comp['product'];
        }
      }) : this.purchasePrice.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: colorLight,
      lineWidth: 2,
      yAxis: this.showYAxis ? 0 : 1
    };

    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.purchasePrice.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions.series = [sellingPriceChartData, purchaseChartData];
    setTimeout(() => { this.reqst = 2; }, 100);
  }

}
