import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product-all',
  templateUrl: './select-product-all.component.html',
  styleUrls: ['./select-product-all.component.scss']
})
export class SelectProductAllComponent implements OnDestroy {

  @Input() showAll: boolean = false;
  public items: any[] = [
    { name: 'Todos', value: 'all' },
    { name: 'Gasoleo A', value: 'Gasoleo A' },
    { name: 'Gasolina 95 E5', value: 'Gasolina 95 E5' },
    { name: 'Gasolina 98 E5', value: 'Gasolina 98 E5' },
    { name: 'Gasoleo Premium', value: 'Gasoleo Premium' }
  ];

  public productTypeAll: any = [];
  public ngxDisabled = false;
  productTypeAll$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productTypeAll$ = this.sharedService.sharedProductTypeAll.subscribe(productTypeAll => {
      if (this.productTypeAll !== productTypeAll) {
        this.productTypeAll = productTypeAll;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit() { 
    if (this.showAll) {
      this.sharedService.nextProductTypeAll = 'all';
    }
  }

  ngOnDestroy() {
    this.productTypeAll$.unsubscribe();
  }

  changeProductAll(event: any) {
    this.productTypeAll = event;
    this.sharedService.nextProductTypeAll = this.productTypeAll;
  }
}
