import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-months',
  templateUrl: './select-months.component.html',
  styleUrls: ['./select-months.component.scss']
})
export class SelectMonthsComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { value: 12, name: 'Últ. 12 meses' },
    { value: 18, name: 'Últ. 18 meses' },
    { value: 24, name: 'Últ. 24 meses' },
    { value: 30, name: 'Últ. 30 meses' },
    { value: 36, name: 'Últ. 36 meses' }
  ];

  public monthsAgo: any = [];
  public ngxDisabled = false;
  monthsAgo$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.monthsAgo$ = this.sharedService.sharedMonthsAgo.subscribe(monthsAgo => {
      this.monthsAgo = monthsAgo;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.monthsAgo$.unsubscribe();
  }

  changeMonthsAgo(event: any) {
    this.monthsAgo = event;
    this.sharedService.nextMonthsAgo = this.monthsAgo;
  }
}
