<div class="header-c">
    Dif Precio vs Competencia
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Promedio diario de la diferencia de precio de venta por estación vs su competencia.
    </epy-tooltip>
    - Indice de Precios
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es < 1.0 significa
            que tu precio es menor, mientras que si es> 1.0 tu precio es más alto que el de tus competidores.
    </epy-tooltip>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="reqst===2">
    <div>
        <highcharts-chart id="mychart5" [Highcharts]="Highcharts" [options]="chartOptions"
            [ngStyle]="{ 'height': station ? 'auto': '310px'}" style="width: 100%;display: block;"></highcharts-chart>
    </div>
</div>