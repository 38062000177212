import * as moment from 'moment-timezone';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-quotient-chart',
  templateUrl: './quotient-chart.component.html',
  styleUrls: ['./quotient-chart.component.scss']
})
export class QuotientChartComponent implements OnInit, OnDestroy {

  @ViewChild('editModal') editModal: TemplateRef<any>;
  @Input() filterDays: any = false;
  @Input() section: any = false;
  days = 180;
  days$!: Subscription;
  diffSellingPrice;
  changeModal$!: Subscription;
  changeModal;
  productType = 1;
  productType$!: Subscription;
  quotient: any;
  reqst = 0;
  stationId$!: Subscription;
  stationId: any;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Dif Precio vs Competencia - Indice de Precios`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Dif Precio vs Competencia',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f} €',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Indice de Precios',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f}',
          style: {
            fontSize: '11px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 2
      },
      bar: {
        animation: {
          duration: 1000
        }
      }
    },
    exporting: {
      filename: `Dif Precio vs Competencia - Indice de Precios `,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getChartQuotient();
      }
    });

    this.days$ = this.sharedService.sharedDaysOptimumPrice.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getChartQuotient();
        }
      }
    });
  }

  async ngOnInit() {
    if (this.section) {
      this.getChartQuotient();
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
    this.days$.unsubscribe();
  }

  getChartQuotient() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null) {
      return;
    }

    const url = this.filterDays ? this.gasprecioService.getChartQuotient(this.stationId, this.days) : this.gasprecioService.getChartQuotient(this.stationId, 30);

    url.subscribe({
      next: (data: any) => {
        this.quotient = data['record'];
        this.diffSellingPrice = data['diff_selling_price'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const colorBlue = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[6]);
    const colorOrange = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[7]);

    const lineXaxisLegend = this.quotient.map((v: any) => this.gasprecioService.parseDateDM(v.date));
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    let numberLabels = this.chartOptions['xAxis']['categories'].length;
    const borderW = numberLabels >= 90 ? 0 : 2;

    const tarChartData: any = {
      name: 'Indice de Precios',
      data: this.quotient.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: colorBlue,
      lineWidth: 2,
      marker: { radius: 5, symbol: 'triangle', lineColor: '#ffffff', lineWidth: 1 },
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': ' + this.y.toFixed(3).replace('.', ',') + '</b><br>Click aquí para ver el detalle del día';
        }
      },
      cursor: 'pointer',
      events: {
        click: (event) => {
          this.chartNClicked(event['point']['index'], 'Precio de Venta')
        }
      }
    };

    const purchaseChartData: any = {
      name: 'Dif Precio vs Competencia',
      type: 'column',
      data: this.diffSellingPrice.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: colorOrange,
      borderColor: colorOrange,
      borderWidth: borderW,
      yAxis: 0,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': ' + this.y.toFixed(3).replace('.', ',') + ' € </b><br>Click aquí para ver el detalle del día';
        }
      },
      cursor: 'pointer',
      events: {
        click: (event) => {
          this.chartNClicked(event['point']['index'], 'Precio Competencia')
        }
      }
    };


    this.chartOptions.series = [purchaseChartData, tarChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  chartNClicked(index: any, label: string) {
    const date = this.diffSellingPrice[index].date;
    const name = this.diffSellingPrice[index].name;
    this.changeDataTable(date, name);
    this.openModal();
  }

  changeDataTable(date, name) {
    this.sharedService.nextDate = moment(date).tz('Europe/Madrid').format('YYYY-MM-DD');
    this.sharedService.nextProductType = this.productType;
    this.sharedService.nextStationId = this.stationId;
    this.sharedService.nextNameStation = name;
    setTimeout(() => {
      this.sharedService.nextChangeModal = 'table';
    }, 100);
  }

  openModal() {
    this.modalService.open(this.editModal, { windowClass: 'tableModal' });
  }

  closeModal() {
    this.modalService.dismissAll(this.editModal);
  }
}