import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-market',
  templateUrl: './select-market.component.html',
  styleUrls: ['./select-market.component.scss']
})
export class SelectMarketComponent implements OnInit, OnDestroy {

  public items: any[] = [{ value: 'all&zone_type=', name: 'Zonas' }];
  public idGroup: any;
  public zone: any = [];
  public ngxDisabled = true;
  id;
  idGroup$: Subscription;
  sharedZone$: Subscription;
  showAvia = false;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showAvia = this.idGroup === '66426306b626ea9c0e47f8cd' ? true : false;
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }
    });

    this.sharedZone$ = sharedService.sharedZone.subscribe((zone: any) => {
      this.zone = zone;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getZones();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
    this.sharedZone$.unsubscribe();
  }

  getZones() {
    this.gasprecioService.getZones(this.idGroup).subscribe((data: any) => {
      const zones: any = data;
      zones.forEach((element: any) => {
        this.items.push({ name: this.gasprecioService.capitalizeFirstLetter(element['name']), value: `${element['id']}&zone_type=${element['type']}` });
        if (element['default'] && (this.zone === null || this.zone === '')) {
          this.zone = `${element['id']}&zone_type=${element['type']}`;
        }
      });

      this.ngxDisabled = false;
    });
  }

  changeMarket(event: any) {
    this.zone = event;
    this.sharedService.nextZone = this.zone;
  }
}
