import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-central-tendency',
  templateUrl: './select-central-tendency.component.html',
  styleUrls: ['./select-central-tendency.component.scss']
})
export class SelectCentralTendencyComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { value: 'avg_selling_price', name: 'Precio promedio' },
    { value: 'mode_selling_price', name: 'Moda' }
  ];

  public tendency: any = [];
  tendency$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.tendency$ = this.sharedService.sharedTendency.subscribe(tendency => {
      this.tendency = tendency;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.tendency$.unsubscribe();
  }

  changeTendency(event: any) {
    this.tendency = event;
    this.sharedService.nextTendency = this.tendency;
  }
}
