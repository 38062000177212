import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-entity',
  templateUrl: './select-entity.component.html',
  styleUrls: ['./select-entity.component.scss']
})
export class SelectEntityComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { name: 'Provincia', value: null }
  ];
  public entitySelected: any = [];
  public nationalSelected: any;
  public ngxDisabled = true;
  entitySelected$: Subscription;
  nationalSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {

    this.nationalSelected$ = sharedService.sharedNational.subscribe((nationalSelected: string) => {
      if (this.nationalSelected !== nationalSelected) {
        this.nationalSelected = nationalSelected;
        if (nationalSelected === '' || nationalSelected === '1' || nationalSelected === 'Nacional') {
          this.ngxDisabled = true;
          this.entitySelected = null;
          this.sharedService.nextEntity = null;
        } else {
          this.ngxDisabled = false;
        }
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getEntities();
  }

  ngOnDestroy() {
    this.nationalSelected$.unsubscribe();
  }

  getEntities() {
    this.gasprecioService.getEntities().subscribe((data: any) => {
      const entities: any = data;
      entities.forEach((element: any) => {
        element.name = this.gasprecioService.capitalizeFirstLetter(element.name);
        this.items.push({ name: element.name, value: element.entidad_id });
      });
      if (this.nationalSelected === '' || this.nationalSelected === '1' || this.nationalSelected === 'Nacional') {
        this.ngxDisabled = true;
      } else {
        this.ngxDisabled = false;
      }
    });
  }

  changeEntity(event: any) {
    this.entitySelected = event;
    this.sharedService.nextEntity = this.entitySelected;
    this.items.map((entity: any) => {
      if (entity.value === this.entitySelected) {
        this.sharedService.nextEntityText = entity.name;
      }
    });
  }

}
