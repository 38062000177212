<p class="text-center station-date">
    <strong>{{nameStation}} - {{date| date:'dd/MM/yy'}}</strong>
</p>

<div class="table-overflow-container" #epltable>
    <table *ngIf="reqst === 2" class="table table-hover htable btable no-margin no-padding">
        <thead>
            <tr>
                <td colspan="5" class="text-center both-sides">
                    <span class="info">
                        <strong>
                            Volumen de Venta
                        </strong>
                    </span>
                </td>
            </tr>
            <tr class="row-title font-weight-bold">
                <td class="text-center with-left">
                    <span>
                        <strong>
                            Real
                        </strong>
                    </span>
                </td>
                <td class="text-center">
                    <span>
                        <strong>
                            Forecast
                        </strong>
                    </span>
                </td>
                <td class="text-center">
                    <span>
                        <strong>
                            Dif Lts.
                        </strong>
                    </span>
                </td>
                <td class="text-center">
                    <span>
                        <strong>
                            Dif %
                        </strong>
                    </span>
                </td>
                <td class="text-center with-right">
                    <span>
                        <strong>
                            Alerta
                        </strong>
                    </span>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-center with-left">
                    {{alert.liters| number:'1.0-0':'es'}}
                </td>
                <td class="text-center">
                    {{alert.liters_forecast| number:'1.0-0':'es'}}
                </td>
                <td class="text-center">
                    {{alert.diff_liters | number:'1.0-0':'es'}}
                </td>
                <td class="text-center">
                    {{alert.diff_liters_pct}}
                </td>
                <td class="text-center with-right">
                    <i class="fa fa-flag" *ngIf="alert.flag !== 'gray' && alert.flag !== null" class="{{alert.flag}}" aria-hidden="true"></i>
                    <i class="fa fa-question" *ngIf="alert.flag === 'gray' && alert.flag !== null" class="{{alert.flag}}" aria-hidden="true"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <app-table-loader *ngIf="reqst === 1"></app-table-loader>
</div>
<br><br>
<div class="table-overflow-container" #epltable>
    <table *ngIf="reqst === 2" class="table table-hover htable btable no-margin no-padding">
        <thead>
            <tr>
                <td colspan="5" class="text-center both-sides">
                    <span class="info">
                        <strong>
                            Variables
                        </strong>
                    </span>
                </td>
            </tr>
            <tr class="row-title font-weight-bold">
                <td style="width: 30%;" class="text-center with-left">
                </td>
                <td style="width: 20%;" class="text-center">
                    <span>
                        <strong>
                            {{yesterday| date:'dd/MM/yy'}}
                        </strong>
                    </span>
                </td>
                <td style="width: 20%;" class="text-center">
                    <span>
                        <strong>
                            {{date| date:'dd/MM/yy'}}
                        </strong>
                    </span>
                </td>
                <td style="width: 20%;" class="text-center">
                    <span>
                        <strong>
                            Dif
                        </strong>
                    </span>
                </td>
                <td style="width: 10%;" class="text-center with-right">
                    <span>
                        <strong>

                        </strong>
                    </span>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of rows">
                <td class="with-left text-right">
                    <span style="padding-right: 10px;">
                        <strong>
                            {{row.name}}
                        </strong>
                    </span>
                </td>
                <td class="text-center">
                    <span *ngIf="row.name === 'Inventario'">
                        {{row.yesterday_value | number:'1.0-0':'es'}}
                    </span>
                    <span *ngIf="row.name !== 'Inventario'">
                        {{row.yesterday_value}}
                    </span>
                </td>
                <td class="text-center">
                    <span *ngIf="row.name === 'Inventario'">
                        {{row.today_value | number:'1.0-0':'es'}}
                    </span>
                    <span *ngIf="row.name !== 'Inventario'">
                        {{row.today_value}}
                    </span>
                </td>
                <td class="text-center">
                    <span *ngIf="row.name === 'Inventario'">

                    </span>
                    <span *ngIf="row.name !== 'Inventario'">
                        {{row.diff_value}}
                    </span>
                </td>
                <td class="text-center with-right">
                    <i class="fa fa-arrow-up trend-up" aria-hidden="true"
                        *ngIf="row.diff > 0 && row.name !== 'Inventario' && row.name !== 'Tráfico' && row.name !== 'Obra'"></i>
                    <i class="fa fa-arrow-down trend-down" aria-hidden="true"
                        *ngIf="row.diff < 0 && row.name !== 'Inventario' && row.name !== 'Tráfico' && row.name !== 'Obra'"></i>
                    <span *ngIf="row.diff === 0 && row.name !== 'Inventario' && row.name !== 'Tráfico' && row.name !== 'Obra'">
                        -
                    </span>

                    <i style="font-size: 18px;" title="Hubo mayor tráfico respecto al día anterior"
                        class="fa-solid fa-road-circle-check green" aria-hidden="true"
                        *ngIf="row.flag === 1 && row.name === 'Tráfico'"></i>
                    <i style="font-size: 18px;" title="No hubo mayor tráfico respecto al día anterior"
                        class="fa-solid fa-road-circle-exclamation red" aria-hidden="true"
                        *ngIf="row.flag=== -1 && row.name === 'Tráfico'"></i>

                    <span *ngIf="row.flag === 0 && row.name === 'Tráfico'">
                        -
                    </span>

                    <i style="font-size: 18px;" title="Hubo obra, obstrucción vial o calle cerrada."
                        class="fa-solid fa-road-barrier orange" aria-hidden="true" *ngIf="row.diff === 1 && row.name === 'Obra'"></i>
                    
                    <span *ngIf="(row.flag === 0 || row.flag === -1) && row.name === 'Obra'">
                        -
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <app-table-loader *ngIf="reqst === 1"></app-table-loader>
</div>