import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-chart-margin-volumen',
  templateUrl: './chart-margin-volumen.component.html',
  styleUrls: ['./chart-margin-volumen.component.scss']
})
export class ChartMarginVolumenComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  date: any;
  days = 30;
  days$!: Subscription;
  idGroup = '627034d4c1814c0379cc455a';
  idGroup$!: Subscription;
  months = 12;
  months$!: Subscription;
  event$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 1;
  productType$!: Subscription;
  purchaseMargin;
  reqst = 0;
  route;
  rol;
  showScale = true;
  showColor = false;
  changeColor = false;
  tendency: any = 'avg_selling_price';
  typeVolume: string = 'avg';
  volumen: any;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;

  public items: any[] = [
    { name: 'Promedio', value: 'avg' },
    { name: 'Acumulado', value: 'accumulated' }
  ];

  public statisticSelected: any = [];
  public ngxDisabled = true;
  statisticSelected$: Subscription;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Volumen de Venta - Margen €`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Volumen de Venta',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:,.0f} lts.',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Margen €',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f} €',
          style: {
            fontSize: '11px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      column: {
        animation: {
          duration: 1000
        },
        stacking: 'normal'
      }
    },
    exporting: {
      filename: `Volumen de Venta - Margen €`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router,
    public sanitizer: DomSanitizer
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');
    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        if (this.reqst > 1) {
          this.zoneSelected = '';
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.zoneSelected$ = sharedService.sharedZoneDashboard.subscribe((zone: string) => {
      if (zone === 'Total') {
        this.zoneSelected = null;
        if (zone !== this.zoneSelected) {
          this.getDashboardVolumenPurchaseMargin();
        }
      } else {
        if (zone !== this.zoneSelected) {
          this.zoneSelected = zone;
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });
  }

  ngOnInit() {
    this.getDashboardVolumenPurchaseMargin();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.zoneSelected$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
    this.event$.unsubscribe();
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  changeVariable(event: any) {
    this.typeVolume = event;
    if (this.reqst > 1) {
      this.getDashboardVolumenPurchaseMargin();
    }
  }


  getDashboardVolumenPurchaseMargin() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    const url = this.station ? this.gasprecioService.getDashboardVolumenPurchaseMarginStation(this.period, typeValue, n) : this.gasprecioService.getDashboardVolumenPurchaseMargin(this.idGroup, this.zoneSelected, this.period, typeValue, n);
    url.subscribe({
      next: (data: any) => {
        const info = data;
        this.volumen = info['volume'];
        this.purchaseMargin = info['purchase_margin'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.productType === null ? this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[6]) : this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.productType - 1]);
    const colorGold = this.gasprecioService.getColorString(this.gasprecioService.colors[0]);

    const minVolume = Math.min(...this.volumen
      .map((comp: any) => this.productType === null ? +comp['product'] : +comp['product_' + this.productType])
      .filter((value: number) => value !== null && value !== 0)
    );

    const tarChartData: any = {
      name: 'Margen €',
      data: this.productType === null ? this.purchaseMargin.map((comp: any) => {
        if (comp['product'] === null) {
          return null;
        } else {
          return +comp['product'];
        }
      }) : this.purchaseMargin.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: colorGold,
      lineWidth: 2,
      marker: { radius: 4, symbol: 'diamond' },
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': ' + this.y.toFixed(3).replace('.', ',') + ' € </b>';
        }
      },
    };

    let volumenChartData: any;
    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.volumen.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    let numberLabels = this.chartOptions['xAxis']['categories'].length;
    const borderW = numberLabels >= 90 ? 0 : 2;
    if (this.productType === null) {
      this.showScale = true;
      const colors = [
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[0]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[1]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[2]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[3]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[4]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[5])
      ];

      const productTypes = ['gasoleo a', 'gasoleo b', 'gasoleo premium', 'gasolina 95 e5', 'gasolina 98 e5', 'gasolina 95 e5 premium'];

      volumenChartData = productTypes.map((type, index) => ({
        name: this.gasprecioService.capitalizeFirstLetter(type),
        type: 'column',
        data: this.volumen.map((comp: any) => {
          if (comp['product_' + (index + 1)] === null) {
            return null;
          } else {
            return +comp['product_' + (index + 1)];
          }
        }),
        color: this.showColor ? colors[index] : '#FF8F07',
        borderColor: this.showColor ? colors[index] : '#FF8F07',
        borderWidth: borderW,
        yAxis: 0,
        stack: 'total',
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.</b>';
          }
        }
      }));
    } else {
      volumenChartData = [{
        name: 'Volumen de Venta',
        type: 'column',
        data: this.volumen.map((comp: any) => {
          if (comp['product_' + this.productType] === null) {
            return null;
          } else {
            return +comp['product_' + this.productType];
          }
        }),
        color: color,
        borderColor: color,
        borderWidth: borderW,
        yAxis: 0,
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.</b>';
          }
        }
      }];
    }

    this.chartOptions['yAxis'][0]['min'] = this.showScale ? 0 : minVolume;
    this.chartOptions.series = [...volumenChartData, tarChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  toggleColors(){
    this.showColor = !this.showColor;
    this.changeColor = !this.changeColor;
    setTimeout(() => {
      this.setData();
    }, 100);
  }
}

