import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variables-intelligence-market',
  templateUrl: './select-variables-intelligence-market.component.html',
  styleUrls: ['./select-variables-intelligence-market.component.scss']
})
export class SelectVariablesIntelligenceMarketComponent implements  OnDestroy {

  public items: any[] = [
    { name: 'Precio Venta', value: 'selling_price' }
  ];

  public variableSelected: any = [];
  public ngxDisabled = true;
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariable.subscribe((variableSelected: string) => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariable = this.variableSelected;
  }

}
