import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-dif-min-price-competitors',
  templateUrl: './table-dif-min-price-competitors.component.html',
  styleUrls: ['./table-dif-min-price-competitors.component.scss']
})
export class TableDifMinPriceCompetitorsComponent implements OnInit, OnDestroy {

  competitors;
  productType = 1;
  productType$!: Subscription;
  rowsCompetitors: any;
  reqst = 0;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getDiffPxMin();
        }
      }
    });
  }

  ngOnInit(): void {
    this.getDiffPxMin();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
  }

  getDiffPxMin() {
    this.reqst = 0;
    this.gasprecioService.getDiffPxMinDays(this.productType, 180).subscribe((data: any) => {
      if (data['competitors']) {
        this.competitors = data['competitors'].length;
        data['competitors'].forEach(element => {
          element['name'] = this.gasprecioService.removeSADECV(element['name']);
        });
        
        this.rowsCompetitors = data['competitors'];
      }
      this.reqst = 1;
    });
  }

}
