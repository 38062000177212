import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-reason',
  templateUrl: './select-reason.component.html',
  styleUrls: ['./select-reason.component.scss']
})
export class SelectReasonComponent implements OnInit, OnDestroy {

  @Input() reason: any;
  @Input() idAlert: any;
  public items: any[] = [];
  public reasonSelected: any = [];
  public ngxDisabled = true;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getReasons(false);
  }

  ngOnDestroy() {

  }

  getReasons(add: boolean) {
    this.gasprecioService.getReasons(add).subscribe((data: any) => {
      const reasons: any = data;
      reasons.forEach((element: any, index) => {
        const pos = index + 1;
        this.items.push({ name: pos + '.- ' + element.heading, value: element._id });
      });
      this.reasonSelected = this.reason;
      this.ngxDisabled = false;
    });
  }

  changeReason(event: any) {
    this.reasonSelected = event;
    if (event === '62deb2c39cb48f8227314898') {
      this.addReason();
    } else {
      this.sharedService.nextReason = this.reasonSelected;
      this.saveReason();
    }
  }

  saveReason(reason?: any) {
    if (reason) {
      this.reasonSelected = reason;
    }

    if (this.reason === this.reasonSelected) {
      return;
    }

    const json = {
      'data': {
        'cause': this.reasonSelected
      }
    };

    this.gasprecioService.setComment(json, this.idAlert).subscribe((data: any) => {
      if (data['message'] === 'OK alert updated') {
        this.reason = data['data']['alert']['cause'];
        this.successReason();
        return true;
      } else {
        this.errorReason();
      }
    }, err => {
      console.log(err);
    });
  }

  async successReason() {
    Swal.fire({
      title: '¡Causa actualizada!',
      text: 'La causa ha sido actualizada con éxito.',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      preConfirm: () => {
        this.items = [];
        this.getReasons(true);
      }
    });
  }

  async errorReason() {
    Swal.fire({
      title: '¡Error!',
      text: 'La causa no ha sido actualizada con éxito.',
      icon: 'error',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar'
    });
  }

  addReason() {
    Swal.fire({
      title: 'Describe la causa de la alerta',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#00e07b',
      cancelButtonColor: '#fa1975',
      confirmButtonText: `Agregar`,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: (reason) => {
        if (reason === '' || reason === null) {
          return false;
        } else {
          this.setReason(reason);
        }
      }
    });
  }

  setReason(reason: any) {
    const data = {
      'data': [
        {
          'heading': reason,
          'description': null
        }
      ]
    };

    this.gasprecioService.setReason(data).subscribe((resp: any) => {
      if (resp['message'] === 'OK data saved') {
        this.sharedService.nextReason = resp['data']['causes'][0]['_id'];
        this.successAddReason(resp['data']['causes'][0]['_id']);
      } else {
        this.errorAddReason();
      }
    });
  }

  async successAddReason(reason?: any) {
    Swal.fire({
      title: '¡Causa agregada!',
      text: 'La causa ha sido agregada con éxito.',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      preConfirm: () => {
        this.saveReason(reason);
      }
    });
  }

  async errorAddReason() {
    Swal.fire({
      title: '¡Error!',
      text: 'La causa no ha sido agregada con éxito.',
      icon: 'error',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar'
    });
  }
}
