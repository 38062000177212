import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';


@Component({
  selector: 'app-chart-index-sale',
  templateUrl: './chart-index-sale.component.html',
  styleUrls: ['./chart-index-sale.component.scss']
})
export class ChartIndexSaleComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  date: any;
  days = 180;
  days$!: Subscription;
  idGroup = '627034d4c1814c0379cc455a';
  idGroup$!: Subscription;
  event$!: Subscription;
  productType = 1;
  productType$!: Subscription;
  index;
  reqst = 0;
  route;
  rol;
  stationId;
  showScale = false;
  volumen: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Volumen de Venta - Indice de Precios`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Volumen de Venta',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:,.0f} lts.',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Indice de Precios',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:.3f}',
          style: {
            fontSize: '11px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      column: {
        animation: {
          duration: 1000
        },
        stacking: 'normal'
      }
    },
    exporting: {
      filename: `Volumen de Venta - Indice de Precios`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');
    this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysOptimumPrice.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getSalesIndex();
        }
      }
    });
  }

  ngOnInit() {
    this.getSalesIndex();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.event$.unsubscribe();
    this.days$.unsubscribe();
  }

  getSalesIndex() {
    this.reqst = 1;
    this.stationId = localStorage.getItem('idStation');
    const url = this.gasprecioService.getChartSalesOptimum(this.stationId, this.days, 0);
    url.subscribe({
      next: (data: any) => {
        const info = data;
        this.volumen = info['record'];
        this.index = info['quotient'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.productType - 1]);
    const colorBlue = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[6]);

    const indexChartData: any = {
      name: 'Indice de Precios',
      data: this.index.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: colorBlue,
      lineWidth: 2,
      marker: { radius: 4, symbol: 'triangle', lineColor: '#ffffff', lineWidth: 1 },
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': ' + this.y.toFixed(3).replace('.', ',') + '</b>';
        }
      },
    };

    let volumenChartData: any;
    const lineXaxisLegend = this.volumen.map((v: any) => this.gasprecioService.parseDateDM(v.date));
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    let numberLabels = this.chartOptions['xAxis']['categories'].length;
    const borderW = numberLabels >= 90 ? 0 : 2;

    volumenChartData = [{
      name: 'Volumen de Venta',
      type: 'column',
      data: this.volumen.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      color: color,
      borderColor: color,
      borderWidth: borderW,
      yAxis: 0,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.</b>';
        }
      }
    }];

    this.chartOptions.series = [...volumenChartData, indexChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }
}
