import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../models/user.model';
import Swal from 'sweetalert2';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    token: any;
    idStation: any;
    creId: any;
    rol;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authenticationService: AuthService,
        private sharedService: SharedService
    ) {
        this.token = localStorage.getItem('token');
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();

        if (this.authenticationService.currentUserValue && this.token) {
            this.router.navigate(['/gasprecio/monitor']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .subscribe((data: any) => {
                this.idStation = data.user.stations[0]._id;
                this.creId = data.user.stations[0].station_id;
                this.token = data.user.token;
                this.rol = data.user.rol;
                data.authdata = window.btoa(this.f.email.value + ':' + this.f.password.value);
                localStorage.setItem('creId', this.creId);
                this.sharedService.nextCreId = this.creId;
                localStorage.setItem('currentUser', JSON.stringify(data));
                localStorage.setItem('idStation', this.idStation);
                this.sharedService.nextIdStation = this.idStation;
                localStorage.setItem('token', this.token);
                localStorage.setItem('rol', this.rol);
                this.sharedService.nextRol = this.rol;
                this.currentUserSubject.next(data);
                this.reloadPage();
            }, err => {
                this.errorLogin();
            });
    }

    reloadPage(): void {
        window.location.reload();
    }

    errorLogin() {
        Swal.fire({
            title: 'El usuario o la contraseña son inválidos',
            text: 'Intentalo de nuevo',
            icon: 'error',
            confirmButtonColor: '#00e07b',
            confirmButtonText: 'Aceptar'
        });
    }

    async recoverPassword() {
        const { value: email } = await Swal.fire({
            title: 'Recuperar contraseña',
            text: 'Ingrese su cuenta de correo electrónico y le enviaremos instrucciones para recuperar su contraseña.',
            input: 'email',
            inputPlaceholder: 'Correo electrónico',
            confirmButtonText: 'Enviar',
            confirmButtonColor: '#cd2120'
        });

        if (email) {
            const result = await this.authenticationService.sendRecoverPassword(email);
            Swal.fire(`Se ha enviado un correo con instrucciones a ${email}`);
        }
    }
}
