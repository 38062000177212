import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variable-px-opt',
  templateUrl: './select-variable-px-opt.component.html',
  styleUrls: ['./select-variable-px-opt.component.scss']
})
export class SelectVariablePxOptComponent implements OnDestroy {

  public items: any[] = [
    { value: 'sales', name: 'Ventas Litros' },
    { value: 'utility', name: 'Ganancia Bruta' }
  ];

  public variableSelected: any = 'sales';
  public ngxDisabled = true;
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariablePxOpt.subscribe((variableSelected: string) => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
      } 
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariablePxOpt = this.variableSelected;
  }

}
