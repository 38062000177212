import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HAnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import HAnnotations from 'highcharts/modules/annotations';
import HC_exportData from 'highcharts/modules/export-data';
import HC_drilldown from 'highcharts/modules/drilldown';
import HC_exportingOffline from 'highcharts/modules/offline-exporting';
import HC_exportingExporting from 'highcharts/modules/exporting';
import "chartjs-chart-box-and-violin-plot";
import { AngularDraggableModule } from 'angular2-draggable';
import { ChartComparativeDashboardComponent } from './components/chart-comparative-dashboard/chart-comparative-dashboard.component';
import { ChartDifPriceQuotientComponent } from './components/chart-dif-price-quotient/chart-dif-price-quotient.component';
import { ChartEarningComponent } from './components/chart-earning/chart-earning.component';
import { ChartIndexSaleComponent } from './components/chart-index-sale/chart-index-sale.component';
import { ChartMarginVolumenComponent } from './components/chart-margin-volumen/chart-margin-volumen.component';
import { ChartQuotientSaleComponent } from './components/chart-quotient-sale/chart-quotient-sale.component';
import { ChartSaleBuyComponent } from './components/chart-sale-buy/chart-sale-buy.component';
import { ChartSalePpcComponent } from './components/chart-sale-ppc/chart-sale-ppc.component';
import { ChartSalesPeriodComponent } from './components/chart-sales-period/chart-sales-period.component';
import { ChartSalesSeasonallyComponent } from './components/chart-sales-seasonally/chart-sales-seasonally.component';
import { ChartSalesQuartilComponent } from './components/chart-sales-quartil/chart-sales-quartil.component';
import { ChartsModule } from 'ng2-charts';
import { ChartTarBuyComponent } from './components/chart-tar-buy/chart-tar-buy.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { LastUpdateComponent } from './components/last-update/last-update.component';
import { MultiselectFlagComponent } from './components/multiselect-flag/multiselect-flag.component';
import { MultiselectProductComponent } from './components/multiselect-product/multiselect-product.component';
import { MultiselectReasonComponent } from './components/multiselect-reason/multiselect-reason.component';
import { MultiselectVariablesComponent } from './components/multiselect-variables/multiselect-variables.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSelectModule } from 'ngx-select-ex';
import { PriceRulesComponent } from './components/price-rules/price-rules.component';
import { QuotientChartComponent } from './components/quotient-chart/quotient-chart.component';
import { RouterModule } from '@angular/router';
import { SalesChartComponent } from './components/sales-chart/sales-chart.component';
import { SearchPipe } from './pipes/search.pipe';
import { SelectCentralTendencyComponent } from './components/select-central-tendency/select-central-tendency.component';
import { SelectDashboardDaysComponent } from './components/select-dashboard-days/select-dashboard-days.component';
import { SelectDashboardPeriodComponent } from './components/select-dashboard-period/select-dashboard-period.component';
import { SelectDashboardZoneComponent } from './components/select-dashboard-zone/select-dashboard-zone.component';
import { SelectDaysActualComponent } from './components/select-days-actual/select-days-actual.component';
import { SelectDaysChangeComponent } from './components/select-days-change/select-days-change.component';
import { SelectDaysComponent } from './components/select-days/select-days.component';
import { SelectDaysEvolutionComponent } from './components/select-days-evolution/select-days-evolution.component';
import { SelectDaysYesterdayComponent } from './components/select-days-yesterday/select-days-yesterday.component';
import { SelectDaysOptimumPriceComponent } from './components/select-days-optimum-price/select-days-optimum-price.component';
import { SelectGroupComponent } from './components/select-group/select-group.component';
import { SelectMonthsComponent } from './components/select-months/select-months.component';
import { SelectPeriodComparativeComponent } from './components/select-period-comparative/select-period-comparative.component';
import { SelectPeriodComponent } from './components/select-period/select-period.component';
import { SelectPeriodIndexComponent } from './components/select-period-index/select-period-index.component';
import { SelectProductComponent } from './components/select-product/select-product.component';
import { SelectProductsOptionsComponent } from './components/select-products-options/select-products-options.component';
import { SelectProductTotalComponent } from './components/select-product-total/select-product-total.component';
import { SelectReasonComponent } from './components/select-reason/select-reason.component';
import { SelectStatisticsTradeTrackingComponent } from './components/select-statistics-trade-tracking/select-statistics-trade-tracking.component';
import { SelectVariablesDashboardComponent } from './components/select-variables-dashboard/select-variables-dashboard.component';
import { SelectVariablesPriceIndexComponent } from './components/select-variables-price-index/select-variables-price-index.component';
import { SelectVariablePxOptComponent } from './components/select-variable-px-opt/select-variable-px-opt.component';
import { SelectWeeksComponent } from './components/select-weeks/select-weeks.component';
import { SellingPriceChartComponent } from './components/selling-price-chart/selling-price-chart.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableAlertsComponent } from './components/table-alerts/table-alerts.component';
import { TableComparativeDashboardComponent } from './components/table-comparative-dashboard/table-comparative-dashboard.component';
import { TableLoaderComponent } from './components/table-loader/table-loader.component';
import { TableSalesQuartilComponent } from './components/table-sales-quartil/table-sales-quartil.component';
import { TrafficChartComponent } from './components/traffic-chart/traffic-chart.component';
import { SelectDaysPxOptimumComponent } from "./components/select-days-px-optimum/select-days-px-optimum.component";
import { ChartQuotientSaleLevelComponent } from "./components/chart-quotient-sale-level/chart-quotient-sale-level.component";
import { ChartSalesQuartilComparativeComponent } from "./components/chart-sales-quartil-comparative/chart-sales-quartil-comparative.component";
import { TableSalesQuartilComparativeComponent } from "./components/table-sales-quartil-comparative/table-sales-quartil-comparative.component";
import { TableDifMaxPriceCompetitorsComponent } from './components/table-dif-max-price-competitors/table-dif-max-price-competitors.component';
import { TableDifMaxPriceComponent } from './components/table-dif-max-price/table-dif-max-price.component';
import { ChartDifMaxPriceComponent } from './components/chart-dif-max-price/chart-dif-max-price.component';
import { TableDifMinPriceComponent } from './components/table-dif-min-price/table-dif-min-price.component';
import { ChartDifMinPriceComponent } from './components/chart-dif-min-price/chart-dif-min-price.component';
import { TableDifMinPriceCompetitorsComponent } from './components/table-dif-min-price-competitors/table-dif-min-price-competitors.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SortDirective } from '../gasprecio/directives/sort.directive';
import { SelectMarketComponent } from './components/select-market/select-market.component';
import { SelectVariablesIntelligenceComponent } from './components/select-variables-intelligence/select-variables-intelligence.component';
import { SelectVariablesIntelligenceEvolutionComponent } from './components/select-variables-intelligence-evolution/select-variables-intelligence-evolution.component';
import { SelectVariablesIntelligenceMarketComponent } from './components/select-variables-intelligence-market/select-variables-intelligence-market.component';
import { SelectVariablesSecondIntelligenceEvolutionComponent } from './components/select-variables-second-intelligence-evolution/select-variables-second-intelligence-evolution.component';
import { SelectBrandsComponent } from './components/select-brands/select-brands.component';
import { SelectEntityComponent } from './components/select-entity/select-entity.component';
import { SelectMarketTypeComponent } from './components/select-market-type/select-market-type.component';
import { SelectMarketZoneComponent } from './components/select-market-zone/select-market-zone.component';
import { SelectProductAllComponent } from './components/select-product-all/select-product-all.component';
import { SelectRegionEvolutionComponent } from './components/select-region-evolution/select-region-evolution.component';
import { SelectTownComponent } from './components/select-town/select-town.component';
import { SelectTypeNationalComponent } from './components/select-type-national/select-type-national.component';
import { SelectStatisticsEvolutionComponent } from './components/select-statistics-evolution/select-statistics-evolution.component';
HC_exporting(Highcharts);
HC_exportData(Highcharts);
HC_drilldown(Highcharts);
HC_exportingOffline(Highcharts);
HC_exportingExporting(Highcharts);
HAnnotations(Highcharts);
HAnnotationsAdvanced(Highcharts);

@NgModule({
    declarations: [
        HeaderComponent,
        SidebarComponent,
        SearchPipe,
        PriceRulesComponent,
        TableLoaderComponent,
        LastUpdateComponent,
        SelectProductComponent,
        SelectGroupComponent,
        SelectDaysComponent,
        SelectDaysActualComponent,
        SelectPeriodComponent,
        SelectProductTotalComponent,
        SelectCentralTendencyComponent,
        SelectReasonComponent,
        MultiselectVariablesComponent,
        SelectDaysYesterdayComponent,
        SelectDaysOptimumPriceComponent,
        MultiselectProductComponent,
        MultiselectReasonComponent,
        MultiselectFlagComponent,
        QuotientChartComponent,
        SalesChartComponent,
        SellingPriceChartComponent,
        TrafficChartComponent,
        SelectDaysChangeComponent,
        TableAlertsComponent,
        ChartDifPriceQuotientComponent,
        ChartMarginVolumenComponent,
        ChartQuotientSaleComponent,
        ChartSaleBuyComponent,
        ChartSalePpcComponent,
        ChartTarBuyComponent,
        SelectDashboardZoneComponent,
        SelectVariablesDashboardComponent,
        ChartComparativeDashboardComponent,
        TableComparativeDashboardComponent,
        SelectDashboardPeriodComponent,
        SelectDashboardDaysComponent,
        SelectDaysEvolutionComponent,
        SelectVariablesPriceIndexComponent,
        SelectVariablePxOptComponent,
        SelectPeriodIndexComponent,
        SelectWeeksComponent,
        SelectMonthsComponent,
        SelectPeriodComparativeComponent,
        SelectStatisticsTradeTrackingComponent,
        SelectProductsOptionsComponent,
        SelectMarketComponent,
        ChartEarningComponent,
        ChartSalesPeriodComponent,
        ChartSalesSeasonallyComponent,
        ChartIndexSaleComponent,
        ChartSalesQuartilComponent,
        TableSalesQuartilComponent,
        SelectDaysPxOptimumComponent,
        ChartQuotientSaleLevelComponent,
        ChartSalesQuartilComparativeComponent,
        TableSalesQuartilComparativeComponent,
        ChartDifMaxPriceComponent,
        TableDifMaxPriceComponent,
        TableDifMaxPriceCompetitorsComponent,
        ChartDifMinPriceComponent,
        TableDifMinPriceComponent,
        TableDifMinPriceCompetitorsComponent,
        SortDirective,
        SelectVariablesIntelligenceComponent,
        SelectVariablesIntelligenceEvolutionComponent,
        SelectVariablesIntelligenceMarketComponent,
        SelectVariablesSecondIntelligenceEvolutionComponent,
        SelectBrandsComponent,
        SelectEntityComponent,
        SelectMarketTypeComponent,
        SelectMarketZoneComponent,
        SelectProductAllComponent,
        SelectRegionEvolutionComponent,
        SelectTownComponent,
        SelectTypeNationalComponent,
        SelectStatisticsEvolutionComponent,
    ],
    exports: [
        HeaderComponent,
        SidebarComponent,
        TableLoaderComponent,
        PriceRulesComponent,
        AngularDraggableModule,
        LastUpdateComponent,
        SelectProductComponent,
        SelectGroupComponent,
        SelectDaysComponent,
        SelectDaysActualComponent,
        SelectPeriodComponent,
        SelectProductTotalComponent,
        SelectCentralTendencyComponent,
        SelectReasonComponent,
        MultiselectVariablesComponent,
        SelectDaysYesterdayComponent,
        SelectDaysOptimumPriceComponent,
        MultiselectProductComponent,
        MultiselectReasonComponent,
        MultiselectFlagComponent,
        QuotientChartComponent,
        SalesChartComponent,
        SellingPriceChartComponent,
        TrafficChartComponent,
        SelectDaysChangeComponent,
        TableAlertsComponent,
        ChartDifPriceQuotientComponent,
        ChartMarginVolumenComponent,
        ChartQuotientSaleComponent,
        ChartSaleBuyComponent,
        ChartSalePpcComponent,
        ChartTarBuyComponent,
        SelectDashboardZoneComponent,
        SelectVariablesDashboardComponent,
        ChartComparativeDashboardComponent,
        TableComparativeDashboardComponent,
        SelectDashboardPeriodComponent,
        SelectDashboardDaysComponent,
        SelectDaysEvolutionComponent,
        SelectVariablesPriceIndexComponent,
        SelectVariablePxOptComponent,
        SelectPeriodIndexComponent,
        SelectWeeksComponent,
        SelectMonthsComponent,
        SelectPeriodComparativeComponent,
        SelectStatisticsTradeTrackingComponent,
        SelectProductsOptionsComponent,
        SelectMarketComponent,
        ChartEarningComponent,
        ChartSalesPeriodComponent,
        ChartSalesSeasonallyComponent,
        ChartIndexSaleComponent,
        ChartSalesQuartilComponent,
        TableSalesQuartilComponent,
        SelectDaysPxOptimumComponent,
        ChartQuotientSaleLevelComponent,
        ChartSalesQuartilComparativeComponent,
        TableSalesQuartilComparativeComponent,
        ChartDifMaxPriceComponent,
        TableDifMaxPriceComponent,
        TableDifMaxPriceCompetitorsComponent,
        ChartDifMinPriceComponent,
        TableDifMinPriceComponent,
        TableDifMinPriceCompetitorsComponent,
        SortDirective,
        SelectVariablesIntelligenceComponent,
        SelectVariablesIntelligenceEvolutionComponent,
        SelectVariablesIntelligenceMarketComponent,
        SelectVariablesSecondIntelligenceEvolutionComponent,
        SelectBrandsComponent,
        SelectEntityComponent,
        SelectMarketTypeComponent,
        SelectMarketZoneComponent,
        SelectProductAllComponent,
        SelectRegionEvolutionComponent,
        SelectTownComponent,
        SelectTypeNationalComponent,
        SelectStatisticsEvolutionComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        AngularDraggableModule,
        NgxSelectModule,
        NgMultiSelectDropDownModule,
        ChartsModule,
        NgxDatatableModule,
        HighchartsChartModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
    constructor() {
        // Establece las traducciones globales de Highcharts
        Highcharts.setOptions({
            lang: {
                // Traducción al español
                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                contextButtonTitle: 'Exportar gráfico',
                downloadPNG: 'Descargar como PNG',
                downloadJPEG: 'Descargar como JPEG',
                downloadPDF: 'Descargar como PDF',
                downloadSVG: 'Descargar como SVG',
                downloadCSV: 'Descargar como CSV',
                downloadXLS: 'Descargar como Excel',
                viewFullscreen: 'Ver en Pantalla Completa',
                viewData: 'Ver en Tabla',
                hideData: 'Esconder la Tabla',
                printChart: 'Imprimir Gráfica',
                resetZoom: 'Restablecer zoom',
                resetZoomTitle: 'Restablecer zoom al nivel 1:1',
                exitFullscreen: 'Salir Pantalla Completa',
                thousandsSep: ".",
                decimalPoint: ','
            }
        });
    }
}
