import * as Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-chart-sales-quartil',
  templateUrl: './chart-sales-quartil.component.html',
  styleUrls: ['./chart-sales-quartil.component.scss']
})
export class ChartSalesQuartilComponent implements OnInit, OnDestroy {

  boxplotData;
  id;
  idStation;
  isRendering = false;
  max;
  median;
  productType = 1;
  productType$!: Subscription;
  variable = 'sales'
  variable$!: Subscription;
  daysOptimumPx = 180;
  daysOptimumPx$!: Subscription;
  private subscriptions: Subscription = new Subscription();
  private chart: any;
  rol;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.subscriptions.add(
      combineLatest([
        this.sharedService.sharedVariablePxOpt,
        this.sharedService.sharedProduct,
        this.sharedService.sharedDaysOptimumPx
      ]).subscribe(([variable, productType, daysOptimumPx]) => {
        let shouldUpdate = false;

        if (this.variable !== variable) {
          this.variable = variable;
          shouldUpdate = true;
        }

        if (this.productType !== productType && !this.isRendering) {
          this.productType = productType;
          shouldUpdate = true;
        }

        if (this.daysOptimumPx !== daysOptimumPx) {
          this.daysOptimumPx = daysOptimumPx;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          this.getTableSalesQuartil();
        }
      })
    );
  }

  ngOnInit(): void {
    if (!this.isRendering) {
      this.getTableSalesQuartil();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTableSalesQuartil() {
    this.isRendering = true;
    this.boxplotData = {
      labels: [],
      datasets: [{
        label: 'Cuartil',
        backgroundColor: [],
        medianColor: [],
        borderColor: ['#000000', '#000000', '#000000', '#000000'],
        borderWidth: 1,
        outliers: false,
        outlierRadius: 0,
        outlierColor: '#000000',
        padding: 1,
        itemRadius: 0,
        data: [],
      }]
    };

    this.gasprecioService.getChartSalesQuartilDays(this.productType, this.variable, this.daysOptimumPx).subscribe({
      next: (data: any) => {
        this.max = this.roundValues(data['max_sale'], 1000);
        data['data'].map((element: any, index: number) => {
          const sanitizedElement = element.map((value: number | null) => value !== null ? value : 0);

          const boxplotDataObject = {
              min: sanitizedElement[0] || 0,
              q1: sanitizedElement[1] || 0,
              median: sanitizedElement[2] || 0,
              q3: sanitizedElement[3] || 0,
              max: sanitizedElement[4] || 0
          };

          this.boxplotData.datasets[0].data.push(boxplotDataObject);
      });

        data['labels'].map((label: any, index: number) => {
          switch (label) {
            case 'low':
              this.boxplotData.labels.push('Baja')
              this.boxplotData.datasets[0].backgroundColor.push('rgba(229, 229, 229, .8)')
              this.boxplotData.datasets[0].medianColor.push('black')
              break;
            case 'medium_low':
              this.boxplotData.labels.push('Media Baja')
              this.boxplotData.datasets[0].backgroundColor.push('rgba(114, 114, 114, .8)')
              this.boxplotData.datasets[0].medianColor.push('white')
              break;
            case 'medium_high':
              this.boxplotData.labels.push('Media Alta')
              this.boxplotData.datasets[0].backgroundColor.push('rgba(0, 155, 211, .8)')
              this.boxplotData.datasets[0].medianColor.push('white')
              break;
            case 'high':
              this.boxplotData.labels.push('Alta')
              this.boxplotData.datasets[0].backgroundColor.push('rgba(130, 207, 255, .8)')
              this.boxplotData.datasets[0].medianColor.push('black')
              break;
            default:
              break;
          }
        });

      },
      complete: () => {
        if (this.chart) {
          this.chart.destroy();
        }
        this.chart = this.createChart();
        this.isRendering = false;
      }
    });
  }

  roundValues(value, round) {
    const result = Math.round(value / round) * round;
    return result;
  }

  createChart() {
    const canvas: any = document.getElementById("canvas-" + this.productType);
    const step = +((this.max - 0) / 10).toFixed(0);
    return new Chart(canvas.getContext('2d'), {
      type: 'boxplot',
      data: this.boxplotData,
      options: {
        responsive: true,
        legend: {
          position: 'top',
          display: false
        },
        scales: {
          xAxes: [
            {
              display: false
            }
          ],
          yAxes: [
            {
              ticks: {
                callback(value: any) {
                  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                  return value + ' lts.';
                },
                max: this.max + step,
                beginAtZero: true,
                stepSize: step
              }
            }
          ]
        },
        tooltips: {
          position: 'nearest',
          titleAlign: 'left',
          titleFontSize: 12,
          titleMarginBottom: 12,
          mode: 'label',
          callbacks: {
            label: (tooltipItem: any, data: any): any => {
              const dataset = data['datasets'][0]['data'][tooltipItem.index];
              if (dataset && dataset.min !== undefined && dataset.q1 !== undefined &&
                  dataset.median !== undefined && dataset.q3 !== undefined && dataset.max !== undefined) {
                const tooltipValues = [
                  `Mínimo: ${dataset.min.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.'}`,
                  `25% Cuartil: ${dataset.q1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.'}`,
                  `Mediana: ${dataset.median.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.'}`,
                  `75% Cuartil: ${dataset.q3.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.'}`,
                  `Máximo: ${dataset.max.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.'}`,
                ];
                return tooltipValues;
              } else {
                return 'Datos no disponibles';
              }
            }
          }
        },
        title: {
          display: false
        },
        plugins: {
          datalabels: {
            display: true,
            formatter: (val, ctx) => {
              if (val.median > 0) {
                return val.median.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' lts.';
              } else {
                return '';
              }           },
            color: [...this.boxplotData.datasets[0].medianColor],
            font: {
              weight: 'bold',
              size: 12
            },
            anchor: 'end',
            align: 'end',
            offset: 0,
            padding: 0
          }
        }
      }
    });
  }
}