import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-type-national',
  templateUrl: './select-type-national.component.html',
  styleUrls: ['./select-type-national.component.scss']
})
export class SelectTypeNationalComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { name: 'Nacional', value: '1' },
    { name: 'Por Zona', value: 0 },
    { name: 'Por Provincia', value: '0' }
  ];
  public nationalSelected: any = [];
  public ngxDisabled = true;
  nationalSelected$: Subscription;
  page = 'station';
  page$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.nationalSelected$ = sharedService.sharedNational.subscribe((national: string) => {
      if (national !== this.nationalSelected) {
        this.nationalSelected = national;
        switch (this.nationalSelected) {
          case '1':
            this.sharedService.nextEntity = null;
            this.sharedService.nextTown = null;
            this.sharedService.nextZone = null;
            break;
          case '0':
            this.sharedService.nextZone = null;
            this.sharedService.nextTown = null;
            break;
          case 0:
            this.sharedService.nextEntity = null;
            this.sharedService.nextTown = null;
            break;
          default:
            break;
        }
      }
    });

    this.page$ = sharedService.sharedPageMetric.subscribe(page => {
      if (page !== this.page) {
        this.page = page;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.nationalSelected$.unsubscribe();
    this.page$.unsubscribe();
  }

  changeNational(event: any) {
    this.nationalSelected = event;
    this.sharedService.nextNational = this.nationalSelected;
  }

}
