import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { Subscription } from 'rxjs';
HighchartsMore(Highcharts);

@Component({
  selector: 'app-chart-dif-max-price',
  templateUrl: './chart-dif-max-price.component.html',
  styleUrls: ['./chart-dif-max-price.component.scss']
})
export class ChartDifMaxPriceComponent implements OnInit, OnDestroy {

  boxplotData;
  chartOptions;
  daysAgo = 180
  daysAgo$!: Subscription;
  Highcharts = Highcharts;
  id;
  idStation;
  isRendering = false;
  max;
  median;
  productType = 1;
  productType$!: Subscription;
  reqst = 0;
  rol;
  variableSelected = 'sales';
  variableSelected$!: Subscription;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
      if (this.productType !== productType && !this.isRendering) {
        this.productType = productType;
        this.getDiffPxMax();
      }
    });

    this.variableSelected$ = this.sharedService.sharedVariablePxOpt.subscribe(variableSelected => {
      if (this.variableSelected !== variableSelected && !this.isRendering) {
        this.variableSelected = variableSelected;
        this.getDiffPxMax();
      }
    });

    this.daysAgo$ = this.sharedService.sharedDaysOptimumPx.subscribe(daysAgo => {
      if (this.daysAgo !== daysAgo) {
        this.daysAgo = daysAgo;
        this.getDiffPxMax();
      }
    });
  }

  ngOnInit(): void {
    if (!this.isRendering) {
      this.getDiffPxMax();
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.variableSelected$.unsubscribe();
    this.daysAgo$.unsubscribe();
  }

  getDiffPxMax() {
    this.isRendering = true;
    this.reqst = 0;
    this.max = 0;
    this.gasprecioService.getDiffPxMax(this.productType, this.variableSelected, this.daysAgo).subscribe({
      next: (data: any) => {
        let boxPlots = data['graph_data_180'];
        if (this.productType === 1) {
          this.max = this.roundValues(data['max_sale'], 1000);
        } else {
          this.max = this.roundValues(data['max_sale'], 500);
        }
        const varText = this.variableSelected === 'sales' ? 'Ventas' : 'Ganancia Bruta';
        let box = {
          name: `${varText} Históricas`,
          type: 'boxplot',
          data: [],
          tooltip: {
            headerFormat: '<em>Distancia: {point.key}</em><br/><br/>',
            pointFormatter: function () {
              if (varText === 'Ventas') {
                return '<span style="color:' +
                  this.series.color + '">\u25CF</span> <b> ' +
                  this.series.name + '</b><br/>' +
                  'Mínimo: ' + (this.low.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                  '25% Cuartil: ' + (this.q1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                  'Mediana: ' + (this.median.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                  '75% Cuartil: ' + (this.q3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                  'Máximo: ' + (this.high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>'
              } else {
                return '<span style="color:' +
                  this.series.color + '">\u25CF</span> <b> ' +
                  this.series.name + '</b><br/>' +
                  'Mínimo: $' + (this.low.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                  '25% Cuartil: $' + (this.q1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                  'Mediana: $' + (this.median.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                  '75% Cuartil: $' + (this.q3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                  'Máximo: $' + (this.high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>'
              }
            }
          },
          showInLegend: false
        };

        boxPlots['data'].map((element: any, index: number) => {
          switch (index) {
            case 0:
              box['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(237, 237, 237, 1)',
                medianColor: 'black'
              })
              break;
            case 1:
              box['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(229, 229, 229, 1)',
                medianColor: 'black'
              })
              break;
            case 2:
              box['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(114, 114, 114, .8)',
                medianColor: 'black'
              })

              break;
            case 3:
              box['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(0, 155, 211, .8)',
                medianColor: 'black'
              })
              break;
            case 4:
              box['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(130, 207, 255, .8)',
                medianColor: 'black'
              })
              break;
            default:
              break;
          }
        });

        this.chartOptions = {
          credits: {
            enabled: false
          },
          chart: {
            type: 'boxplot',
            events: {
              load: function () {
                var chart = this;
                var points_180 = chart.series[0].points;
                points_180.forEach(function (point_180, index) {
                  const median = point_180.median ? point_180.median.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
                  let textColor = index === 0 || index === 3 ? 'black' : 'white';
                  const textVar = varText === 'Ventas' ? median + ' lts' : '$ ' + median;
                  const posText = varText === 'Ventas' ? 60 : 54;
                  chart.renderer.text(textVar, point_180.plotX + posText, point_180.medianPlot + 20)
                    .css({
                      color: textColor, // Establece el color del texto según la condición del índice
                      fontSize: '11px',
                      fontWeight: 'bold'
                    })
                    .attr({
                      zIndex: 5 // Ajusta el zIndex para asegurarte de que el texto esté encima de otros elementos
                    })
                    .add();
                });
              }
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Negativo', 'Nula', 'Poca', 'Media', 'Mucha'],
            title: {
              text: ''
            },
            labels: {
              enabled: false
            }
          },
          yAxis: {
            title: {
              text: ''
            },
            labels: {
              formatter: function () {
                let value = this.value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                if (varText === 'Ventas') {
                  return value + ' lts.';
                } else {
                  return '$ ' + value;
                } 
              }
            }
          },
          plotOptions: {
            boxplot: {
              lineWidth: 1,
              color: 'black',
              medianWidth: 1.5,
              pointWidth: 90,
              groupPadding: 1,
              whiskerLength: 98,
            }
          },
          tooltip: {
            boxplot: {
              enabled: true
            },
            line: {
              enabled: false
            }
          },
          series: [box]
        };
      },
      complete: () => {
        this.isRendering = false;
        this.reqst = 1;
        setTimeout(() => {
          const chart = Highcharts.chart('container-max-highcharts-dif', this.chartOptions);
        }, 10);
      }
    });
  }

  roundValues(value, round) {
    const result = Math.round(value / round) * round;
    return result;
  }

}