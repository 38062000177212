<div class="header-c" style="margin-bottom: 10px;">
    Volumen de Venta
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Promedio de litros diarios vendidos por estación.
    </epy-tooltip>
    - Indice de Precios
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es < 1,000 significa
            que tu precio es menor, mientras que si es> 1.0 tu precio es más alto que el de tus competidores.
    </epy-tooltip>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="reqst===2">
    <div>
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
            style="width: 100%;height: 380px;display: block;"></highcharts-chart>
    </div>
</div>