<div style="display: inline-block;">
    Precio Venta
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Precio de venta promedio diario reportado a la CRE por estación.
    </epy-tooltip> 
     - Precio de Competencia
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Precio promedio de los competidores con mayor impacto <br>en tu volumen de venta (mayor
        correlación).
    </epy-tooltip>
    ------>
    &nbsp; <i title="Ver Evolución Competencia" class="fa fa-line-chart chart" aria-hidden="true" (click)="getEvolution();openModalChart();"></i>
</div>
<div class="flex-item" *ngIf="reqst===2">
    <div>
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
            style="width: 100%;height: 380px;display: block;overflow: hidden;"></highcharts-chart>
    </div>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<ng-template #editModal let-modal class="modal-dialog big-dialog">
    <div class="modal-header" #tableModal>
        <h5 class="modal-title" id="downloadPricesLabel">
        </h5>
        <button type="button" class="close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0;">
        <app-table-alerts></app-table-alerts>
    </div>
    <div class="footer-modal" style="padding-top: 0;">

    </div>
</ng-template>

<ng-template #chartModal let-modal class="modal-dialog extra-big-dialog modal-dialog-centered">
    <div class="modal-header">
        <h5 class="modal-title" id="downloadPricesLabel">
            <p class="text-center station-date" style="margin-bottom: 0 !important;">
                <strong>{{nameStation}}</strong>
            </p>
        </h5>
        <button type="button" class="close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0;">
        <div class="flex-item" *ngIf="reqstEvol===2">
            <div>
                <highcharts-chart [Highcharts]="Highcharts" [options]="chartEvolOptions"
                    style="width: 100%; height: 80vh; display: block;"></highcharts-chart>
            </div>
        </div>
        <div *ngIf="reqstEvol===1" class="text-center loader-container">
            <div class="spinner-grow align-middle text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="footer-modal" style="padding-top: 0;">

    </div>
</ng-template>
