<div class="header-c">
    Utilidad Bruta
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Volumen de venta en litros multiplicado por su margen bruto.
    </epy-tooltip>
    &nbsp; &nbsp;&nbsp;
    <input type="checkbox" id="checkbox-scale-earning" [(ngModel)]="showScale" (ngModelChange)="setData()"  *ngIf="productType !== null"> <label
        style="font-size: 13px;margin-bottom: 0;margin-left: 1%;" for="checkbox-scale-earning"  *ngIf="productType !== null">Ver escala desde
        0</label>
        &nbsp;
    <input type="checkbox" id="checkbox-colors-earning" [(ngModel)]="changeColor" (ngModelChange)="toggleColors()" *ngIf="productType === null"> <label
        style="font-size: 13px;margin-bottom: 0;margin-left: 1%;" for="checkbox-colors-earning"  *ngIf="productType === null">Ver productos por separado</label>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="reqst===2">
    <div style="margin-right: 1%;">
        <highcharts-chart id="mychart6" [Highcharts]="Highcharts" [options]="chartOptions"
            [ngStyle]="{ 'height': station ? 'auto': '310px'}" style="width: 100%;display: block;"></highcharts-chart>
    </div>
</div>
