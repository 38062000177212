<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <div class="navbar-header">
            <a class="navbar-brand" aria-expanded="false" id="3">
                <div class="header-container">
                    <b>
                        <img src="https://gp-pricing-es-prod.s3.us-east-2.amazonaws.com/assets/images/logo.png" alt="homepage" class="logo" />
                        <i class="fa fa-bars menu" (click)="openSideBar()"></i>
                    </b>

                    <a class="nav-link dropdown-toggle waves-effect waves-dark" [routerLink]="'/gasprecio/monitor'"
                        (click)="clickIcon('Monitor');" *ngIf="isMobile">
                        <i class="mdi mdi-gas-station icon-mobile" [ngClass]="{'active': (page$ | async) === 'Monitor' && isMobile}"></i>
                    </a>
                    
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" [routerLink]="'/gasprecio/general'"
                        [queryParams]="{view: 'rdc'}" (click)="clickIcon('Tablero');"
                        *ngIf="idGroup === '615efe30b318ce63b8971986' && isMobile">
                        <i class="mdi mdi-television-guide icon-mobile"
                            [ngClass]="{'active': (page$ | async) === 'General' && isMobile}"></i>
                    </a>

                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                        [routerLink]="'/gasprecio/inteligencia-mercado'" (click)="clickPage('Modulo Inteligencia')"
                        *ngIf="(page$ | async) !== 'Mercado' && isMobile">
                        <i class="mdi mdi-google-circles-extended icon-mobile"></i>
                    </a>
                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/monitor'"
                        (click)="clickPage('Monitor');"
                        *ngIf="!isMobile">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-gas-station icon-desktop" [ngClass]="{'active': (page$ | async) === 'Monitor' && !isMobile}"></i>
                        </span>
                        Monitor
                    </epy-tooltip>

                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/general'"
                        (click)="clickPage('Tablero');"
                        *ngIf="!isMobile">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-television-guide icon-desktop" [ngClass]="{'active': (page$ | async) === 'General' && !isMobile}"></i>
                        </span>
                        Tablero Estaciones
                    </epy-tooltip>

                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/inteligencia-mercado'"
                        (click)="clickPage('Modulo Inteligencia');"
                        *ngIf="!isMobile">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-google-circles-extended icon-desktop" [ngClass]="{'active': (page$ | async) === 'Mercado' && !isMobile}"></i>
                        </span>
                        Inteligencia de Mercado o Marca
                    </epy-tooltip>
                </div>
            </a>
        </div>
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto"></ul>
            <ul class="navbar-nav my-lg-0">
                <li class="nav-item dropdown" *ngIf="rol=='Administrador' || rol === 'Dios'"
                    (click)="clickPage('Notificaciones')">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" id="2" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-email"></i>
                        <div class="notify" *ngIf="notifications">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu mailbox dropdown-menu-right animated fadeIn" aria-labelledby="2">
                        <ul>
                            <li>
                                <div class="drop-title">Notificaciones</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    <a *ngFor="let alert of alerts" (click)="goTo(alert.type, alert)"
                                        (mouseover)="alertRead(alert._id, alert.click)">
                                        <div class="user-img">
                                            <i class="fa fa-list-ol" style="font-size: 22px;color:gray"></i>
                                            <span class="profile-status pull-right"
                                                [ngClass]="alert.click ? 'offline' : 'online'"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h6 style="margin-bottom: 0;">{{alert.station}}</h6>
                                            <span class="mail-desc">{{alert.text}}</span>
                                            <span class="time">{{alert.date}} hrs.</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item dropdown" (click)="clickPage('Estaciones')" *ngIf="(page$ | async) === 'Monitor'">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"> {{clienteActual}}</a>
                    <div class="dropdown-menu client-list dropdown-menu-right animated bounceInDown">
                        <input type="text" placeholder="Buscar estación..." (ngModelChange)="filterItem($event)"
                            [(ngModel)]="filterText" class="search-station">
                        <ng-container *ngFor="let client of clientsList">
                            <a class="dropdown-item" (click)="changeClient(client);">
                                {{capitalizeFirstLetter(client.alias)}}</a>
                        </ng-container>
                    </div>
                </li>

                <li class="nav-item dropdown" (click)="clickPage('Estaciones')"
                    *ngIf="(page$ | async) === 'General' || (page$ | async) === 'Mercado'">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"> {{clienteActual}}</a>
                    <div class="dropdown-menu client-list dropdown-menu-right animated bounceInDown">

                        <input type="text" placeholder="Buscar estación..." (ngModelChange)="filterItem($event)"
                            [(ngModel)]="filterText" class="search-station">

                        <ng-container *ngFor="let client of clientsList">
                            <a class="dropdown-item" (click)="changeClientG(client);">
                                {{capitalizeFirstLetter(client.alias)}}</a>
                        </ng-container>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <p style="margin-bottom: 0;" [attr.data-letters]="letters"></p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-text">
                                        <h4>{{name}}</h4>
                                        <p class="text-muted">{{email}}</p>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a (click)="logout();clickPage('Salir')" [routerLink]="'/login'"><i
                                        class="fa fa-power-off"></i> Salir</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>