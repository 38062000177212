import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-weeks',
  templateUrl: './select-weeks.component.html',
  styleUrls: ['./select-weeks.component.scss']
})
export class SelectWeeksComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { value: 8, name: 'Últ. 8 semanas' },
    { value: 12, name: 'Últ. 12 semanas' },
    { value: 26, name: 'Últ. 26 semanas' },
    { value: 52, name: 'Últ. 52 semanas' },
    { value: 104, name: 'Últ. 104 semanas' },
    { value: 156, name: 'Últ. 156 semanas' },
    { value: '2023-01-01T06:00:00Z', name: 'Desde el 2023' },
    { value: '2022-01-01T06:00:00Z', name: 'Desde el 2022' },
    { value: '2021-01-01T06:00:00Z', name: 'Desde el 2021' }
  ];

  public weeksAgo: any = [];
  public ngxDisabled = false;
  weeksAgo$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.weeksAgo$ = this.sharedService.sharedWeeksAgo.subscribe(weeksAgo => {
      this.weeksAgo = weeksAgo;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.weeksAgo$.unsubscribe();
  }

  changeWeeksAgo(event: any) {
    this.weeksAgo = event;
    this.sharedService.nextWeeksAgo = this.weeksAgo;
  }
}
