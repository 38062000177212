import * as moment from 'moment-timezone';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sales-chart',
  templateUrl: './sales-chart.component.html',
  styleUrls: ['./sales-chart.component.scss']
})
export class SalesChartComponent implements OnInit, OnDestroy {

  @ViewChild('editModal') editModal: TemplateRef<any>;
  @Input() section: any = false;
  adjusted;
  changeModal$!: Subscription;
  changeModal;
  forecast;
  productType = 1;
  productType$!: Subscription;
  reqst = 0;
  sales: any;
  stationId$!: Subscription;
  stationId: any;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            callback(value) {
              value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              return value + ' lts.';
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, details: any) => {
          const dataset = details.datasets[tooltipItem.datasetIndex];
          const value: any = tooltipItem.yLabel;
          return dataset.label + ': ' + this.numberWithCommas(value) + ' lts.';
        },
        footer: () => {
          return 'Click aquí para ver el detalle del día';
        }
      }
    },
    elements: {
      line: {
        fill: false
      }
    },
    annotation: {},
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  public lineChartColors: Color[] = [];

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.formatData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getChartSales();
      }
    });
  }

  async ngOnInit() {
    if (this.section) {
      this.getChartSales();
    }
   }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  getChartSales() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null) {
      return;
    }
    this.gasprecioService.getChartSales(this.stationId).subscribe((data: any) => {
      this.sales = data['record'];
      this.adjusted = data['adjusted'];
      this.forecast = data['forecast'];
      this.formatData();
      this.reqst = 2;
    }, err => {
      console.log(err);
    });
  }

  formatData() {

    this.lineChartData = [{
      label: 'Ventas',
      data: this.sales.map((v: any) => v['product_' + this.productType]),
      lineTension: 0
    }];

    this.lineChartLabels = this.sales.map(
      (v: any) => this.gasprecioService.parseDateDM(v['date'])
    );

    if (this.productType === 1) {
      this.lineChartColors = [
        { // gold
          backgroundColor: 'rgba(30, 34, 40, 1)',
          borderColor: '#1e2228',
          pointBackgroundColor: '#1e2228',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(30, 34, 40, 1)',
          pointHoverBorderColor: '#1e2228'
        },
        { // orange
          backgroundColor: 'rgba(212, 175, 55, 1)',
          borderColor: '#d4af37',
          pointBackgroundColor: '#d4af37',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(212, 175, 55, 1)',
          pointHoverBorderColor: '#d4af37'
        }
      ];
    } else if (this.productType === 2) {
      this.lineChartColors = [
        { // blue
          backgroundColor: 'rgba(205, 33, 32, 1)',
          borderColor: '#CD2120',
          pointBackgroundColor: '#CD2120',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(205, 33, 32, 1)',
          pointHoverBorderColor: '#CD2120'
        },
        { // orange
          backgroundColor: 'rgba(212, 175, 55, 1)',
          borderColor: '#d4af37',
          pointBackgroundColor: '#d4af37',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(212, 175, 55, 1)',
          pointHoverBorderColor: '#d4af37'
        }
      ];
    } else if (this.productType === 3) {
      this.lineChartColors = [
        { // black
          backgroundColor: 'rgba(232, 168, 86, 1)',
          borderColor: '#e8a856',
          pointBackgroundColor: '#e8a856',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(232, 168, 86, 1)',
          pointHoverBorderColor: '#e8a856'
        },
        { // orange
          backgroundColor: 'rgba(212, 175, 55, 1)',
          borderColor: '#d4af37',
          pointBackgroundColor: '#d4af37',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(212, 175, 55, 1)',
          pointHoverBorderColor: '#d4af37'
        }
      ];
    } else if (this.productType === 4) {
      this.lineChartColors = [
        { // black
          backgroundColor: 'rgba(0, 161, 86, 1)',
          borderColor: '#00a156',
          pointBackgroundColor: '#00a156',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(0, 161, 86, 1)',
          pointHoverBorderColor: '#00a156'
        },
        { // orange
          backgroundColor: 'rgba(212, 175, 55, 1)',
          borderColor: '#d4af37',
          pointBackgroundColor: '#d4af37',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(212, 175, 55, 1)',
          pointHoverBorderColor: '#d4af37'
        }
      ];
    } else if (this.productType === 6) {
      this.lineChartColors = [
        { // black
          backgroundColor: 'rgba(138, 208, 31, 1)',
          borderColor: '#8AD01F',
          pointBackgroundColor: '#8AD01F',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(138, 208, 31, 1)',
          pointHoverBorderColor: '#8AD01F'
        },
        { // orange
          backgroundColor: 'rgba(212, 175, 55, 1)',
          borderColor: '#d4af37',
          pointBackgroundColor: '#d4af37',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(212, 175, 55, 1)',
          pointHoverBorderColor: '#d4af37'
        }
      ];
    } else if (this.productType === null) {
      this.lineChartColors = [
        { // gold
          backgroundColor: 'rgba(23, 148, 70, 1)',
          borderColor: '#179446',
          pointBackgroundColor: '#179446',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(23, 148, 70, 1)',
          pointHoverBorderColor: '#179446'
        },
        { // blue
          backgroundColor: 'rgba(205, 33, 32, 1)',
          borderColor: '#CD2120',
          pointBackgroundColor: '#CD2120',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(205, 33, 32, 1)',
          pointHoverBorderColor: '#CD2120'
        },
        { // black
          backgroundColor: 'rgba(30, 34, 40, 1)',
          borderColor: '#1e2228',
          pointBackgroundColor: '#1e2228',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(30, 34, 40, 1)',
          pointHoverBorderColor: '#1e2228'
        },
        { // orange
          backgroundColor: 'rgba(212, 175, 55, 1)',
          borderColor: '#d4af37',
          pointBackgroundColor: '#d4af37',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: 'rgba(212, 175, 55, 1)',
          pointHoverBorderColor: '#d4af37'
        }
      ];
    }

    this.lineChartData.push({
      label: 'Venta Ajustada',
      data: this.adjusted.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      lineTension: 0,
      borderDash: [4, 4],
    });

    this.lineChartData.push({
      label: 'Venta Pronosticada',
      data: this.forecast.map((comp: any) => {
        if (comp['product_' + this.productType] === null) {
          return null;
        } else {
          return +comp['product_' + this.productType];
        }
      }),
      lineTension: 0,
    });

  }

  chartClicked(e: any) {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const date = this.sales[clickedElementIndex].date;
        const name = this.sales[clickedElementIndex].name;
        this.changeDataTable(date, name);
        this.openModal();
      }
    }
  }

  changeDataTable(date, name) {
    this.sharedService.nextDate = moment(date).tz('Europe/Madrid').format('YYYY-MM-DD');
    this.sharedService.nextProductType = this.productType;
    this.sharedService.nextStationId = this.stationId;
    this.sharedService.nextNameStation = name;
    setTimeout(() => {
      this.sharedService.nextChangeModal = 'table';
    }, 100);
  }

  openModal() {
    this.modalService.open(this.editModal, { windowClass: 'tableModal' });
  }

  closeModal() {
    this.modalService.dismissAll(this.editModal);
  }
}
