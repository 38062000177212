import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-town',
  templateUrl: './select-town.component.html',
  styleUrls: ['./select-town.component.scss']
})
export class SelectTownComponent implements OnInit, OnDestroy {

  @Input() rdc: boolean;
  public items: any[] = [];
  public townSelected: any = [];
  public entitySelected = '';
  public zoneSelected = '';
  public nationalSelected: any = '';
  public ngxDisabled = true;
  entitySelected$: Subscription;
  townSelected$: Subscription;
  sharedZone$: Subscription;
  nationalSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {

    this.nationalSelected$ = sharedService.sharedNational.subscribe((nationalSelected: string) => {
      if (this.nationalSelected !== nationalSelected) {
        this.nationalSelected = nationalSelected;
        this.ngxDisabled = true;
        this.townSelected = null;
        this.sharedService.nextTown = null;
      }
    });

    this.entitySelected$ = this.sharedService.sharedEntity.subscribe((entitySelected: any) => {
      this.entitySelected = entitySelected;
      if ((this.entitySelected === '' || this.entitySelected === 'Provincia' || this.entitySelected === null)) {
        this.ngxDisabled = true;
      } else if (this.nationalSelected === '1'){
        this.ngxDisabled = false;
      } else {
        this.ngxDisabled = false;
      }
      if (this.nationalSelected !== 0) {
        this.getTownsEntity();
      }
    });

    this.sharedZone$ = sharedService.sharedZoneMarket.subscribe((zoneSelected: any) => {
      this.zoneSelected = zoneSelected;
      if ((this.zoneSelected === '' || this.zoneSelected === 'Zona' || this.zoneSelected === null) && this.nationalSelected !== '1') {
        this.ngxDisabled = true;
      } else if (this.nationalSelected === '1') {
        this.ngxDisabled = false;
      } else {
        this.ngxDisabled = false;
      }
      this.getTownsZone();
    });

  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    if (this.rdc) {
      this.getTownsZone();
    } else {
      this.getTownsEntity();
    }
  }

  ngOnDestroy() {
    this.nationalSelected$.unsubscribe();
    this.entitySelected$.unsubscribe();
    this.sharedZone$.unsubscribe();
  }

  getTownsEntity() {
    if (this.rdc) {
      return;
    }
    this.items = [
      { name: 'Localidad', value: null },
    ];
    if (this.entitySelected === '' || this.entitySelected === null) {
      return;
    }
    this.townSelected = 'Localidad';
    this.gasprecioService.getTownsEntity(this.entitySelected).subscribe((data: any) => {
      const towns: any = data;
      towns.forEach((element: any) => {
        element.name = this.gasprecioService.capitalizeFirstLetter(element.name);
        this.items.push({ name: element.name, value: element.municipio_id });
      });
    });
  }

  getTownsZone() {
    this.items = [
      { name: 'Localidad', value: null },
    ];
    if (this.zoneSelected === '' || this.zoneSelected === null || this.nationalSelected === '1') {
      this.ngxDisabled = true;
      return;
    }
    this.townSelected = 'Localidad';
    this.gasprecioService.getTownsZone(this.zoneSelected).subscribe((data: any) => {
      const towns: any = data;
      towns.forEach((element: any) => {
        element.name = this.gasprecioService.capitalizeFirstLetter(element.name);
        this.items.push({ name: element.name, value: element.municipio_id });
      });
    });
  }

  changeTown(event: any) {
    this.townSelected = event;
    this.sharedService.nextTown = this.townSelected;
    this.items.map((town: any) => {
      if (town.value === this.townSelected) {
        this.sharedService.nextTownText = town.name;
      }
    });
  }
}
