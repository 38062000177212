import { Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
moment.locale('es');

@Component({
  selector: 'app-verify',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./../login/login.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  public token: any;
  public date: any;
  public dateToken: any;
  public success = true;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private authenticationService: AuthService
  ) {

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.dateToken = params['date'];
      if (this.token && this.dateToken) {
        this.authenticationService.verifyAccount(this.token);
        const startdate = moment();
        this.date = moment(startdate);
        this.dateToken = moment(this.dateToken);
        const numb = this.date.diff(this.dateToken, 'minutes');
        if (numb < 30) {
          if (this.token.length > 0) {
            this.authenticationService.verifyAccount(this.token);
          } else {
            this.success = false;
          }
        }
      } else {
        this.success = false;
      }
    });
   }

  ngOnInit() {

  }
}
