import * as fs from 'file-saver';
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Station } from 'src/app/models/Station';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from '../../../../enviroments/enviroments';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NumberFormat } from 'xlsx';

const url = environment.apiUrl;
// const url = environment.developmentUrl;

@Injectable({
  providedIn: 'root'
})
export class GasprecioService {

  private endpoint = url + '/v1/gasprecio';
  private endpoint1 = url + '/v1';
  public currentUser: any;
  public lastUpdate: any;
  public idStation;
  public idGroup;
  public creIdStation;
  public _currentStation: any = {};
  public get currentStation() { return this._currentStation; }
  public currentStationSource = new Subject<any>();
  public currentStation$ = this.currentStationSource.asObservable();
  private competitorsSource = new Subject<Array<any>>();
  private _competitors: Array<Station> = [];
  public competitors$ = this.competitorsSource.asObservable();
  get competitors() { return this._competitors; }
  public date: Observable<any> | undefined;
  public groups: Observable<any> | undefined;
  public products: Observable<any> | undefined;
  public dashboardPrice: Observable<any> | undefined;
  public variables: Observable<any> | undefined;
  public weeks: Observable<any> | undefined;
  public sales: Observable<any> | undefined;
  public maxSales: Observable<any> | undefined;
  public markets: Observable<any> | undefined;
  public zones: Observable<any> | undefined;
  public marginEvolution: Observable<any> | undefined;
  public marginDays: Observable<any> | undefined;
  public saleEarning: Observable<any> | undefined;
  public brands: Observable<any> | undefined;
  public entities: Observable<any> | undefined;
  public difMin: Observable<any> | undefined;
  public lastProduct;
  public lastDays;
  public reasons: Observable<any> | undefined;
  public colors = [
    { r: 212, g: 175, b: 55, a: 1 }, // gold
    { r: 205, g: 33, b: 32, a: 0.8 },  // red
    { r: 40, g: 218, b: 109, a: 0.8 }, // green
    { r: 99, g: 63, b: 156, a: 0.8 },  // purple
    { r: 0, g: 150, b: 210, a: 0.8 },  // blue
    { r: 75, g: 192, b: 192, a: 0.8 }, // blue-green
    { r: 138, g: 75, b: 8, a: 0.8 },   // brown
    { r: 222, g: 76, b: 138, a: 0.8 }, // pink
    { r: 16, g: 44, b: 84, a: 0.8 },   // dark blue
    { r: 132, g: 132, b: 132, a: 0.8 }, // gray
    { r: 220, g: 220, b: 220, a: 0.8 }, // light gray
    { r: 155, g: 89, b: 182, a: 0.8 }, // violet
    { r: 241, g: 204, b: 177, a: 0.8 }, // light orange
    { r: 26, g: 188, b: 156, a: 0.8 },  // turquoise
    { r: 241, g: 196, b: 15, a: 0.8 },  // yellow
    { r: 46, g: 204, b: 113, a: 0.8 },  // green
    { r: 230, g: 126, b: 34, a: 0.8 },  // dark orange
    { r: 204, g: 255, b: 153, a: 0.8 }, // light green
    { r: 255, g: 0, b: 255, a: 0.8 },   // magenta
    { r: 255, g: 0, b: 0, a: 0.8 },     // red
    { r: 190, g: 162, b: 125, a: 0.8 }, // beige
  ];

  public colorsProducts = [
    { r: 30, g: 34, b: 40, a: 1 }, // Gasoleo A
    { r: 205, g: 33, b: 32, a: 1 },  // Gasoleo B
    { r: 232, g: 168, b: 86, a: 1 }, // Gasoleo Prem
    { r: 0, g: 161, b: 86, a: 1 }, // Gasolina 95 E5
    { r: 0, g: 161, b: 86, a: 1 }, // Gasolina 95 E5 Prem
    { r: 138, g: 208, b: 31, a: 1 }, // Gasolina 98 E5
    { r: 0, g: 95, b: 189, a: 1 }, // blue
    { r: 255, g: 143, b: 7, a: 1 } // orange
  ];

  public colorsProductsReports = {
    "gasoleo a": "1E2228",
    "gasoleo b": "CD2120",
    "gasoleo premium": "E8A856",
    "gasolina 95 e5": "00A156",
    "gasolina 98 e5": "8AD01F",
    "gasolina 95 e5 premium": "003A1C"
  }

  public colorsProductsLight = [
    { r: 30, g: 34, b: 40, a: .3 }, // Gasoleo A
    { r: 205, g: 33, b: 32, a: .3 },  // Gasoleo B
    { r: 232, g: 168, b: 86, a: .3 }, // Gasoleo Prem
    { r: 0, g: 161, b: 86, a: .3 }, // Gasolina 95 E5
    { r: 0, g: 161, b: 86, a: .3 }, // Gasolina 95 E5 Prem
    { r: 138, g: 208, b: 31, a: .3 }, // Gasolina 98 E5
    { r: 150, g: 214, b: 255, a: 1 }, // blue
    { r: 255, g: 143, b: 7, a: .3 } // orange
  ];

  constructor(
    private http: HttpClient,
    public authenticationService: AuthService,
    public router: Router
  ) {
    this.idStation = localStorage.getItem('idStation') ? localStorage.getItem('idStation') : this.authenticationService.idStation;
    this.creIdStation = localStorage.getItem('creId') ? localStorage.getItem('creId') : this.authenticationService.creId;
    this.init();
  }

  // Obtener el margen por nivel de los competidores

  async init() {
    await this.getMonitor().then((station: any) => {
      this._currentStation = { ...this.currentStation, ...station['client'] };
      this._currentStation['name'] = this._currentStation['name'] ? this.removeSADECV(this.currentStation['name']) : '';
      this._currentStation['old_name'] = this._currentStation['name'];
      if (this._currentStation['product_1_selling_price']) {
        this._currentStation['product_1_price'] = this._currentStation['product_1_selling_price']
      } 

      if (this._currentStation['product_2_selling_price']) {
        this._currentStation['product_2_price'] = this._currentStation['product_2_selling_price']
      } 

      if (this._currentStation['product_3_selling_price']) {
        this._currentStation['product_3_price'] = this._currentStation['product_3_selling_price']
      }

      if (this._currentStation['product_4_selling_price']) {
        this._currentStation['product_4_price'] = this._currentStation['product_4_selling_price']
      } 


      if (this._currentStation['product_5_selling_price']) {
        this._currentStation['product_5_price'] = this._currentStation['product_5_selling_price']
      }

      if (this._currentStation['product_6_selling_price']) {
        this._currentStation['product_6_price'] = this._currentStation['product_6_selling_price']
      } 
     
      this._currentStation['brand'] = this._currentStation['brand'] ? this.removeSADECV(this.currentStation['brand']) : '';
      this.currentStationSource.next(this.currentStation);
      this._competitors = station['competitors'];
      this._competitors.forEach((element: any) => {
        element['name'] = element['name'] ? this.removeSADECV(element['name']) : '';
        element['old_name'] = element['name']; 
        element['product_1_price'] = element['product_1_selling_price'] ? element['product_1_selling_price'] : null;
        element['product_2_price'] = element['product_2_selling_price'] ? element['product_2_selling_price'] : null;
        element['product_3_price'] = element['product_3_selling_price'] ? element['product_3_selling_price'] : null;
        element['product_4_price'] = element['product_4_selling_price'] ? element['product_4_selling_price'] : null;
        element['product_5_price'] = element['product_5_selling_price'] ? element['product_5_selling_price'] : null;
        element['product_6_price'] = element['product_6_selling_price'] ? element['product_6_selling_price'] : null;
        element['brand'] = element['brand'] ? this.removeSADECV(element['brand']) : '';
      });
      this.competitorsSource.next(this.competitors);
    });
  }

  getProducts() {
    if (this.products) {
      return this.products;
    } else {
      this.products = this.http.get(`${this.endpoint}/general/products?station=${this.idStation}`).pipe(
        map((x: any) => x?.data?.products),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.products;
    }
  }

  getProductsById(idStation) {
    return this.http.get(`${this.endpoint}/general/products?station=${idStation}`).pipe(
      map((x: any) => x?.data?.products),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getProductsOptions() {
    return this.http.get(`${this.endpoint}/general/products`).pipe(
      map((x: any) => x?.data?.products),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  saveProducts(data: any) {
    return this.http.post<any>(`${this.endpoint}/general/products/selection`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener competidores de CorpoGas

  getCompetitorsCG(idStation: string) {
    if (!idStation || typeof idStation !== 'string' || idStation.trim().length === 0) {
      throw new Error('idStation debe ser una cadena no vacía');
    }

    return this.http.get(`${this.endpoint1}/crm/stations/${idStation}/competitors`, { headers: { skip: 'skip' } }).pipe(
      map((x: any) => x?.competitors),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener competidores

  getCompetitors(idStation: string): Observable<any> {
    if (!idStation || typeof idStation !== 'string' || idStation.trim().length === 0) {
      throw new Error('idStation debe ser una cadena no vacía');
    }

    return this.http.get(`${this.endpoint1}/crm/stations/${idStation}/competitors`).pipe(
      map((x: any) => x?.competitors),
      shareReplay(1),
      catchError(this.processError)
    );
  }


  // Obtener competidores para mostrar en el mapa

  getCompetitorsMap(idStation: string) {
    return this.http.get(`${this.endpoint}/competence_price/monitor/last_report?station=${idStation}`).pipe(
      map((x: any) => x?.competitors),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener grupos gasolineros (clientes)

  getGroups(): Observable<any> {
    return this.groups ??= this.http.get(`${this.endpoint1}/crm/groups`).pipe(
      map((x: any) => x?.data?.groups),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener las ciudades de rendichicas

  getZones(idGroup: string) {
    if (this.zones && idGroup === this.idGroup) {
      return this.zones;
    } else {
      this.idGroup = idGroup;
      this.zones = this.http.get(`${this.endpoint1}/crm/groups/${idGroup}/zones`).pipe(
        map((x: any) => x?.data?.zones),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.zones;
    }
  }

  // Obtener los mercados de rendichicas

  getMarkets(idGroup: string) {
    if (this.markets) {
      return this.markets;
    } else {
      this.markets = this.http.get(`${this.endpoint1}/crm/groups/${idGroup}/zones/markets`).pipe(
        map((x: any) => x?.data?.zones),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.markets;
    }
  }

  // Obtener la lista de estaciones de un grupo gasolinero

  getStationsGroup(idGroup: string) {
    return this.http.get(`${this.endpoint1}/crm/groups/${idGroup}/stations`).pipe(
      map((x: any) => x?.data?.stations),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener la última actualización de información

  getLastUpdate() {
    if (this.date) {
      return this.date;
    } else {
      this.date = this.http.get<string>(`${this.endpoint}/last_update`).pipe(
        map((x: any) => x?.last_update),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.date;
    }
  }

  // Obtener lista de notificaciones por cada grupo

  getAlerts() {
    return this.http.get(`${this.endpoint}/alerts`).pipe(
      map((x: any) => x?.alerts),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Envia una bandera de que le dieron click o leyeron una notificación

  alertRead(idAlert: string) {

    const data = { 'click': true };

    return this.http.put(`${this.endpoint}/alerts/${idAlert}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Seccion monitor

  // Guardar precios modificados

  setSellingPricesManual(data) {
    return this.http.post(`${this.endpoint}/selling_price/assignment/manual/bulk`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Nuevo Precio Actual y margen actual monitor y mapa

  getMonitor() {
    return this.http.get(`${this.endpoint}/selling_price/monitor?station=${this.idStation}`).toPromise();
  }

  // Obtener el Margen Actual de una estación

  getMargin() {
    return this.http.get<Array<object>>(`${this.endpoint}/monitor/margin?station=${this.idStation}`).pipe(
      map((x: any) => x?.margin),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener el precio minimo por zona de todas las estaciones

  getPricesZoneT(idStation) {
    return this.http.get<Array<object>>(`${this.endpoint}/selling_price/report_zone?station=${idStation}`).pipe(
      map((x: any) => x?.data?.all_products_formated),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener el precio minimo por zona de una estación

  getPricesZone() {
    return this.http.get<Array<object>>(`${this.endpoint}/selling_price/report_zone?station=${this.idStation}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener lista de competidores para poder seleccionar los que influyen a tu precio

  getListCompetitors(distance: number) {
    return this.http.get(`${this.endpoint}/stations/${this.idStation}/competitors/list?distance=${distance}`).pipe(
      map((x: any) => x?.data?.list),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Guardar la lista de competidores seleccionados en el monitor

  saveListCompetitors(data: any) {

    return this.http.post(`${this.endpoint}/stations/${this.idStation}/competitors/selection`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener lista de competidores de correlaciones para poder seleccionar los que influyen a tu precio

  getListCorrelationCompetitors(distance: number): Observable<any> {
    return this.http.get(`${this.endpoint}/stations/${this.idStation}/correlation-competitors/list?distance=${distance}`).pipe(
      map((x: any) => x?.data?.list),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Guardar la lista de competidores de correlaciones seleccionados en el monitor

  saveListCorrelationCompetitors(data: any) {
    return this.http.post(`${this.endpoint}/stations/${this.idStation}/correlation-competitors/selection`, data)
      .pipe(
        map((x: any) => { return x; }),
        shareReplay(1),
        catchError(this.processError)
      );
  }

  // Guardar el horario en que quieres recibir las notificaciones de cambios de precio

  scheduleNotifications(from: any, until: any) {

    const data = { 'from': from, 'until': until };

    return this.http.put(`${this.endpoint}/competence_price/monitor/schedule?station=${this.idStation}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Obtener competidores a los cuales puedes editar su precio manual

  getEditStations() {
    return this.http.get(`${this.endpoint}/stations/${this.idStation}/enable_edit_selling_prices`).pipe(
      map((x: any) => x?.data?.stations),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Guardar los nuevos precios por producto de una estación

  setNewPrice(creId: any, product: any, price: any, date: any) {

    const data = {
      'station_id': creId,
      'product_id': product,
      'price': price,
      'application_date': date
    };

    return this.http.post<any>(`${this.endpoint}/selling_price/assignment/manual`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Activar/desactivar notificaciones de una estación

  activeNotifications(creId: any, status: any, type: any) {

    const data = { 'competitor': creId, 'active': status, 'type': type };

    return this.http.put(`${this.endpoint}/selling_price/competitor_notifications?station=${this.idStation}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Guardar el alias de una estación

  saveNewAlias(data) {
    return this.http.post<any>(`${this.endpoint}/stations/station/alias`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Guardar la marca de una estación

  saveNewBrand(data) {

    return this.http.post<any>(`${this.endpoint}/stations/station/brands`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener info de la Bitacora de cambios y generar excel

  getChangesBinnacle(startDate?: any, endDate?: any) {
    if (startDate && endDate) {
      return this.http.get(`${this.endpoint}/selling_price/competitor_changes?station=${this.idStation}&startdate=${startDate}&lastdate=${endDate}`).pipe(
        map((x: any) => x?.data?.changes),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/selling_price/competitor_changes?station=${this.idStation}`).pipe(
        map((x: any) => x?.data?.changes),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Seccion competencia

  // Obtener info de la Evolución de precios

  getPriceHistory(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/selling_price/evolution?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.data?.evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/selling_price/evolution?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.data?.evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Excel de Evolución de precios

  getReportPriceHistory(startDate?: any, endDate?: any) {
    return this.http.get(`${this.endpoint}/selling_price/evolution?station=${this.idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.evolution),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info Top 10 Competidores

  getTopTen() {
    return this.http.get(`${this.endpoint}/competence_price/competence/top10?station=${this.idStation}`).pipe(
      map((x: any) => x?.top10_price),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info de la Grafica Top 10 Competidores

  getTopTenEvolution(daysAgo: any, variable: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/competence_price/competence/top10_evolution?station=${this.idStation}&variable=${variable}&month=${month}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/competence_price/competence/top10_evolution?station=${this.idStation}&variable=${variable}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener info Localidad

  getTown() {
    return this.http.get(`${this.endpoint}/competence_price/competence/municipio?station=${this.idStation}`).pipe(
      map((x: any) => x?.municipio_prices),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info Grafica Localidad

  getTownEvolution(daysAgo: any, variable: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/competence_price/competence/municipio_evolution?station=${this.idStation}&variable=${variable}&month=${month}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/competence_price/competence/municipio_evolution?station=${this.idStation}&variable=${variable}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener lista de las Cisternas compradas

  getPurchases() {
    return this.http.get(`${this.endpoint}/purchases?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.purchases),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener ultima Cisterna comprada por cada producto

  getLastPurchases() {
    return this.http.get(`${this.endpoint}/purchases/last_purchases?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.purchases),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Registrar Cisternas compradas por cada producto

  registerPurchase(date: any, product: any, liters: any, cost: any, suplyPrice: any, issuing: any) {

    const data = {
      'data': {
        'station': this.idStation,
        'date': date,
        'product_id': product,
        'liters': liters,
        'cost': cost,
        'supply_price': suplyPrice,
        'issuing': issuing
      }
    };

    return this.http.post<any>(`${this.endpoint}/purchases/purchase`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Registrar Cisternas compradas de PETRUM por cada producto

  registerPurchasePetrum(date: any, product: any, liters: any, cost: any, suplyPrice: any, adjust: any, purchaseSupplyAdjust: any, issuing: any, folio: any) {

    let data;

    data = {
      'data': {
        'issuing': issuing,
        'folio': folio,
        'station': this.idStation,
        'date': date,
        'product_id': product,
        'liters': liters,
        'cost': cost,
        'supply_price': suplyPrice,
        'adjust': adjust,
        'supply_price_adjust': purchaseSupplyAdjust
      }
    };

    return this.http.post<any>(`${this.endpoint}/purchases/purchase`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Actualizar Cisternas de una estación

  updatePurchases(idPurchases: any, data: any) {
    return this.http.put<any>(`${this.endpoint}/purchases/${idPurchases}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Borrar compras de una estación

  deletePurchase(idPurchase: any) {
    return this.http.delete<any>(`${this.endpoint}/purchases/${idPurchase}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener info del Margen Actual

  getCurrentMargin() {
    return this.http.get(`${this.endpoint}/buying_price/margin/actual_margin?station=${this.idStation}`).pipe(
      map((x: any) => x?.actual_margin),
      shareReplay(1),
      catchError(this.processError)

    );
  }

  // Obtener info del Margen Final y %

  getFinalMargin(daysAgo: any, month?: any) {
    if (month) {
      this.marginEvolution = this.http.get(`${this.endpoint}/buying_price/margin/margin_evolution?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.margin_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.marginEvolution;
    } else {
      this.marginEvolution = this.http.get(`${this.endpoint}/buying_price/margin/margin_evolution?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.margin_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.marginEvolution;
    }
  }

  // Obtener info del Margen Final y %

  getFinalEvol(daysAgo: number) {
    if (this.marginDays) {
      return this.marginDays;
    } else {
      this.marginDays = this.http.get(`${this.endpoint}/buying_price/margin/margin_evolution?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.margin_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.marginDays;
    }
  }

  // Obtener info del Margen Periodo

  getMarginPeriod() {
    return this.http.get(`${this.endpoint}/buying_price/margin/margin_period?station=${this.idStation}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Precio Compra vs Precio Venta

  getVersus(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/supply_price/record/versus_selling_price?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/supply_price/record/versus_selling_price?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Precio Recomendado

  // Registrar una venta

  registerSale(data: any) {

    return this.http.post<any>(`${this.endpoint}/optimum_price/recommended_price/sale?station=${this.idStation}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Registrar las ventas mediante la plantilla excel

  uploadSalesRecord(record: any) {

    const formData = new FormData();
    formData.append('sales', record);

    return this.http.post<any>(`${this.endpoint}/optimum_price/recommended_price/sales?station=${this.idStation}`, formData)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Sobrescribir las ventas mediante la plantilla excel

  overwriteSalesRecord(record: any) {

    const formData = new FormData();
    formData.append('sales', record);

    return this.http.put<any>(`${this.endpoint}/optimum_price/recommended_price/sales?station=${this.idStation}`, formData)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener las ventas de una estación

  getSales(startDate: any, endDate: any) {
    return this.http.get(`${this.endpoint}/optimum_price/recommended_price/sales?station=${this.idStation}&startdate=${startDate}&lastdate=${endDate}`).pipe(
      map((x: any) => x?.sales),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Actualizar venta

  updateSale(idDiscount: any, data: any) {

    return this.http.put<any>(`${this.endpoint}/optimum_price/recommended_price/sale/${idDiscount}?station=${this.idStation}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Borrar venta

  deleteSale(idDiscount: any) {
    return this.http.delete<any>(`${this.endpoint}/optimum_price/recommended_price/sale/${idDiscount}?station=${this.idStation}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Precio Ponderado de la Competencia

  getPpc(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/correlations_and_ppc?station=${this.idStation}&product_id=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Precio Ponderado de la Competencia gráfica

  getPpcEvolution(product: any, daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/optimum_price/record_ppc?station=${this.idStation}&product_id=${product}&month=${month}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/optimum_price/record_ppc?station=${this.idStation}&product_id=${product}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Indice de Precio

  getQuotient(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/current_price_index?station=${this.idStation}&product_id=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Curva gráfica

  getDemandCurveEvolution(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/demand_curve_graph?station=${this.idStation}&product_id=${product}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Ganancias gráfica

  getEarningCurveEvolution(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning_curve_graph?station=${this.idStation}&product_id=${product}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte indice de precios

  getReportIndex(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/index?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte indice de precios

  getReportIndexByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/index?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte indice de precios

  getPricesChanges(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price/changes?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.data_formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Seccion General (Tablero)

  // Actualizar preferencias variables precio
  setVariables(type: string, data: any) {
    return this.http.put(`${this.endpoint}/control_panel/custom/variables/${type}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener preferencias variables precio
  getVariables(type: string) {
    return this.http.get(`${this.endpoint}/control_panel/custom/variables/${type}`).pipe(
      map((x: any) => x?.data?.vars),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardSaleZone(group: any, zone?: string) {
    if (zone) {
      return this.http.get(`${this.endpoint}/control_panel/sale?group=${group}&zone=${zone}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/sale?group=${group}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardEarningZone(group: any, zone?: any) {
    if (zone) {
      return this.http.get(`${this.endpoint}/control_panel/earning?group=${group}&zone=${zone}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/earning?group=${group}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardTrackingZone(product: any, period: any, complete: any, calculation: any, group: any, zone?: string) {
    if (complete) {
      complete = 1;
    } else {
      complete = 0;
    }
    if (zone) {
      return this.http.get(`${this.endpoint}/control_panel/sales/liters_avg?group=${group}&product_id=${product}&type=${period}&complete_period=${complete}&calculation=${calculation}&zone_id=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/sales/liters_avg?group=${group}&product_id=${product}&type=${period}&complete_period=${complete}&calculation=${calculation}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardAlerts(product: any, complement: any, period: any, group?: any) {
    if (group) {
      return this.http.get(`${this.endpoint}/sale_alerts?group=${group}&product_id=${product}${complement}${period}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/sale_alerts?product_id=${product}${complement}${period}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }


  getDashboardAlertsZone(product: any, complement: any, period: any, group: any, zone?: string) {
    if (zone) {
      return this.http.get(`${this.endpoint}/sale_alerts?group=${group}&product_id=${product}${complement}${period}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/sale_alerts?group=${group}&product_id=${product}${complement}${period}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardBinaccleAlerts(complement: any, daysAgo: any, period: any, group?: any) {
    if (group) {
      return this.http.get(`${this.endpoint}/sale_alerts/record?group=${group}&${complement}${daysAgo}${period}`).pipe(
        map((x: any) => x?.data?.alerts),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/sale_alerts/record?${complement}${daysAgo}${period}`).pipe(
        map((x: any) => x?.data?.alerts),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardOptimumPrice(product: any, change: any, group: any, data: any, zone?: string) {
    if (zone) {
      return this.http.post(`${this.endpoint}/control_panel/optimum_price?group=${group}&product_id=${product}&change=${change}&zone=${zone}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    } else {
      return this.http.post(`${this.endpoint}/control_panel/optimum_price?group=${group}&product_id=${product}&change=${change}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    }
  }

  getDashboardOptimumPriceSimulation(product: any, change: any, station: any, zone?: any) {
    return this.http.get(`${this.endpoint}/control_panel/optimum_price/simulator?station=${station}&product_id=${product}&change=${change}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.changes),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardIndexPrice(product: any, variable: any, group: any, period: any, zone?: string) {
    if (zone) {
      if (period) {
        return this.http.get(`${this.endpoint}/dashboard/index_price?group=${group}&product_id=${product}&variable=${variable}&period=${period}&zone=${zone}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/index_price?group=${group}&product_id=${product}&variable=${variable}&zone=${zone}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (period) {
        return this.http.get(`${this.endpoint}/dashboard/index_price?group=${group}&product_id=${product}&variable=${variable}&period=${period}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/index_price?group=${group}&product_id=${product}&variable=${variable}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getBinaccleAlerts(complement: any, daysAgo: any, period: any) {
    return this.http.get(`${this.endpoint}/sale_alerts/record?station=${this.idStation}&${complement}${daysAgo}${period}`).pipe(
      map((x: any) => x?.data?.alerts),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getReasons(add?: any) {
    if (this.reasons && !add) {
      return this.reasons;
    } else {
      this.reasons = this.http.get(`${this.endpoint}/sale_alerts/causes`).pipe(
        map((x: any) => x?.data?.causes),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.reasons;
    }
  }

  setReason(data: any) {
    return this.http.put(`${this.endpoint}/sale_alerts/causes`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  setComment(data: any, idAlert: any) {
    return this.http.put(`${this.endpoint}/sale_alerts/${idAlert}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  deleteComment(idAlert: any) {
    return this.http.delete(`${this.endpoint}/sale_alerts/${idAlert}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getTableAlertVariables(date: any, idStation: any, product: any) {
    return this.http.get(`${this.endpoint}/sale_alerts/variables?date=${date}&station=${idStation}&product_id=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSales(idStation: any) {
    return this.http.get(`${this.endpoint}/sales/record?station=${idStation}&forecast=1&adjusted=1&quotient=0&n=30&scatter=0`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesSeasonally(idStation: any) {
    return this.http.get(`${this.endpoint}/sales/seasonally_adjusted?station=${idStation}&n=30`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesOptimum(idStation: any, days: number, scatter?: number) {
    if (this.sales) {
      return this.sales;
    } else {
      return this.http.get(`${this.endpoint}/sales/record?station=${idStation}&forecast=0&adjusted=0&quotient=1&n=${days}&scatter=${scatter}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getChartTraffic(idStation: any) {
    return this.http.get(`${this.endpoint}/traffic/record?station=${idStation}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartQuotient(idStation: any, days: number) {
    return this.http.get(`${this.endpoint}/selling_price/quotient/record?station=${idStation}&n=${days}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSellingPrice(idStation: any, days: number) {
    return this.http.get(`${this.endpoint}/selling_price/record/self?station=${idStation}&n=${days}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getEvolPriceHistory(idStation: any) {
    return this.http.get(`${this.endpoint}/selling_price/evolution?station=${idStation}&days_ago=30`).pipe(
      map((x: any) => x?.data?.evolution),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardMarginZone(group: string, zone?: any) {
    if (zone) {
      return this.http.get(`${this.endpoint}/control_panel/margin?group=${group}&zone=${zone}`).pipe(
        map((x: any) => x?.data_format),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/margin?group=${group}`).pipe(
        map((x: any) => x?.data_format),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardPriceZone(group: string, zone?: any) {
    if (zone) {
      return this.dashboardPrice = this.http.get(`${this.endpoint}/dashboard/resume?group=${group}&zone=${zone}`).pipe(
        map((x: any) => x?.data_format),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.dashboardPrice = this.http.get(`${this.endpoint}/dashboard/resume?group=${group}`).pipe(
        map((x: any) => x?.data_format),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardMapZone(group: any, zone?: string) {
    if (zone) {
      return this.http.get(`${this.endpoint}/control_panel/map?group=${group}&zone=${zone}`).pipe(
        map((x: any) => x?.data?.station_info),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/map?group=${group}`).pipe(
        map((x: any) => x?.data?.station_info),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardComparative(group: any, variable: any, product: any, period: any, typeValue: any, n?: any) {
    return this.dashboardPrice = this.http.get(`${this.endpoint}/dashboard/comparative_zones?group=${group}&variable=${variable}&product_id=${product}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Variables vista mercado

  setMarketVariables(data) {
    return this.http.put(`${this.endpoint}/control_panel/custom/variables/market`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Variables vista mercado

  getMarketVariables() {
    return this.http.get(`${this.endpoint}/control_panel/custom/variables/market`).pipe(
      map((x: any) => x?.data?.categories),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado

  getMarketGeneral(product: any, group: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/general?product=${product}&group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.general),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado ubicacion

  getMarketLocation(group: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/location?group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.location),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado marca

  getMarketBrand(group: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/brand?group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.brand),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado precio

  getMarketSellingPrice(product: any, group: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/selling_price?product=${product}&group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.selling_price),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista Dashboard

  setPriceExcel(data: any) {

    return this.http.post(`${this.endpoint}/selling_price/assignment/final_prices`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  setAdjust(data: any) {

    return this.http.put(`${this.endpoint}/purchases/adjust/upsert`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Vista Dashboard

  // Panel Métricas

  getZonesDashboard(idGroup: any) {
    return this.http.get(`${this.endpoint}/dashboard/zones?group_id=${idGroup}`).pipe(
      map((x: any) => x?.data?.zones),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardSellingPricePurchase(idGroup: any, zone: any, period: any, typeValue: any, n?: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardSellingPricePurchaseStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardVolumenPurchaseMargin(idGroup: any, zone: any, period: any, typeValue: any, n?: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardVolumenPurchaseMarginStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardTarPricePurchasePrice(idGroup: any, zone: any, period: any, typeValue: any, n?: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/brent_price_purchase_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/brent_price_purchase_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardTarPricePurchasePriceStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/brent_price_purchase_price?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardSellingPricePpc(idGroup: any, zone: any, period: any, typeValue: any, n?: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardSellingPricePpcStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardQuotientSellingPrice(idGroup: any, zone: any, period: any, typeValue: any, n: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        shareReplay(1)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        shareReplay(1)
      );
    }
  }

  getDashboardEarningStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/earning?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardEarning(idGroup: any, zone: any, period: any, typeValue: any, n: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/earning?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/earning?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardQuotientSellingPriceStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardPipesDetails(idGroup: string, product: string, startDate: any, endDate: any, zone?: string) {
    if (zone) {
      return this.http.get(`${this.endpoint}/control_panel/purchases_details?group=${idGroup}&${product}start_date=${startDate}&end_date=${endDate}&zone=${zone}`).pipe(
        map((x: any) => x?.data?.purchases),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/purchases_details?group=${idGroup}&${product}start_date=${startDate}&end_date=${endDate}`).pipe(
        map((x: any) => x?.data?.purchases),
        shareReplay(1),
        catchError(this.processError)
      );
    }
    
  }

  getDashboardVolumeQuotient(idGroup: any, zone: any, period: any, typeValue: any, n?: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/volume_quotient?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/volume_quotient?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardVolumeQuotientStation(period: any, typeValue: any, n?: any) {
    return this.http.get(`${this.endpoint}/dashboard/volume_quotient?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMaxSales() {
    if (this.maxSales) {
      return this.maxSales;
    } else {
      this.maxSales = this.http.get(`${this.endpoint}/sales/period/max?station=${this.idStation}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.maxSales;
    }
  }

  getSalesQuartilSplitZones(product: number, daysAgo: any, subsubdivision: number) {
    return this.http.get(`${this.endpoint}/optimum_price/has_subdivisions?station=${this.idStation}&product=${product}&days=${daysAgo}`).pipe(
      map((x: any) => x?.data?.has_subdivisions),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesQuartil(product: number, variable: String) {
    return this.http.get(`${this.endpoint}/sales/markets/graph?station=${this.idStation}&product_id=${product}&variable=${variable}`).pipe(
      map((x: any) => x?.data?.markets),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesQuartilDays(product: number, variable: String, days: number) {
    return this.http.get(`${this.endpoint}/sales/markets/graph?station=${this.idStation}&product_id=${product}&variable=${variable}&days=${days}`).pipe(
      map((x: any) => x?.data?.markets),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTableSalesQuartil(product: number, variable: String) {
    return this.http.get(`${this.endpoint}/sales/markets?station=${this.idStation}&product_id=${product}&variable=${variable}`).pipe(
      map((x: any) => x?.data?.markets),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTableSalesQuartilDays(product: number, variable: String, days: number) {
    return this.http.get(`${this.endpoint}/sales/markets?station=${this.idStation}&product_id=${product}&variable=${variable}&days=${days}`).pipe(
      map((x: any) => x?.data?.markets),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesQuartilComparative(product: number) {
    return this.http.get(`${this.endpoint}/optimum_price/markets/graph/180_30?station=${this.idStation}&product_id=${product}`).pipe(
      map((x: any) => x?.data?.graphs),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTableSalesQuartilComparative(product: number) {
    return this.http.get(`${this.endpoint}/optimum_price/periods/comparative/180_30?station=${this.idStation}&product_id=${product}`).pipe(
      map((x: any) => x?.data?.periods),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTypePxConfig() {
    return this.http.get(`${this.endpoint}/optimum_price/strategy?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.strategy),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  saveTypePxConfig(typePx, idStation?: string) {
    const station = idStation ? idStation : this.idStation;
    const data = {
      'data': {
        'strategy': typePx
      }
    };

    return this.http.put(`${this.endpoint}/optimum_price/strategy?station=${station}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getDiffPxMax(product: number, variable: string, daysAgo: any) {
    return this.http.get(`${this.endpoint}/optimum_price/diff_pmin?station=${this.idStation}&product_id=${product}&variable=${variable}&type=max&days=${daysAgo}`).pipe(
      map((x: any) => x?.data?.levels),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMaxMarkets(product: number, max: number, variable: string, daysAgo: any) {
    return this.http.get(`${this.endpoint}/optimum_price/markets/min?station=${this.idStation}&product_id=${product}&min_and_no_min=${max}&variable=${variable}&type=max&days=${daysAgo}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDiffPxMinDays(product: number, days: number) {
    if (this.difMin && this.lastProduct === product && this.lastDays === days) {
      return this.difMin;
    } else {
      this.difMin = this.http.get(`${this.endpoint}/optimum_price/diff_pmin?station=${this.idStation}&product_id=${product}&days=${days}`).pipe(
        map((x: any) => x?.data?.levels),
        shareReplay(1),
        catchError(this.processError)
      );
      this.lastProduct = product;
      this.lastDays = days;
      return this.difMin;
    }
  }

  getMinMarkets(product: number, min: number, days: number) {
    return this.http.get(`${this.endpoint}/optimum_price/markets/min?station=${this.idStation}&product_id=${product}&min_and_no_min=${min}&type=min&days=${days}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getLevelsActualScatter(product: number) {
    return this.http.get(`${this.endpoint}/optimum_price/sale_quotient/scatter_plot?station=${this.idStation}&product_id=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Sección Reglas

  // Obtener Reglas

  getListPriceRules(idStation: any, product: number) {
    return this.http.get(`${this.endpoint}/price_rules?station=${idStation}&product_id=${product}`).pipe(
      map((x: any) => x?.rules),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Seleccionar Regla

  registerRule(rule: any, product: any, idStation: any, idRule: any, competitors: any, value: any, unit: any, operator: any, idActiveRule?: any) {

    let data;

    if (rule === 1) {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'hwmCompetitors': 'all',
              'competitors': [],
              'operations': [],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 2) {
      if (operator === 'equal') {
        operator = 'add';
      }

      if (operator !== 'without') {
        data = {
          'product_id': product,
          'station': idStation,
          'rule': idRule,
          'composition': {
            'type': 'simple',
            'rules': [
              {
                'rule': idRule,
                'competitors': competitors[0],
                'operations': [
                  { 'type': operator, 'value': value, 'unit': unit, 'operator': 'arithmetic' }
                ],
                'priority': 1
              }
            ]
          },
          'addRule': 'false'
        };
      } else {
        data = {
          'product_id': product,
          'station': idStation,
          'rule': idRule,
          'composition': {
            'type': 'simple',
            'rules': [
              {
                'rule': idRule,
                'competitors': competitors[0],
                'operations': [],
                'priority': 1
              }
            ]
          },
          'addRule': 'false'
        };
      }
    } else if (rule === 3 || rule === 4) {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 5 || rule === 6) {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'operations': [
                { 'type': 'subtract', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 7) {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 8) {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'major_equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 9) {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'minor_equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 10) {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'operations': [
                { 'type': operator, 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 11 && product === 'premium') {
      data = {
        'product_id': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'add', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    }

    if (idActiveRule) {
      return this.http.put(`${this.endpoint}/price_rules/station_rules/${idActiveRule}`, data = { data })
        .pipe(map(station => {
          return station;
        })
        );
    } else {
      return this.http.post<any>(`${this.endpoint}/price_rules/station_rule`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    }

  }

  // Registrar una nueva regla

  registerNewRuleC(data: any) {
    return this.http.post<any>(`${this.endpoint}/price_rules/station_rule`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener regla por producto

  getRuleActive(idStation: any, product: any) {
    return this.http.get(`${this.endpoint}/price_rules/station_rules?station=${idStation}&product_id=${product}&status[]=active`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Desactivar regla

  statusRule(idRule: any, status: any) {
    const data = {
      'status': status
    };

    return this.http.put(`${this.endpoint}/price_rules/station_rules/${idRule}/status`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Actualizar regla

  updateRuleC(idRule: any, data: any) {
    return this.http.put(`${this.endpoint}/price_rules/station_rules/${idRule}`, data = { data })
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Obtener histórico de reglas

  getPriceRuleCurrent(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/price_rules/rule_current?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/price_rules/rule_current?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Subir compras

  uploadPurchases(data: any) {

    return this.http.post(`${this.endpoint}/purchases`, data)
      .pipe(map(purchases => {
        return purchases;
      })
      );
  }

  // Subir sales

  uploadSales(data: any) {

    return this.http.post(`${this.endpoint}/sales?type_register=excel`, data)
      .pipe(map(purchases => {
        return purchases;
      })
      );
  }

  // Subir inventario

  uploadStock(data: any) {

    return this.http.post(`${this.endpoint}/stock?type_register=excel`, data)
      .pipe(map(purchases => {
        return purchases;
      })
      );
  }

  // Obtener stock

  getStock() {
    return this.http.get(`${this.endpoint}/stock?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.stock),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener ult stock

  getLastStock() {
    return this.http.get(`${this.endpoint}/stock/last_stock?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.stock),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Actualizar stock

  registerStock(data) {

    return this.http.post(`${this.endpoint}/stock/stock_register`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  registerProductStock(data) {
    return this.http.put(`${this.endpoint}/stock/`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Actualizar stock

  updateStock(idStock: any, data: any) {
    return this.http.put(`${this.endpoint}/stock/${idStock}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Borrar stock

  deleteStock(idStock: any) {
    return this.http.delete<any>(`${this.endpoint}/stock/${idStock}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener evolución stock

  getEvolutionStock(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/supply_price/record/versus_stock/?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/supply_price/record/versus_stock/?station=${this.idStation}&last_days=${daysAgo}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getSuplyPrice(date: any) {
    return this.http.get(`${this.endpoint}/supply_price?station=${this.idStation}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de venta

  getReportSellingPrice(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de venta por Estación

  getReportSellingPriceByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Resumen de Compras

  getPurchasesResumeReport(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchases-resume?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  } 

  getDashboardCrossReport(idGroup: string, product: any, zone?: any) {
    if (zone) {
      return this.http.get(`${this.endpoint}/reports/cross/groups/1?product_id=${product}&group=${idGroup}&zone=${zone}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/reports/cross/groups/1?product_id=${product}&group=${idGroup}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte Margen

  getReportMargin(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen por estación

  getReportMarginByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Pct

  getReportMarginPct(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin_pct?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Pct por estación

  getReportMarginPctByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin_pct?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Cisternas Compradas

  getReportPipes(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/groups/${idGroup}/purchases?start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Detalle de Cisternas

  getReportCisternsDetails(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchases-detail?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Detalle de Cisternas

  getReportCisternsDetailsByStation(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchases-detail?station=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de Compra

  getReportBuy(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/supply_price?group_id=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1), 
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de Compra

  getReportBuyByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/supply_price?start_date=${startDate}&end_date=${endDate}&station=${idStation}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Sección Inteligencia de Mercado

  // Obtener listado de marcas

  getEntities() {
    if (this.entities) {
      return this.entities;
    } else {
      this.entities = this.http.get(`${this.endpoint}/general/entidades`).pipe(
        map((x: any) => x?.data?.entidades),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.entities;
    }
  }

  getTownsEntity(entity: string) {
    return this.http.get(`${this.endpoint}/general/municipios?entidad_id=${entity}`).pipe(
      map((x: any) => x?.data?.entidades),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTownsZone(zone: string) {
    return this.http.get(`${this.endpoint}/general/municipios?zone_id=${zone}`).pipe(
      map((x: any) => x?.data?.entidades),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getRegions(group: string) {
    return this.http.get(`${this.endpoint}/general/regions?group=${group}`).pipe(
      map((x: any) => x?.data?.regions),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getZonesIM(group: string, entity?: string) {
    if (entity) {
      return this.http.get(`${this.endpoint}/general/zones?group=${group}&entidad_id=${entity}`).pipe(
        map((x: any) => x?.data?.zones),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/general/zones?group=${group}`).pipe(
        map((x: any) => x?.data?.zones),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getBrands(group: string) {
    if (this.brands) {
      return this.brands;
    } else {
      this.brands = this.http.get(`${this.endpoint}/market_intelligence/brands?group=${group}`).pipe(
        map((x: any) => x?.data?.brands?.other),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.brands;
    }
  }

  getMarketIntelligence(markeType: string, national: any, entityId: string, townId: string, zoneId: string, brandC1: string, brandC2: string, brandC3: string, brandC4: string, brandC5: string, product: string, variable: string, group: string, own_stations?: any) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/station_list?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&comps=5&brands[]=${brandC1}&brands[]=${brandC2}&brands[]=${brandC3}&brands[]=${brandC4}&brands[]=${brandC5}&product_id=${product}&variable_x=${variable}&own_stations=${own_stations}`).pipe(
      map((x: any) => x?.data?.station_list),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceMetrics(markeType: string, national: any, entityId: string, townId: string, zoneId: string, brandC1: string, brandC2: string, brandC3: string, brandC4: string, brandC5: string, product: string, group: string, own_stations?: any) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&comps=5&brands[]=${brandC1}&brands[]=${brandC2}&brands[]=${brandC3}&brands[]=${brandC4}&brands[]=${brandC5}&product_id=${product}&own_stations=${own_stations}`).pipe(
      map((x: any) => x?.data?.summary),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceBrands(markeType: string, national: any, entityId: string, townId: string, zoneId: string, product: string, variable: string, limit: number, group: string) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/brands?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&product_id=${product}&variable=${variable}`).pipe(
      map((x: any) => x?.data?.summary),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceBrandsTable(markeType: string, national: any, entityId: string, townId: string, zoneId: string, product: string, variable: string, limit: number, group: string, own_stations?: boolean) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/by_entidades?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&product_id=${product}&variable=${variable}&limit_brands=${limit}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceEvolution(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, secondVariable: string, typeValue: string, group: string, period: string, n: any, stations: number) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/evolution?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product_id=${product}&variable=${variable}&second_variable=${secondVariable}&type_value=${typeValue}&period=${period}&n=${n}&limit_brands=${stations}&num_stations=1`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceEvolutionExcel(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, secondVariable: string, typeValue: string, group: string, period: string) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/evolution/excel?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product_id=${product}&variable=${variable}&second_variable=${secondVariable}&type_value=${typeValue}&period=${period}&limit_brands=40&num_stations=1`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceActual(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, group: string) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/current?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product_id=${product}&variable=${variable}&num_stations=1`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceActualExcel(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, group: string) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/current/excel?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product_id=${product}&variable=${variable}&limit_brands=40&num_stations=1`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Data Format

  parseDateTZ(date: string | Date) {
    return moment.tz(date, 'Europe/Madrid').format('DD MMMM HH:mm');
  }

  parseDateSlash(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('DD/MMMM_HH:mm');
  }

  parseDateD(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('(dd) DD-MMM');
  }

  parseDateDM(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('DD MMM');
  }

  parseDateM(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('MMM YY');
  }

  parseDateY(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('DD-MMM-YY');
  }

  parseDateYS(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('DD/MM/YY');
  }

  parseDate(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('DD/MM/YYYY');
  }

  parseDateComplete(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('DD/MM/YYYY HH:mm');
  }

  parseHour(date: any) {
    return moment.tz(date, 'Europe/Madrid').format('HH:mm');
  }

  removeSADECV(str: string) {
    if (str) {
      str = this.capitalizeFirstLetter(str.toLowerCase());
      str = str.replace(/[\s,]+/g, ' ');
      str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s?[Pp]\.?\s?[Ii]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
      str = str.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
      str = str.replace(/(,? )?[Ss]\.?[Dd][Ee][Rr]\.?[Ll]\.?[Dd][Ee][Cc][Vv]?/g, '');
      str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
      str = str.replace(/(,? )?[Ss]\.?\s?[Cc]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ss]\.?\s?/g, '');
      str = str.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s?/g, '');
      str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Cc]\.?\s?[Vv]\.?\s?/g, '');
      str = str.replace(/(,? )?[Ss]\.\s?[Aa]\.?/g, '');
      return str;
    }
  }

  capitalizeFirstLetter(text: any) {
    if (text) {
      return text
        .toLowerCase()
        .split(' ')
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }

  changeNegative(num: any) {
    if (num) {
      num = num.toString();
      if (num.includes('-')) {
        return num.replace('-', '-');
      } else {
        return num.toString(2);
      }
    }
  }

  changeNegativeAmount(num: any) {
    if (num) {
      num = num.toString();
      if (num.includes('-')) {
        return num.replace('-', '-€ ');
      } else {
        return '€ ' + num.toString(2);
      }
    }
  }


  changeNegativeAmountWithoutDecimals(num: number | string): string {
    const parsedNum = parseFloat(num.toString());
    if (isNaN(parsedNum)) {
      return '';
    }
    const formattedNum = +num < 0 ? '-€ ' : '€ ';
    return formattedNum + Math.abs(parsedNum).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }


  numberWithoutDecimals(x: any) {
    if (x) {
      x = x.toFixed(0);
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  }

  formatDistance(distance: any) {
    if (!distance) {
      distance = 0;
    }
    if (typeof distance !== 'number' && !distance.includes('+')) {
      distance = parseFloat(distance);
    }
    if (typeof distance === 'number') {
      distance = Math.round(distance);
      return (Number(distance) > 1000) ? (String((Number(distance) / 1000).toFixed(2)).replace('.', ',') + ' km') : (distance + ' mts');
    } else {
      return distance;
    }
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(() => {
      message;
    });
  }

  createColor(r, g, b, a) {
    const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;
    return {
      backgroundColor: rgba,
      borderColor: rgba,
      pointBackgroundColor: rgba,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: rgba,
      pointHoverBorderColor: rgba
    };
  }

  formatProductCell(product, value, fillColor) {
    product.value = value.toUpperCase();
    product.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: fillColor },
      bgColor: { argb: 'FFFFFFFF' },
    };
    product.font = { color: { argb: 'FFFFFFFF' }, bold: true };
    product.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }

  formatHeaderCell(cell) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF002060' },
      bgColor: { argb: 'FF28da6d' }
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true };
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  }

  changeClientG(creId: string, stationId: string) {
    localStorage.setItem('creId', creId);
    localStorage.setItem('idStation', stationId);
    this.router.navigate(['/gasprecio/monitor']).then(() => {
      window.location.reload();
    });
  }

  displayMessage(messageType: 'success' | 'error' | 'warning', title: string, text?: string, options?: { allowEscapeKey?: boolean; allowEnterKey?: boolean; allowOutsideClick?: boolean; preConfirm?: () => void; }) {
    Swal.fire({
      title: title,
      text: text,
      icon: messageType,
      confirmButtonColor: '#00e07b',
      reverseButtons: true,
      confirmButtonText: 'Aceptar',
      ...options
    });
  }

  showLoader(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
  }

  createWorksheet(workbook, title, ySplit, xSplit) {
    return workbook.addWorksheet(title, {
      views: [
        { state: 'frozen', ySplit: ySplit, xSplit: xSplit }
      ]
    });
  }

  generateExcelFile(workbook, filename) {
    Swal.close();
    workbook.xlsx.writeBuffer().then((resp) => {
      const blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename);
    });
  }

  addHeaderRow(worksheet: any, header: any) {
    const headerRow = worksheet.addRow(header);
    return headerRow.eachCell((cell: any) => {
      this.formatHeaderCell(cell);
    });
  }

  setProductColor(productCell: any) {
    const productColor = this.colorsProductsReports[String(productCell.value).toLowerCase()]
    this.formatProductCell(productCell, String(productCell.value), productColor);
  }

  getColorString(color: { r: number, g: number, b: NumberFormat }): string {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
  }

  getColorRGBAString(color: { r: number, g: number, b: number }): string {
    return `rgba(${color.r}, ${color.g}, ${color.b}, .3)`;
  }
  getProductTypeIndex(productType) {
    return ['1', '2', '3', '4', '5', '6', 'total'].indexOf(productType);
  }
}