<!-- ============================================================== -->
<!-- Main wrapper - style you can find in login.component.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/images/login/img_login.svg)">
    <div class="login-box card" style="border-radius: 0 !important;">
        <div class="password-header">
            <h2>Restablece tu contraseña</h2>
        </div>

        <div class="card-body center-card" *ngIf="success">
            <form class="form-horizontal form-material full" [formGroup]="recoverForm">
                <a href="javascript:void(0)" class="text-center db">
                    <img src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/ic_logoKoomkin.svg" width="40%"
                        alt="Home" />
                </a>

                <h2 class="welcome">Ingresar nueva contraseña</h2>
                <h5 class="welcome-subtitle"> La contraseña debe tener al menos 8 caracteres.</h5>
                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <input class="form-control" type="password" name="password" placeholder="Nueva contraseña"
                            formControlName="password">
                        <span class="text-danger" *ngIf="submitted && f.password.errors?.required">
                            La contraseña es requerida.
                        </span>
                        <span class="text-danger" *ngIf="submitted && f.password.errors">
                            La contraseña debe tener al menos 8 caracteres.
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <input class="form-control" type="password" name="confirm_password"
                            placeholder="Confirmar nueva contraseña" formControlName="confirm_password">
                        <span class="text-danger" *ngIf="submitted && f.confirm_password.errors?.required">
                            La contraseña es requerida.
                        </span>
                        <span class="text-danger" *ngIf="submitted && f.confirm_password.errors?.mustMatch">
                            Las contraseñas no coinciden.
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4 offset-md-4">
                        <button class="btn btn-info btn-red btn-lg btn-block btn-rounded" color="primary"
                            (click)="onSubmit()">
                            Guardar
                        </button>
                    </div>
                </div>

            </form>
        </div>

        <div class="card-body center-card" *ngIf="!success">
            <form class="form-horizontal form-material full" [formGroup]="recoverForm">
                <a href="javascript:void(0)" class="text-center db">
                    <img src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/ic_logoKoomkin.svg" width="40%" alt="Home" />
                </a>

                <h2 class="welcome">Se ha guardado tu nueva contraseña</h2>
                <a href="javascript:void(0)" class="text-center db">
                    <img src="assets/images/password/ic_resolucion.svg" width="80%" alt="Home" />
                </a>
            </form>
        </div>
        <div class="password-footer">
            <h2>hola@gaspremia.com</h2>
        </div>
    </div>

</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->