import * as moment from 'moment-timezone';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-traffic-chart',
  templateUrl: './traffic-chart.component.html',
  styleUrls: ['./traffic-chart.component.scss']
})
export class TrafficChartComponent implements OnInit, OnDestroy {

  @ViewChild('editModal') editModal: TemplateRef<any>;
  @Input() section: any = false;
  changeModal$!: Subscription;
  changeModal;
  construction;
  productType = 1;
  productType$!: Subscription;
  reqst = 0;
  stationId$!: Subscription;
  stationId: any;
  traffic: any;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            callback(value: any) {
              return ' ' + value.toFixed(3).replace('.', ',');
            }
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          ticks: {
            callback(value: any) {
              return value.toFixed(0);
            },
            beginAtZero: true,
            stepSize: 1,
            min: 0,
            max: 2
          }
        }

      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, details: any) => {
          if (tooltipItem.datasetIndex === 0) {
            const dataset = details.datasets[tooltipItem.datasetIndex];
            const value: any = tooltipItem.yLabel;
            return dataset.label + ': ' + value.toFixed(3).replace('.', ',');
          } else if (tooltipItem.datasetIndex === 1) {
            const dataset = details.datasets[tooltipItem.datasetIndex];
            const value: any = tooltipItem.yLabel;
            return dataset.label + ': ' + value.toFixed(0);
          }
        },
        footer: () => {
          return 'Click aquí para ver el detalle del día';
        }
      }
    },
    elements: {
      line: {
        fill: false
      }
    },
    annotation: {},
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  public lineChartColors: Color[] = [
    { // gold
      backgroundColor: 'rgba(212, 175, 55,  .4)',
      borderColor: '#d4af37',
      pointBackgroundColor: '#d4af37',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: 'rgba(212, 175, 55,  .4)',
      pointHoverBorderColor: '#d4af37'
    },
    { // gold
      backgroundColor: 'rgba(255, 143, 7, 1)',
      borderColor: '#F57D08',
      pointBackgroundColor: '#F57D08',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: 'rgba(255, 143, 7, 1)',
      pointHoverBorderColor: '#F57D08'
    }
  ];

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.formatData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getChartTraffic();
      }
    });
  }

  async ngOnInit() {
    if (this.section) {
      this.getChartTraffic();
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
  }

  getChartTraffic() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null) {
      return;
    }
    this.gasprecioService.getChartTraffic(this.stationId).subscribe((data: any) => {
      this.traffic = data['record'];
      this.construction = data['construction'];
      this.formatData();
      this.reqst = 2;
    }, err => {
      console.log(err);
    });
  }

  formatData() {

    this.lineChartData = [{
      label: 'Tráfico',
      data: this.traffic.map((v: any) => v['value']),
      lineTension: 0
    }];

    this.lineChartLabels = this.traffic.map(
      (v: any) => this.gasprecioService.parseDateDM(v['date'])
    );

    this.lineChartData.push(
      {
        label: 'Obra',
        data: this.construction.map((v: any) => v['value']),
        lineTension: 0,
        borderDash: [4, 4],
        pointStyle: 'crossRot',
        pointRadius: 5,
        pointHoverRadius: 6,
        borderWidth: 2,
        yAxisID: 'y-axis-1',
      });

    this.lineChartColors = [
      { // gold
        backgroundColor: 'rgba(169, 173, 178,  .6)',
        borderColor: '#a9adb2',
        pointBackgroundColor: '#a9adb2',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: 'rgba(169, 173, 178,  .6)',
        pointHoverBorderColor: '#a9adb2'
      },
      { // gold
        backgroundColor: 'rgba(255, 143, 7, 1)',
        borderColor: '#F57D08',
        pointBackgroundColor: '#F57D08',
        pointBorderColor: '#F57D08',
        pointHoverBackgroundColor: 'rgba(255, 143, 7, 1)',
        pointHoverBorderColor: '#F57D08'
      }
    ];

  }

  chartClicked(e: any) {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const date = this.traffic[clickedElementIndex].date;
        const name = this.traffic[clickedElementIndex].name;
        this.changeDataTable(date, name);
        this.openModal();
      }
    }
  }

  changeDataTable(date, name) {
    this.sharedService.nextDate = moment(date).tz('Europe/Madrid').format('YYYY-MM-DD');
    this.sharedService.nextProductType = this.productType;
    this.sharedService.nextStationId = this.stationId;
    this.sharedService.nextNameStation = name;
    setTimeout(() => {
      this.sharedService.nextChangeModal = 'table';
    }, 100);
  }

  openModal() {
    this.modalService.open(this.editModal, { windowClass: 'tableModal' });
  }

  closeModal() {
    this.modalService.dismissAll(this.editModal);
  }
}
