

<ng-container class="change-days">
    <ngx-select class="change-days" [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
        [disabled]="ngxDisabled" [(ngModel)]="daysChange" placeholder="Último día" appendTo="body" (select)="changeDaysAgo($event)"
        dropDownMenuOtherClasses="change-days">
    
        <ng-template ngx-select-option-not-found let-input>
            "{{input}}" no encontrado
        </ng-template>
    
    </ngx-select>
</ng-container>