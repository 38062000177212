import * as moment from 'moment-timezone';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
moment.locale('es');

@Component({
  selector: 'app-last-update',
  templateUrl: './last-update.component.html',
  styleUrls: ['./last-update.component.scss']
})
export class LastUpdateComponent implements OnInit, OnDestroy {

  @Input() icon: boolean;
  date: string;
  dateSubs$!: Subscription;

  constructor(
    private gasprecioService: GasprecioService
  ) {
    this.dateSubs$ = gasprecioService.getLastUpdate().subscribe((resp: any) => { this.date = moment(resp).tz('Europe/Madrid').format('DD MMMM HH:mm'); });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.dateSubs$.unsubscribe();
  }

}
