
import * as moment from 'moment-timezone';
import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { StationService } from 'src/app/modules/station/services/station.service';
import { ClickService } from 'src/app/services/click.service';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() pageSelection: string;
  @Output() toggle: EventEmitter<null> = new EventEmitter();
  filterText: any;
  id: any;
  idGroup: any;
  idStation: any;
  letters: any;
  creIdStation: any;
  rol: any;
  email: any;
  name: any;
  newForm: UntypedFormGroup;
  stationForm: UntypedFormGroup;
  showDrop = false;
  showCharges = false;
  showMenu = true;
  showSuscripcion = false;
  submitted = false;
  station: any;
  page$!: Observable<string>;
  page: string; // Current page selection
  profile: any;
  arrayBuffer: any;
  file: File;
  stationList: any = [];
  clientsList: any = [];
  clienteActual: any;
  route: any;
  alerts: any;
  notifications = false;
  isMobile = false;

  constructor(
    private gasprecioService: GasprecioService,
    public stationService: StationService,
    private clickService: ClickService,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthService,
    private router: Router,
    private sharedService: SharedService
  ) {
    this.page$ = sharedService.sharedPage;
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    const isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '627034d4c1814c0379cc455a' : localStorage.getItem('group');
  }

  async ngOnInit() {
    this.sharedService.sharedPage.subscribe(val => this.page = val);
    this.stationForm = this.formBuilder.group({
      name: ['', Validators.required],
      cre: ['', Validators.required],
      location: ['', Validators.required],
      website: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      brand: ['', Validators.required],
      num_dispensaries: ['', Validators.required],
      inauguration: ['', Validators.required],
      type_conv_store: ['', Validators.required]
    });
    this.newForm = this.formBuilder.group({
      file: ['', Validators.required],
    });
    this.getProfile();
    this.getStation();
    this.getAlerts();
    this.creIdStation = localStorage.getItem('creId') ? localStorage.getItem('creId') : this.authenticationService.creId;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
  }

  getAlerts() {
    this.gasprecioService.getAlerts().subscribe((data: any) => {
      this.alerts = data;
      this.alerts.forEach((element: any) => {
        element.date = this.parseDate(element.date);
        if (!element.click) {
          this.notifications = true;
        }
      });
    }, err => {
      console.error(err);
    });
  }

  parseDate(date: any) {
    return moment(date).tz('Europe/Madrid').format('DD/MMM/YY HH:mm');
  }

  get f() { return this.stationForm.controls; }

  close(page: string) {
    $('#exampleModalCenter').modal('hide');

    setTimeout(() => {
      this.router.navigate([`/estacion/${page}`]);
    }, 400);
  }

  openSideBar() {
    this.toggle.emit();
  }

  show() {
    this.showDrop = !this.showDrop;
  }

  changeView(page: string) {
    if (page === 'menu') {
      this.showMenu = false;
      this.showSuscripcion = false;
    } else if (page === 'suscripcion') {
      this.showMenu = false;
      this.showSuscripcion = true;
    }
  }

  resetView() {
    this.showMenu = true;
    this.showCharges = false;
  }

  changePage(page: string) {
    this.page = page;
    this.router.navigate(['/' + page.toLowerCase()]);
  }

  goTo(type: string, alert: any) {
    localStorage.setItem('creId', alert.station_id);
    localStorage.setItem('idStation', alert.idStation);
    this.alertRead(alert._id);
    switch (type) {
      case 'price_rules':
        this.router.navigate(['/gasprecio/reglas-precio']).then(() => {
          window.location.reload();
        });
        break;
      case 'price_changes':
        this.router.navigate(['/gasprecio/monitor']).then(() => {
          window.location.reload();
        });
        break;
      default:
        this.router.navigate(['/gasprecio/monitor']).then(() => {
          window.location.reload();
        });
        break;
    }
  }

  alertRead(idAlert: string, status?: string) {
    if (!status) {
      this.gasprecioService.alertRead(idAlert).subscribe(
        data => {
          this.notifications = false;
          this.getAlerts();
        },
        error => {
          console.log(error);
        });
    }
  }

  changeClient(cliente: any) {
    this.clienteActual = cliente.alias;
    localStorage.setItem('creId', cliente.station_id);
    localStorage.setItem('idStation', cliente._id);
    this.sharedService.nextCreId = cliente.station_id;
    this.sharedService.nextIdStation = cliente._id;
    window.location.reload();
  }

  changeClientG(cliente: any) {
    localStorage.setItem('creId', cliente.station_id);
    localStorage.setItem('idStation', cliente._id);
    this.sharedService.nextCreId = cliente.station_id;
    this.sharedService.nextIdStation = cliente._id;
    this.clienteActual = cliente.alias;
    this.route = 'monitor';
    this.router.navigate(['/gasprecio/monitor']).then(() => {
      window.location.reload();
    });
  }

  async getProfile() {
    const ready = await this.stationService.getProfile().subscribe(
      data => {
        localStorage.setItem('rol', data.rol);
        this.rol = data.rol;
        this.clientsList = data.stations;
        this.stationList = data.stations;
        if (this.clientsList.find((c: any) => c.station_id === this.creIdStation)) {
          this.clienteActual = this.clientsList.find((c: any) => c.station_id === this.creIdStation).alias;
        } else {
          this.clienteActual = this.clientsList[0].alias;
          localStorage.setItem('creId', this.clientsList[0].station_id);
          localStorage.setItem('idStation', this.clientsList[0]._id);
          window.location.reload();
        }
        this.email = data.email;
        this.name = data.name;
        localStorage.setItem('id', data._id);
        this.id = data._id;
        this.sharedService.nextIdStation = this.id;
        this.idStation = data.idStation;
        this.letters = this.name.concat(' ').replace(/([a-zA-Z]{0,} )/g, function (match: any) { return (match.trim()[0]); }).slice(0, 2);
      }
    );

    if (ready) {
      this.getStation();
    }
  }

  getStation() {
    this.stationService.getStationProfile().subscribe(
      data => {
        this.station = data.station;
        if (this.station['group']) {
          this.idGroup = this.station['group'];
          if (this.idGroup.length > 0) {
            localStorage.setItem('group', this.station['group']);
            this.nextGroup();
          }
        }

        if (this.station['custom']['schedule_notifications']) {
          localStorage.setItem('from', this.station['custom']['schedule_notifications']['from']);
          localStorage.setItem('until', this.station['custom']['schedule_notifications']['until']);
        }

        if (this.station['custom']['marketer']) {
          localStorage.setItem('marketer', this.station['custom']['marketer']);
        }

        if (this.station.name) {
          this.stationForm.patchValue({
            name: this.station.name,
          });
        }

        if (this.station.station_id) {
          this.stationForm.patchValue({
            cre: this.station.station_id,
          });
        }

        if (this.station.location) {
          this.stationForm.patchValue({
            location: this.station.location,
          });
        }

        if (this.station.website) {
          this.stationForm.patchValue({
            website: this.station.website,
          });
        }

        if (this.station.phone) {
          this.stationForm.patchValue({
            phone: this.station.phone,
          });
        }

        if (this.station.email) {
          this.stationForm.patchValue({
            email: this.station.email,
          });
        }

        if (this.station.brand) {
          this.stationForm.patchValue({
            brand: this.station.brand,
          });
        }

        if (this.station.num_dispensaries) {
          this.stationForm.patchValue({
            num_dispensaries: this.station.num_dispensaries,
          });
        }

        if (this.station.inauguration) {
          this.stationForm.patchValue({
            inauguration: this.station.inauguration,
          });
        }

        if (this.station.type_conv_store) {
          this.stationForm.patchValue({
            type_conv_store: this.station.type_conv_store,
          });
        }
      },
      error => {
        console.log(error);
      });
  }

  nextGroup() {
    this.sharedService.nextGroup = this.idGroup;
  }

  // convenience getter for easy access to form fields
  get n() { return this.newForm.controls; }

  loadFile(event: any) {
    this.file = event.target.files[0];
  }

  clickPage(page: string) {
    this.clickService.clickPage(this.id, this.idStation, page, this.page)
      .pipe()
      .subscribe(
        data => {

        },
        error => {
          console.log(error);
        });
  }

  async logout() {
    this.authenticationService.logout();
  }

  capitalizeFirstLetter(text: string) {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  filterItem(str: string) {
    if (typeof str === 'string') {
      this.clientsList = this.stationList.filter((d: any) => {
        if (d['alias'].toLowerCase().indexOf(str.toLowerCase()) !== -1) {
          return true;
        }
        return false;
      });
    }
  }

  clickIcon(type) {
    let page = this.page;
    if (page === 'General') {
      page = 'Tablero'
    } else if (page === 'Mercado') {
      page = 'Modulo Inteligencia'
    }

    if (type !== page) {
      this.clickPage(type)
    }
  }
}
